import { callApiGateway } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import { KnownAction } from "./actions";
import { PrivateLabelData, PrivateLabelEditableData } from "./state";
import { ResetSearchPrivateLabels } from "../PrivateLabelsList/actions";
import { ResetPrivateLabelsLookup } from "../../Common/actions";

export const actionCreators = {
    /**
     * Get private label by id.
     */
    getPrivateLabelById: (privateLabelId: string): AppThunkAction<KnownAction> => (dispatch) => {
        callApiGateway<{}, PrivateLabelData>(`privateLabels/${privateLabelId}`)
            .then(response => dispatch({ type: "RECEIVE_PRIVATE_LABEL_BY_ID", privateLabel: response }))
            .catch(() => dispatch({ type: "REJECT_PRIVATE_LABEL_BY_ID" }));
        dispatch({ type: "REQUEST_PRIVATE_LABEL_BY_ID" });
    },

    /**
     * Update private label.
     */
    updatePrivateLabel: (privateLabelId: string, privateLabel: PrivateLabelEditableData): AppThunkAction<KnownAction | ResetSearchPrivateLabels | ResetPrivateLabelsLookup> => (dispatch) => {
        callApiGateway<PrivateLabelEditableData, {}>(`privateLabels/${privateLabelId}`, "PUT", privateLabel)
            .then(() =>{
                dispatch({ type: "RECEIVE_UPDATE_PRIVATE_LABEL", privateLabel });

                // refresh shared PLs lookup if updated successfully
                dispatch({ type: "RESET_PRIVATE_LABELS_LOOKUP" });

                // reset PL search state so updated items appear there
                dispatch({ type: "RESET_SEARCH_PRIVATE_LABELS" });
            })
            .catch(() => dispatch({ type: "REJECT_UPDATE_PRIVATE_LABEL" }));
        dispatch({ type: "REQUEST_UPDATE_PRIVATE_LABEL" });
    },

    /**
     * Reset edit request states to NotStarted.
     */
    resetEditRequestStates: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "RESET_EDIT_PRIVATE_LABEL_STATE" });
    },
};
