import { callApiGateway, extractErrorMessage } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { PrivateLabelSearchRequest, PrivateLabelSearchSearchResponse } from "./state";
import { ResetPrivateLabelsLookup } from "../../Common/actions";

export const actionCreators = {
    changePrivateLabelSearchRequest: (request: PrivateLabelSearchRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "CHANGE_PRIVATE_LABELS_SEARCH_REQUEST", request });
    },

    /**
     * Search private labels.
     */
    searchPrivateLabels: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (!appState.privateLabelsList) {
            return;
        }

        const state = appState.privateLabelsList;
        if (state.searchRequestState === RequestState.InProgress) {
            return;
        }

        if (!state.searchRequest) {
            return;
        }

        dispatch({ type: "REQUEST_SEARCH_PRIVATE_LABELS", request: state.searchRequest });
        callApiGateway<PrivateLabelSearchRequest, PrivateLabelSearchSearchResponse>(
            "privateLabels/search", "POST", state.searchRequest)
            .then(response => dispatch({ type: "RECEIVE_SEARCH_PRIVATE_LABELS", searchResult: response }))
            .catch(error => dispatch({ type: "REJECT_SEARCH_PRIVATE_LABELS" }));
    },

    /**
     * Delete private label.
     */
    deletePrivateLabel: (privateLabelId: string): AppThunkAction<KnownAction | ResetPrivateLabelsLookup> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_DELETE_PRIVATE_LABEL", id: privateLabelId });
        callApiGateway<{}, {}>(`privateLabels/${privateLabelId}`, "DELETE")
            .then(() => {
                dispatch({ type: "RECEIVE_DELETE_PRIVATE_LABEL", id: privateLabelId });

                // refresh shared PLs lookup if deleted successfully
                dispatch({ type: "RESET_PRIVATE_LABELS_LOOKUP" });
            })
            .catch(error => dispatch({ type: "REJECT_DELETE_PRIVATE_LABEL", error: extractErrorMessage(error) }));
    },

    /**
     * Delete private labels range.
     */
    deletePrivateLabels: (privateLabelIds: Array<string>): AppThunkAction<KnownAction | ResetPrivateLabelsLookup> => (dispatch, getState) => {
        for (const privateLabelId of privateLabelIds) {
            actionCreators.deletePrivateLabel(privateLabelId)(dispatch, getState);
        }
    }
};
