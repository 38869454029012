import * as React from "react";
import { confirmAlert } from "react-confirm-alert";
import { IDataTableColumn } from "react-data-table-component";
import { FaArrowAltCircleRight, FaEdit, FaFire, FaGlobe, FaLink, FaLock, FaPlus, FaSlidersH, FaTrash } from "react-icons/fa";
import { connect } from "react-redux";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { ApplicationState } from "../../store";
import { IAuthProps } from "../../store/authTypes";
import { actionCreators } from "../../store/FirmwareUpdates/FirmwareSearch/actionCreators";
import { ConfirmAlert, MessageBox, MessageBoxType, Page, withAuthProps } from "../Common";
import { RequestState } from "../../store/sharedTypes";
import { FirmwareSearchResultItem, FirmwareSearchState } from "../../store/FirmwareUpdates/FirmwareSearch/state";
import { UncontrolledTooltip } from "reactstrap";
import { formatByteSize, formatDateTime } from "../../utils";
import FirmwareVisibility from "../../store/FirmwareUpdates/Shared/FirmwareVisibility";
import SearchRequest, { defaultSearchRequest } from "../../store/search";
import SearchableDataTable, { DataTableFilterSelectorDetails } from "../Common/SearchableDataTable/SearchableDataTable";
import { AiOutlineEyeInvisible } from "react-icons/ai";

type FirmwarePageProps =
    FirmwareSearchState &
    typeof actionCreators &
    IAuthProps &
    RouteComponentProps;

const FirmwarePage = (props: FirmwarePageProps) => {
    // Constants
    const pageTitle = "Firmware Updates";

    if (!props.isAdmin) {
        return <Page title={pageTitle}>
            <MessageBox
                type={MessageBoxType.Error}
                title="Access Denied!"
                description="You do not have permissions to access this page.">
                <button
                    className="btn btn-primary mt-3"
                    onClick={props.history.goBack}>
                    Go Back
                </button>
            </MessageBox>
        </Page>;
    }

    const createFirmwareButton =
        <NavLink
            className="btn btn-primary btn-sm mb-3"
            to="/firmware/new/">
            <FaPlus className="mt-n1" /> Create new
        </NavLink>;

    const goToSessionsButton =
        <NavLink
            className="btn btn-primary btn-sm ml-3 mb-3"
            to="/firmware/sessions/">
            Sessions
        </NavLink>;

    const goToThermostatsButton =
        <NavLink
            className="btn btn-primary btn-sm ml-3 mb-3"
            to="/firmware/thermostats/">
            Thermostats
        </NavLink>;

    const contextComponent = (selected: FirmwareSearchResultItem[], clearRows: () => void) => (
        <>
            {selected.length} firmware selected
            <button
                className="btn btn-danger btn-sm ml-2"
                disabled={selected.length === 0}
                onClick={e => {
                    e.preventDefault();
                    confirmAlert({
                        customUI: ({ onClose }) => <ConfirmAlert
                            title="Are you sure?"
                            description={`You are about to permanently delete ${selected.length} firmware!`}
                            onCancel={onClose}
                            onConfirm={() => {
                                props.deleteFirmwareRange(selected.map(u => u.id));
                                clearRows();
                                onClose();
                            }}
                            confirmText="Yes, I am sure"
                            cancelText="Cancel" />
                    });
                }}><FaTrash className="mt-n1" /> Delete</button>
        </>);

    const defaultSearch: SearchRequest = {
        ...defaultSearchRequest,
        order: {
            terms: [{
                field: "CreatedDate",
                desc: true
            }]
        }
    };

    const filters: DataTableFilterSelectorDetails[] = [{
        field: "Visibility",
        values: Object.keys(FirmwareVisibility).filter(key => isNaN(parseInt(key)))
    },
    {
        field: "Product",
        values: ["UWG5"]
    }];

    const columns: IDataTableColumn[] = [
        {
            name: "Visibility",
            cell: (firmware: FirmwareSearchResultItem) => (
                <div id={`visibility-${firmware.id}`} className="mx-3">
                    {firmware.visibility.toString() === "Private" && <FaLock />}
                    {firmware.visibility.toString() === "Unlisted" && <FaLink />}
                    {firmware.visibility.toString() === "Public" && <FaGlobe />}

                    <UncontrolledTooltip
                        placement="top"
                        target={`visibility-${firmware.id}`}>
                        <span className="p-1 m-1 badge ">{firmware.visibility}</span>
                    </UncontrolledTooltip>
                </div>
            ),
            sortable: true,
            maxWidth: "25px",
            selector: "visibility"
        },
        { name: "AX Version", selector: "axVersion", sortable: true, minWidth: "100px" },
        { name: "Software Version", selector: "softwareVersion", sortable: true, minWidth: "100px" },
        { name: "WiFi Version", selector: "wifiVersion", sortable: true, minWidth: "100px" },
        {
            name: "Hardware Versions",
            cell: (firmware: FirmwareSearchResultItem) => (
                <span className="text-truncate">{firmware.hardwareVersions.join(", ")}</span>
            ),
            sortable: true,
            maxWidth: "300px"
        },
        { name: "Size", selector: "size", cell: s => formatByteSize(s.size), sortable: true, maxWidth: "100px", },
        { name: "Internal Notes", selector: "notes", sortable: false, width: "400px" },
        { name: "Product", selector: "product", sortable: true, width: "100px" },
        { name: "Created", selector: "createdDate", cell: s => formatDateTime(s.createdDate), sortable: true, minWidth: "150px", maxWidth: "200px" },
        {
            name: "Actions",
            minWidth: "200px",
            cell: (firmware: FirmwareSearchResultItem) => (
                <div className="w-100">
                    <NavLink
                        id={`wizard-${firmware.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        to={`/firmware/wizard/${firmware.id}`}>
                        <FaFire size={15} />
                    </NavLink>
                    <UncontrolledTooltip placement="top" target={`wizard-${firmware.id}`}>Create Session</UncontrolledTooltip>

                    <NavLink
                        id={`sessions-${firmware.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        to={`/firmware/sessions/${firmware.id}`}>
                        <FaArrowAltCircleRight size={15} />
                    </NavLink>
                    <UncontrolledTooltip placement="top" target={`sessions-${firmware.id}`}>See Associated Sessions</UncontrolledTooltip>

                    <NavLink
                        id={`details-${firmware.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        to={`/firmware/${firmware.id}`}>
                        <FaSlidersH size={15} />
                    </NavLink>
                    <UncontrolledTooltip placement="top" target={`details-${firmware.id}`}>View Firmware Details</UncontrolledTooltip>

                    <NavLink
                        id={`edit-${firmware.id}`}
                        className={"btn shadow-none text-dark btn-sm mt-n1"}
                        to={`/firmware/edit/${firmware.id}`}>
                        <FaEdit size={15} />
                    </NavLink>
                    <UncontrolledTooltip placement="top" target={`edit-${firmware.id}`}>Edit {firmware.axVersion}</UncontrolledTooltip>

                    <button
                        id={`delete-${firmware.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        type="button"
                        disabled={props.deleteRequestState === RequestState.InProgress}
                        onClick={e => {
                            e.preventDefault();
                            confirmAlert({
                                customUI: ({ onClose }) => <ConfirmAlert
                                    title="Are you sure?"
                                    description={`Are you sure you want to permanently delete ${firmware.softwareVersion}?`}
                                    onCancel={onClose}
                                    onConfirm={() => {
                                        props.deleteFirmware(firmware.id);
                                        onClose();
                                    }}
                                    confirmText="Yes, I am sure"
                                    cancelText="Cancel" />
                            });
                        }}>
                        <FaTrash size={15} />
                    </button>
                    <UncontrolledTooltip placement="top" target={`delete-${firmware.id}`}>Delete {firmware.softwareVersion}</UncontrolledTooltip>

                    {firmware.visibility.toString() === "Unlisted" &&
                        <>
                            <NavLink
                                id={`edit-visibility-${firmware.id}`}
                                className={"btn shadow-none text-dark btn-sm mt-n1"}
                                to={`/firmware/visibility/${firmware.id}`}>
                                <AiOutlineEyeInvisible size={15} />
                            </NavLink>
                            <UncontrolledTooltip placement="top" target={`edit-visibility-${firmware.id}`}>Edit unlisted visibility</UncontrolledTooltip>
                        </>
                    }
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];

    return (
        <Page title={pageTitle}>
            {createFirmwareButton}
            {goToSessionsButton}
            {goToThermostatsButton}

            {props.deleteRequestState === RequestState.Failed && <MessageBox type={MessageBoxType.Error} className="mb-3" title="Failed to delete" description="An error occurred when deleting firmware." />}
            <SearchableDataTable
                state={props.searchRequestState}
                columns={columns}
                response={props.searchResult}
                search={props.searchFirmware}
                defaultSearchRequest={defaultSearch}
                filters={filters}
                displayText={"firmware"}
                pluralSuffix={""}
                contextComponent={contextComponent} />
        </Page>
    );
};

export default withAuthProps(connect(
    (state: ApplicationState) => state.firmwareSearch,
    actionCreators
)(FirmwarePage as any));