// libs
import { Formik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Redirect, RouteComponentProps } from "react-router";

// components
import { Page, PermissionDeniedPage, withAuthProps, withCommonProps } from "../Common";

// state
import { ApplicationState } from "../../store";
import { CommonState } from "../../store/Common/state";
import { IAuthProps } from "../../store/authTypes";
import { RequestState } from "../../store/sharedTypes";
import { validationSchemaCreators } from "../../utils";
import { actionCreators } from "../../store/PrivateLabels/PrivateLabelCreate/actionCreators";
import { PrivateLabelCreateState, PrivateLabelData } from "../../store/PrivateLabels/PrivateLabelCreate/state";

type PrivateLabelCreateProps =
    PrivateLabelCreateState &
    CommonState &
    typeof actionCreators &
    IAuthProps &
    RouteComponentProps;

const PrivateLabelCreatePage = (props: PrivateLabelCreateProps) => {
    const pageTitle = "Create Private Label";
    const { resetCreateRequestStates } = props;
    const [redirectToEdit, setRedirectToEdit] = React.useState(false);

    React.useEffect(() => {
        return () => {
            // Anything in here is fired on component unmount.
            resetCreateRequestStates();
        };
    }, [resetCreateRequestStates]);

    // Redirect to Private Label Editor
    if (!!props.newId && redirectToEdit) {
        props.resetCreateRequestStates();
        return <Redirect to={`/privateLabels/edit/${props.newId}`} />;
    }

    // Show success
    if (!!props.newId && !redirectToEdit) {
        setTimeout(() => setRedirectToEdit(true), 3000);
        return <Page title={pageTitle}><p>The new private label has been created. You will be redirected to the edit page now.</p></Page>;
    }

    if (!props.isAdmin) {
        return <PermissionDeniedPage pageTitle={pageTitle} history={props.history} />;
    }

    type FormValues = PrivateLabelData;

    const emptyValues: FormValues = {
        name: "",
        displayName: "",
        email: "",
        appIdentifier: undefined
    };

    const initialValues: FormValues = {
        ...emptyValues
    };

    const validationSchema = Yup.object().shape<FormValues>({
        name: validationSchemaCreators.privateLabelNameSchema(),
        displayName: validationSchemaCreators.privateLabelDisplayNameSchema(),
        email: validationSchemaCreators.emailSchema(),
        appIdentifier: validationSchemaCreators.privateLabelAppIdentifierSchema()
    });

    const getInputClassNames = (isInvalid: boolean) => {
        const classNames = "form-control";
        return isInvalid ? classNames + " is-invalid text-break" : classNames;
    };

    const submit = (values: typeof emptyValues) => {
        props.createPrivateLabel({ ...values, appIdentifier: values.appIdentifier || undefined });
    };

    return (
        <Page title={pageTitle} id="create-private-label-page">
            <div className="row">
                <div className="col">
                    <Formik
                        enableReinitialize={true}
                        onSubmit={submit}
                        validateOnChange
                        validationSchema={validationSchema}
                        initialValues={initialValues}>
                        {({ values, dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, setFieldTouched }) =>
                            <form onSubmit={e => e.preventDefault()}>
                                {/* Name */}
                                <div
                                    className="form-group">
                                    <div>
                                        <label className="" htmlFor="name">Name</label>
                                    </div>
                                    <input name="name" value={values.name} onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }} onBlur={handleBlur}
                                    className={getInputClassNames(!!(touched.name && errors.name))} />
                                    <div className="invalid-feedback">{errors.name}</div>
                                </div>

                                {/* Display Name */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="displayName">Display Name</label>
                                    </div>
                                    <input name="displayName" value={values.displayName} onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }} onBlur={handleBlur}
                                    className={getInputClassNames(!!(touched.displayName && errors.displayName))} />
                                    <div className="invalid-feedback">
                                        {errors.displayName && errors.displayName.split("\n").map(error => <>{error}<br /></>)}
                                    </div>
                                </div>

                                {/* Email */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="email">Email</label>
                                    </div>
                                    <input name="email" value={values.email} onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }} onBlur={handleBlur}
                                    className={getInputClassNames(!!(touched.email && errors.email))} />
                                    <div className="invalid-feedback">
                                        {errors.email && errors.email.split("\n").map(error => <>{error}<br /></>)}
                                    </div>
                                </div>

                                {/* App Identifier */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="appIdentifier">App Identifier</label>
                                        <small className="float-right">*Optional</small>
                                    </div>
                                    <input name="appIdentifier" value={values.appIdentifier} onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }} onBlur={handleBlur}
                                    className={getInputClassNames(!!(touched.appIdentifier && errors.appIdentifier))} />
                                    <div className="invalid-feedback">
                                        {errors.appIdentifier && errors.appIdentifier.split("\n").map(error => <>{error}<br /></>)}
                                    </div>
                                </div>

                                {/* Save */}
                                <div className="form-group mb-0">
                                    <button
                                        type="submit"
                                        onClick={() => handleSubmit()}
                                        className="btn btn-primary"
                                        disabled={!dirty || !isValid || props.createRequestState === RequestState.InProgress}>
                                        {props.createRequestState === RequestState.InProgress ? "Creating new private label" : "Create new private label"}
                                    </button>
                                    <button
                                        onClick={() => props.history.goBack()}
                                        className="btn btn-primary ml-3">Cancel</button>
                                    {props.createRequestState === RequestState.Failed && <p className="text-danger mt-3">Failed to create private label</p>}
                                    {props.createRequestState === RequestState.Succeeded && <div className="valid-feedback d-block">Private label successfully created</div>}
                                </div>
                            </form>
                        }
                    </Formik>
                </div>
            </div>
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => ({ ...state.privateLabelCreate }),
    actionCreators
)(PrivateLabelCreatePage as any)));
