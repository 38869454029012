import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { PrivateLabelCreateState } from "./state";

const unloadedState: PrivateLabelCreateState = {
    createRequestState: RequestState.NotStarted,
    newId: undefined
};

export const reducer: Reducer<PrivateLabelCreateState> = (state: PrivateLabelCreateState | undefined, incomingAction: Action): PrivateLabelCreateState => {

    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        // Create private label
        case "REQUEST_CREATE_PRIVATE_LABEL":
            return {
                ...state,
                createRequestState: RequestState.InProgress
            };
        case "RECEIVE_CREATE_PRIVATE_LABEL":
            return {
                ...state,
                newId: action.newId,
                createRequestState: RequestState.Succeeded
            };
        case "REJECT_CREATE_PRIVATE_LABEL":
            return {
                ...state,
                createRequestState: RequestState.Failed
            };

        // Reset create request states
        case "RESET_CREATE_PRIVATE_LABEL_STATE":
            return {
                ...state,
                ...unloadedState
            };

        default:
            return state;
    }
};
