import { AppThunkAction } from "../index";
import { KnownAction } from "./actions";
import { callApiGateway } from "../../ApiGateway";
import { ThermostatDetails } from "./state";
import { UserProfileData } from "../UserManagement/EditUser/state";
import { isValidGuid, toDate } from "../../utils";

export const actionCreators = {
    /**
     * Load thermostat and thermostat owner based on the user id of the thermostat, if any exists.
     */
    loadThermostat: (id: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "REQUEST_THERMOSTAT_BY_ID", id });

        callApiGateway<{}, ThermostatDetails>(`thermostats/${id}`, "GET")
            .then(getThermostatResponse => {
                const hasValidUserId = getThermostatResponse && isValidGuid(getThermostatResponse.userId);

                // Convert timestamps to Date objects
                const createdDate = toDate(getThermostatResponse?.createdDate);
                if (createdDate) {
                    getThermostatResponse.createdDate = createdDate;
                }
                const wifiChangedDate = toDate(getThermostatResponse?.settings?.wifi?.changeTimestamp);
                if (wifiChangedDate) {
                    getThermostatResponse.settings.wifi.changeTimestamp = wifiChangedDate;
                }
                const regulationChangedDate = toDate(getThermostatResponse?.settings?.regulation?.changeTimestamp);
                if (regulationChangedDate) {
                    getThermostatResponse.settings.regulation.changeTimestamp = regulationChangedDate;
                }
                const displayChangedDate = toDate(getThermostatResponse?.settings?.display?.changeTimestamp);
                if (displayChangedDate) {
                    getThermostatResponse.settings.display.changeTimestamp = displayChangedDate;
                }
                const heatingChangedDate = toDate(getThermostatResponse?.settings?.heating?.changeTimestamp);
                if (heatingChangedDate) {
                    getThermostatResponse.settings.heating.changeTimestamp = heatingChangedDate;
                }
                const floorsensorChangedDate = toDate(getThermostatResponse?.settings?.floorSensor?.changeTimestamp);
                if (floorsensorChangedDate) {
                    getThermostatResponse.settings.floorSensor.changeTimestamp = floorsensorChangedDate;
                }
                const datetimeChangedDate = toDate(getThermostatResponse?.settings?.dateTime?.changeTimestamp);
                if (datetimeChangedDate) {
                    getThermostatResponse.settings.dateTime.changeTimestamp = datetimeChangedDate;
                }
                const datetimeCurrentChangedDate = toDate(getThermostatResponse?.settings?.dateTimeCurrent?.currentValue);
                if (datetimeCurrentChangedDate) {
                    getThermostatResponse.settings.dateTimeCurrent.currentValue = datetimeCurrentChangedDate;
                }
                const latestChangeDate = toDate(getThermostatResponse?.state?.latestChange);
                if (latestChangeDate) {
                    getThermostatResponse.state.latestChange = latestChangeDate;
                }
                const lastReportedDate = toDate(getThermostatResponse?.state?.lastStateReportedAt);
                if (lastReportedDate) {
                    getThermostatResponse.state.lastStateReportedAt = lastReportedDate;
                }

                if (hasValidUserId) {
                    callApiGateway<{}, UserProfileData>(`users/${getThermostatResponse.userId}`)
                        .then(getUserResponse => {
                            dispatch({
                                type: "RECEIVE_THERMOSTAT_BY_ID",
                                thermostat: getThermostatResponse,
                                user: getUserResponse
                            });
                        })
                        .catch(error => {
                            console.log("Failed to get thermostat owner", error);
                            dispatch({ type: "REJECT_THERMOSTAT_BY_ID" });
                        });
                } else {
                    dispatch({ type: "RECEIVE_THERMOSTAT_BY_ID", thermostat: getThermostatResponse, user: undefined });
                }
            })
            .catch(error => {
                console.log("Failed to get thermostat", error);
                dispatch({ type: "REJECT_THERMOSTAT_BY_ID" });
            });
    }
};
