import { RequestState } from "../sharedTypes";

export interface UserInformationViewState {
    requestState: RequestState;
    data?: UserInformationViewDetails;
}

export interface BuildingLookupTree extends LookupTreeItem {
    zones: ZoneLookupTree[];
    awayData?: AwayModeData;
}
export interface ZoneLookupTree extends LookupTreeItem {
    thermostats: ThermostatLookupTree[];
}
export interface ThermostatLookupTree extends LookupTreeItem {
    serialNumber: string;
}
export interface LookupTreeItem {
    id: string;
    name: string;
}

export interface AwayModeData {
    id: string;
    buildingId: string;
    temperatureLimitMax: number;
    startTime: Date;
    endTime: Date;
    isPlanned: boolean;
}

export enum DayOfWeek {
    Sunday = "Sunday",
    Monday = "Monday",
    Tuesday = "Tuesday",
    Wednesday = "Wednesday",
    Thursday = "Thursday",
    Friday = "Friday",
    Saturday = "Saturday"
}


export interface ScheduleEvent {
    start: Date;
    end: Date;
    temperature: number;
}

export interface Schedule {
    key: string;
    days: DayOfWeek[];
    schedulesEvents: ScheduleEvent[];
}
export enum ScheduleType {
    Unknown = "Unknown",
    OnePerWeek = "OnePerWeek",
    WorkDaysAndHolidays = "WorkingDaysAndHolidaysSeparately",
    EachDaySeparately = "EachDayOwnSchedule"
}

export enum DefaultScheduleType {
    None = "None",
    FloorSensor = "FloorSensor",
    RoomSensor = "RoomSensor"
}

export interface ScheduleItem {
    baseTemperature: number;
    daySchedules: Schedule[];
    scheduleType: ScheduleType;
    id: string;
    name: string;
    creationDate: Date;
    defaultScheduleType: DefaultScheduleType;
}

export enum TemperatureUnit {
    Celsius = "Celsius",
    Fahrenheit = "Fahrenheit"
}

export enum TimeFormat {
    Format12H = "Format12H",
    Format24H = "Format24H"
}

export enum DateFormat {
    European = "European",
    UnitedStates = "UnitedStates"
}

export interface ProfileData {
    userId: string;
    privateLabelId: string;
    username: string;
    email: string;
    isEmailConfirmed: boolean;
    isLocked: boolean;
    roles: string[];
    pendingConfirmationEmail: string;
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    postalCode: string;
    countryIsoCode: string;
    languageIsoCode: string;
    phoneNumber: string;
    temperatureUnit: TemperatureUnit;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    birthDate: Date;
}

export interface UserInformationViewDetails {
    profileData?: ProfileData;
    buildingsTreeData?: BuildingLookupTree[];
    schedulesData?: ScheduleItem[];
}