import { RequestState } from "../sharedTypes";

export enum SearchFilterOperation {
    AND = 0,
    OR = 1,
}

export interface ThermostatsSearchRequest {
    query?: string;
    limit?: number;
    offset?: number;
    orderBy?: string;
    orderDescending?: boolean;
    filters?: { filter?: string, field: string, key?:string, value: string, operation: SearchFilterOperation }[]
}

export interface ThermostatsListState {
    thermostatsSearchFormValues: ThermostatsSearchFormValues;
    thermostatsSearchRequest?: ThermostatsSearchRequest;
    thermostatsResult?: ThermostatsSearchResponse;
    searchRequestState: RequestState;
    unlinkThermostatState: RequestState;
    deleteThermostatState: RequestState;
}

export interface ThermostatsSearchResponse {
    items: ThermostatListItem[];
    total: number;
}

export enum FallbackMode {
    Undefined,
    Auto,
    Manual
}

export interface ThermostatMode {
    fallbackMode: FallbackMode,
    manualSetpoint: number,
    holdUntilSetpoint?: number,
    holdUntilEndTime?: Date,
    holdIsPermanent?: boolean,
    scheduleEventId?: number,
    scheduleNextEventId?: number
}

export interface ThermostatListItem {
    id: string;
    isConnected: boolean;
    isLinked: boolean;
    name: string;
    serialNumber: string;
    softwareVersion: string;
    wifiSoftwareVersion: string;
    hardwareVersion: string;
    temperatureCelsius: number;
    temperatureFahrenheit: number;
    userId?: string;
    zoneId: string;
    mode: ThermostatMode;
    username?: string;
    email?: string;
    lastOnline: Date;
    privateLabelId: string;
    distributorId: number;
    internalDistributorId: string;
}

export interface ThermostatsSearchFormValues {
    query: string,
    isOnline: string
}

// support number and string formatting from API
export const match = (actual: FallbackMode | string, expected: FallbackMode): boolean => {
    return actual === expected || actual === FallbackMode[expected];
};

export const isAuto = (mode: ThermostatMode): boolean => {
    return match(mode.fallbackMode, FallbackMode.Auto) && !mode.holdUntilSetpoint;
};

export const getSetpoint = (mode: ThermostatMode): number | null => {
    if (mode.holdUntilSetpoint) {
        return mode.holdUntilSetpoint;
    }

    if (match(mode.fallbackMode, FallbackMode.Manual)) {
        return mode.manualSetpoint;
    }

    return null;
};

export const modeFormat = (mode?: ThermostatMode): string => {
    const defaultLocale: string = "en-US";
    const formatHoldTime = (datetime: Date | string) => {
        const today = new Date();
        const date = new Date(datetime);
        const isToday = date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();

        // show just HH:MM if date is today
        const customFormat = isToday ?
            new Intl.DateTimeFormat(defaultLocale, {
                hour: "2-digit",
                minute: "2-digit"
            }) :
            new Intl.DateTimeFormat(defaultLocale, {
                year: "numeric",
                month: "long",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit"
            });

        return customFormat.format(date);
    };

    const defaultMode = "Undefined";
    if (!mode) {
        return defaultMode;
    }

    if (mode.holdUntilSetpoint) {
        let holdLabel = "Hold";

        if (mode.holdIsPermanent) {
            holdLabel += " permanently (Manual)";
        } else {
            holdLabel += mode.holdUntilEndTime ?
                ` until ${formatHoldTime(mode.holdUntilEndTime)}`
                : " until next event";
        }

        return holdLabel;
    }

    if (match(mode.fallbackMode, FallbackMode.Auto)) {
        return "Auto";
    }

    if (match(mode.fallbackMode, FallbackMode.Manual)) {
        return "Manual";
    }

    return defaultMode;
};
