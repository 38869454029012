import { callApiGateway } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import EditableFirmwareData from "../Shared/EditableFirmwareData";
import FirmwareData from "../Shared/FirmwareData";
import { KnownAction } from "./actions";

export const actionCreators = {
    /**
     * Get firmware by id.
     */
    getFirmwareById: (firmwareId: string): AppThunkAction<KnownAction> => (dispatch) => {
        callApiGateway<{}, FirmwareData>(`firmware/${firmwareId}`)
            .then(response => dispatch({ type: "RECEIVE_FIRMWARE_BY_ID", firmware: response }))
            .catch(() => dispatch({ type: "REJECT_FIRMWARE_BY_ID" }));
        dispatch({ type: "REQUEST_FIRMWARE_BY_ID" });
    },

    /**
     * Update firmware.
     */
    updateFirmware: (firmwareId: string, firmware: EditableFirmwareData): AppThunkAction<KnownAction> => (dispatch) => {
        callApiGateway<EditableFirmwareData, {}>(`firmware/${firmwareId}`, "PUT", firmware)
            .then(() => dispatch({ type: "RECEIVE_UPDATE_FIRMWARE", firmware }))
            .catch(() => dispatch({ type: "REJECT_UPDATE_FIRMWARE" }));
        dispatch({ type: "REQUEST_UPDATE_FIRMWARE" });
    },

    /**
     * Reset edit request states to NotStarted.
     */
    resetEditRequestStates: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "RESET_EDIT_FIRMWARE_STATE" });
    },
};