import { callApiGateway } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import { KnownAction } from "./actions";
import { DistributorData, DistributorEditableData } from "./state";
import { ResetSearchDistributors } from "../DistributorsList/actions";
import { ResetDistributorsLookup } from "../../Common/actions";

export const actionCreators = {
    /**
     * Get distributor by id.
     */
    getDistributorById: (distributorId: string): AppThunkAction<KnownAction> => (dispatch) => {
        callApiGateway<{}, DistributorData>(`distributors/${distributorId}`)
            .then(response => dispatch({ type: "RECEIVE_DISTRIBUTOR_BY_ID", distributor: response }))
            .catch(() => dispatch({ type: "REJECT_DISTRIBUTOR_BY_ID" }));
        dispatch({ type: "REQUEST_DISTRIBUTOR_BY_ID" });
    },

    /**
     * Update distributor.
     */
    updateDistributor: (distributorId: string, distributor: DistributorEditableData): AppThunkAction<KnownAction | ResetSearchDistributors | ResetDistributorsLookup> => (dispatch) => {
        callApiGateway<DistributorEditableData, {}>(`distributors/${distributorId}`, "PUT", distributor)
            .then(() => {
                dispatch({ type: "RECEIVE_UPDATE_DISTRIBUTOR", distributor });

                // refresh shared distributors lookup if updated successfully
                dispatch({ type: "RESET_DISTRIBUTORS_LOOKUP" });

                // reset distributors search state so updated items appear there
                dispatch({ type: "RESET_SEARCH_DISTRIBUTOR" });
            })
            .catch(() => dispatch({ type: "REJECT_UPDATE_DISTRIBUTOR" }));
        dispatch({ type: "REQUEST_UPDATE_DISTRIBUTOR" });
    },

    /**
     * Reset edit request states to NotStarted.
     */
    resetEditRequestStates: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "RESET_EDIT_DISTRIBUTOR_STATE" });
    },
};
