import * as React from "react";
import { Page } from "../Common";

const TermsOfUse = () => {
    return (
        <Page title="Terms Of Use">
            <h5>TBD</h5>
        </Page>
    );
};

export default TermsOfUse;