import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store";
import { IAuthProps } from "../../store/authTypes";
import { RequestState } from "../../store/sharedTypes";
import { actionCreators } from "../../store/FirmwareUpdates/FirmwareDetails/actionCreators";
import { formatByteSize, formatDateTime } from "../../utils";
import { MessageBox, MessageBoxType, Page, Spinner, withAuthProps, withCommonProps } from "../Common";
import { CommonState } from "../../store/Common/state";
import { FirmwareDetailsState } from "../../store/FirmwareUpdates/FirmwareDetails/state";

type FirmwareDetailsProps =
    FirmwareDetailsState &
    typeof actionCreators &
    IAuthProps &
    CommonState &
    RouteComponentProps<{ firmwareId: string }>;

const FirmwareDetailsPage = (props: FirmwareDetailsProps) => {

    const { resetRequestStates } = props;
    React.useEffect(() => {
        return () => {
            // Anything in here is fired on component unmount.
            resetRequestStates();
        };
    }, [resetRequestStates]);

    if (props.getRequestState === RequestState.NotStarted) {
        props.getFirmwareById(props.match.params.firmwareId);
    }

    if (props.getRequestState === RequestState.InProgress || props.getRequestState === RequestState.NotStarted) {
        return (
            <Page title="Firmware details">
                <Spinner />
            </Page>
        );
    }

    if (props.getRequestState === RequestState.Failed || !props.firmware) {
        return <MessageBox
            title="Failed to load firmware"
            description="An error occurred when loading firmware."
            type={MessageBoxType.Error}>
            <button
                className="btn btn-primary mt-3"
                onClick={() => props.getFirmwareById(props.match.params.firmwareId)}>Try again</button>
        </MessageBox>;
    }

    // Render
    return (
        <Page id="firmware-page" title={`Firmware details for '${props.firmware.axVersion}'`}>
            <div className="row">
                <fieldset className="col-md 6">
                    <table className="table table-sm table-striped">
                        <tbody>
                            <tr>
                                <th scope="row">Visibility:</th>
                                <td>{props.firmware.visibility}</td>
                            </tr>
                            <tr>
                                <th scope="row">Ax Version:</th>
                                <td>{props.firmware.axVersion}</td>
                            </tr>
                            <tr>
                                <th scope="row">Software Version:</th>
                                <td>{props.firmware.softwareVersion}</td>
                            </tr>
                            <tr>
                                <th scope="row">Wifi Version:</th>
                                <td>{props.firmware.wifiVersion}</td>
                            </tr>
                            <tr>
                                <th scope="row">Hardware Versions:</th>
                                <td>{props.firmware.hardwareVersions?.join(", ")}</td>
                            </tr>
                            <tr>
                                <th scope="row">Firmware Size:</th>
                                <td>{formatByteSize(props.firmware.size)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Product:</th>
                                <td>{props.firmware.product}</td>
                            </tr>
                            <tr>
                                <th scope="row">Created Date:</th>
                                <td>{formatDateTime(props.firmware.createdDate)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Internal Notes:</th>
                                <td>{props.firmware.notes}</td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
            </div>
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => state.firmwareDetails,
    actionCreators
)(FirmwareDetailsPage as any)));
