import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { FirmwareDetailsState } from "./state";

const unloadedState: FirmwareDetailsState = {
    getRequestState: RequestState.NotStarted,
    firmware: undefined
};

export const reducer: Reducer<FirmwareDetailsState> = (state: FirmwareDetailsState | undefined, incomingAction: Action): FirmwareDetailsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        // Get firmware
        case "REQUEST_FIRMWARE_BY_ID":
            return {
                ...state,
                getRequestState: RequestState.InProgress,
                firmware: undefined
            };
        case "RECEIVE_FIRMWARE_BY_ID":
            return {
                ...state,
                getRequestState: RequestState.Succeeded,
                firmware: action.firmware
            };
        case "REJECT_FIRMWARE_BY_ID":
            return {
                ...state,
                getRequestState: RequestState.Failed,
                firmware: undefined
            };

        // Reset detail request states
        case "RESET_FIRMWARE_DETAILS_STATE":
            return {
                ...state,
                getRequestState: RequestState.NotStarted,
            };

        default:
            return state;
    }
};
