import { RequestState } from "../sharedTypes";

export interface EnqueueEmailData {
    emailAddress: string;
    subject: string;
    htmlBody: string;
    parameters: Map<string, string>;
    privateLabelId: string;
}

export interface TemplateEditableData {
    subject: string;
    htmlBody: string;
}

export interface EmailTemplatesState {
    templates: EmailTemplate[];
    defaultTemplate: EmailTemplate | undefined;
    getPrivateLabelTemplatesRequestState: RequestState;
    saveEmailTemplateRequestState: RequestState;
    restoreEmailTemplateRequestState: RequestState;
    sendTestEmailRequestState: RequestState;
}

export enum EmailTemplateType {
    NotSpecified = "NotSpecified",
    ResetPassword = "ResetPassword",
    ConfirmEmail = "ConfirmEmail",
    AccountLocked = "AccountLocked"
}

export enum EmailTemplateLanguage {
    English = "English",
    Spanish = "Spanish",
    French = "French"
}

export interface EmailTemplate {
    id: string,
    type: EmailTemplateType,
    subject: string,
    htmlBody: string,
    language: EmailTemplateLanguage
}

export interface EmailTemplateVariable {
    name: string;
    testValue: string;
    isRequired: boolean;
    linkText?: string;
}

export const emailTemplateTypeToString = (type: EmailTemplateType) => {
    if (type === EmailTemplateType.NotSpecified) {
        return "Not Specified";
    }
    if (type === EmailTemplateType.ResetPassword) {
        return "Reset Password";
    }
    if (type === EmailTemplateType.ConfirmEmail) {
        return "Confirm Email";
    }
    if (type === EmailTemplateType.AccountLocked) {
        return "Account Locked";
    }
    return "Unknown email template type";
};

const variables: Map<EmailTemplateType, EmailTemplateVariable[]> = new Map();

const commonVariables: EmailTemplateVariable[] = [
    { name: "{ FirstName }", testValue: "John", isRequired: false },
    { name: "{ LastName }", testValue: "Doe", isRequired: false },
    { name: "{ Username }", testValue: "JohnDoe", isRequired: false },
    { name: "{ PrivateLabelName }", testValue: "Example Thermostat System", isRequired: false },
    { name: "{ EmailAddress }", testValue: "not-a-real@email.address", isRequired: false },
    { name: "{ PrivacyPolicyUrl }", testValue: "https://example.com", isRequired: true, linkText: "Privacy Policy" },
    { name: "{ TermsAndConditionsUrl }", testValue: "https://example.com", isRequired: true, linkText: "Terms and Conditions" },
    { name: "{ iOSEULAUrl }", testValue: "https://example.com", isRequired: true, linkText: "iOS EULA" },
    { name: "{ AndroidEULAUrl }", testValue: "https://example.com", isRequired: true, linkText: "Android EULA" }
];

variables.set(EmailTemplateType.ConfirmEmail, [
    ...commonVariables,
    { name: "{ EmailVerificationLink }", testValue: "https://example.com", isRequired: true, linkText: "Confirm email address" },
]);

variables.set(EmailTemplateType.ResetPassword, [
    ...commonVariables,
    { name: "{ PasswordResetLink }", testValue: "https://example.com", isRequired: true, linkText: "Reset password" }
]);

variables.set(EmailTemplateType.AccountLocked, [
    ...commonVariables,
    { name: "{ PasswordResetLink }", testValue: "https://example.com", isRequired: true, linkText: "Reset password" },
]);

export const knownTemplateVariables = variables;