import { callApiGateway } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import { KnownAction } from "./actions";
import { RetrySession } from "./state";

export const actionCreators = {
    requestRetrySession: (retrySession: RetrySession): AppThunkAction<KnownAction> => (dispatch) => {
        callApiGateway<RetrySession, {}>("firmware/sessions/retry", "POST", retrySession)
            .then(_ => dispatch({ type: "SESSION_RETRY_RESPONSE" }))
            .catch(() => dispatch({ type: "SESSION_RETRY_REJECTED" }));
        dispatch({ type: "REQUEST_SESSION_RETRY" });
    },

    resetRetryWizard: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "RESET_RETRY_WIZARD_STATE" });
    },
};