import { callApiGateway } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import { KnownAction } from "./actions";
import { PrivateLabelData } from "./state";
import { ResetSearchPrivateLabels } from "../PrivateLabelsList/actions";
import { ResetPrivateLabelsLookup } from "../../Common/actions";

export const actionCreators = {
    /**
     * Create private label.
     */
    createPrivateLabel: (privateLabel: PrivateLabelData): AppThunkAction<KnownAction | ResetSearchPrivateLabels | ResetPrivateLabelsLookup> => (dispatch) => {
        dispatch({ type: "REQUEST_CREATE_PRIVATE_LABEL", privateLabel });
        callApiGateway<PrivateLabelData, { id: string }>("privateLabels", "POST", privateLabel)
            .then(response =>{
                dispatch({ type: "RECEIVE_CREATE_PRIVATE_LABEL", newId: response.id });

                // refresh shared PLs lookup if created successfully
                dispatch({ type: "RESET_PRIVATE_LABELS_LOOKUP" });

                // reset PL search state so new items appear there
                dispatch({ type: "RESET_SEARCH_PRIVATE_LABELS" });
            })
            .catch(() => dispatch({ type: "REJECT_CREATE_PRIVATE_LABEL" }));
    },

    /**
     * Reset create request states to NotStarted.
     */
    resetCreateRequestStates: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "RESET_CREATE_PRIVATE_LABEL_STATE" });
    },
};
