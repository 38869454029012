// libs
import { Field, FieldArray, Formik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { RouteComponentProps } from "react-router";

// components
import { MessageBox, MessageBoxType, Page, Spinner, withAuthProps, withCommonProps } from "../Common";

// state
import { ApplicationState } from "../../store";
import { CommonState } from "../../store/Common/state";
import { IAuthProps } from "../../store/authTypes";
import { actionCreators } from "../../store/FirmwareUpdates/EditFirmware/actionCreators";
import { EditFirmwareState } from "../../store/FirmwareUpdates/EditFirmware/state";
import { RequestState } from "../../store/sharedTypes";
import { formatByteSize, formatDateTime, validationSchemaCreators } from "../../utils";
import FirmwareVisibility from "../../store/FirmwareUpdates/Shared/FirmwareVisibility";
import EditableFirmwareData from "../../store/FirmwareUpdates/Shared/EditableFirmwareData";
import { FaPlus, FaTrash } from "react-icons/fa";
import { UncontrolledTooltip } from "reactstrap";

type EditFirmwareProps =
    EditFirmwareState &
    CommonState &
    typeof actionCreators &
    IAuthProps &
    RouteComponentProps<{ firmwareId: string }>;

const EditFirmware = (props: EditFirmwareProps) => {
    const pageTitle = "Edit Firmware";
    const firmwareId = props.match.params.firmwareId;
    const { resetEditRequestStates } = props;

    React.useEffect(() => {

        return () => {
            // Anything in here is fired on component unmount.
            resetEditRequestStates();
        };
    }, [resetEditRequestStates]);

    if (props.getRequestState === RequestState.NotStarted) {
        props.getFirmwareById(firmwareId);
    }

    if (props.getRequestState === RequestState.InProgress || props.getRequestState === RequestState.NotStarted) {
        return (
            <Page title={pageTitle}>
                <Spinner />
            </Page>
        );
    }

    if (props.getRequestState === RequestState.Failed) {
        return <Page title={pageTitle}>
            <MessageBox
                type={MessageBoxType.Error}
                title="Failed to load"
                description="An error occurred when loading the firmware.">
                <button
                    className="btn btn-primary mt-3"
                    onClick={() => props.getFirmwareById(firmwareId)}>Try again</button>
            </MessageBox>
        </Page>;
    }

    if (!props.isAdmin) {
        return <Page title={pageTitle}>
            <MessageBox
                type={MessageBoxType.Error}
                title="Access Denied!"
                description="You do not have permissions to access this page.">
                <button
                    className="btn btn-primary mt-3"
                    onClick={props.history.goBack}>
                    Go Back
                </button>
            </MessageBox>
        </Page>;
    }

    type FormValues = {
        visibility: string,
        axVersion: string | undefined,
        softwareVersion: string | undefined,
        wifiVersion: string | undefined,
        hardwareVersions: string[],
        product: string | undefined,
        changelog: string | undefined,
        notes: string | undefined
    };

    const emptyValues: FormValues = {
        visibility: "",
        axVersion: "",
        softwareVersion: "",
        wifiVersion: "",
        hardwareVersions: [],
        product: "",
        changelog: "",
        notes: ""
    };

    const initialValues: FormValues = {
        ...emptyValues,
        ...{
            visibility: props.existingFirmware!.visibility.toString(),
            axVersion: props.existingFirmware!.axVersion,
            softwareVersion: props.existingFirmware!.softwareVersion,
            wifiVersion: props.existingFirmware!.wifiVersion,
            hardwareVersions: props.existingFirmware!.hardwareVersions!,
            product: props.existingFirmware!.product,
            changelog: props.existingFirmware!.changelog,
            notes: props.existingFirmware!.notes
        }
    };

    const validationSchema = Yup.object().shape<FormValues>({
        visibility: validationSchemaCreators.visibilitySchema(),
        axVersion: validationSchemaCreators.axVersionSchema(),
        softwareVersion: validationSchemaCreators.swVersionSchema(),
        wifiVersion: validationSchemaCreators.wifiVersionSchema(),
        hardwareVersions: validationSchemaCreators.hwVersionsSchema(),
        product: validationSchemaCreators.productVersionSchema(),
        changelog: validationSchemaCreators.changelogSchema(),
        notes: validationSchemaCreators.notesSchema()
    });

    const getInputClassNames = (isInvalid: boolean) => {
        const classNames = "form-control";
        return isInvalid ? classNames + " is-invalid" : classNames;
    };

    const submit = (values: typeof emptyValues) => {
        const str: FirmwareVisibility = values.visibility as unknown as FirmwareVisibility;
        const vals: EditableFirmwareData = { ...values, visibility: str };
        props.updateFirmware(firmwareId, vals);
    };

    return (
        <Page title={pageTitle} id="edit-firmware-page">
            <div className="row">
                <div className="col">
                    <Formik
                        enableReinitialize={true}
                        onSubmit={submit}
                        validateOnChange
                        validationSchema={validationSchema}
                        initialValues={initialValues}>
                        {({ values, dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, setFieldTouched }) =>
                            <form onSubmit={e => e.preventDefault()}>

                                {/* Created Date */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="createdDate">Created Date</label>
                                    </div>
                                    <input
                                        disabled
                                        name="createdDate"
                                        value={formatDateTime(props.existingFirmware!.createdDate)}
                                        onBlur={handleBlur}
                                        className="form-control" />
                                </div>

                                {/* Visibility */}
                                <div className="form-group">
                                    <label htmlFor="visibility">Visibility</label>
                                    <select
                                        name="visibility"
                                        className={getInputClassNames(!!(touched.visibility && errors.visibility))}
                                        onChange={(e) => {
                                            setFieldTouched(e.target.name);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.visibility}>
                                        <option value="Private" label="Private" />
                                        <option value="Unlisted" label="Unlisted" />
                                        <option value="Public" label="Public" />
                                    </select>
                                    <div className="invalid-feedback">{errors.visibility}</div>
                                </div>

                                {/* Product */}
                                <div className="form-group">
                                    <label htmlFor="product">Product</label>
                                    <select
                                        name="product"
                                        className={getInputClassNames(!!(touched.product && errors.product))}
                                        onChange={(e) => {
                                            setFieldTouched(e.target.name);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.product}>
                                        <option value="" label="Choose product..." />
                                        <option value="UWG5" label="UWG5" />
                                    </select>
                                    <div className="invalid-feedback">{errors.product}</div>
                                </div>

                                {/* AX Version */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="axVersion">AX Version</label>
                                    </div>
                                    <input name="axVersion" value={values.axVersion} onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }} onBlur={handleBlur} className={getInputClassNames(!!(touched.axVersion && errors.axVersion))} />
                                    <div className="invalid-feedback">{errors.axVersion}</div>
                                </div>

                                {/* Software Version */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="softwareVersion">Software Version</label>
                                    </div>
                                    <input name="softwareVersion" value={values.softwareVersion} onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }} onBlur={handleBlur} className={getInputClassNames(!!(touched.softwareVersion && errors.softwareVersion))} />
                                    <div className="invalid-feedback">{errors.softwareVersion}</div>
                                </div>

                                {/* WiFi Version */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="wifiVersion">WiFi Version</label>
                                    </div>
                                    <input name="wifiVersion" value={values.wifiVersion} onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }} onBlur={handleBlur} className={getInputClassNames(!!(touched.wifiVersion && errors.wifiVersion))} />
                                    <div className="invalid-feedback">{errors.wifiVersion}</div>
                                </div>

                                {/* Hardware Version */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="hardwareVersions">Hardware Versions</label>
                                    </div>

                                    <div className={!!(touched.hardwareVersions && errors.hardwareVersions) ? "is-invalid" : ""}>
                                        <FieldArray
                                            name="hardwareVersions"
                                            render={arrayHelpers => (
                                                <div>
                                                    {values.hardwareVersions && values.hardwareVersions.length > 0 ? (
                                                        values.hardwareVersions.map((hw, index) => (
                                                            <div key={index}>
                                                                <div className="d-flex w-100 m-1" key={index}>
                                                                    <div className="flex-grow-1">
                                                                        <Field
                                                                            name={`hardwareVersions.${index}`}
                                                                            className="form-control"
                                                                            validate={(value: string) => {
                                                                                let errorMessage = "";
                                                                                if (value.length < 4) {
                                                                                    errorMessage = "Hardware version must have at least 4 characters";
                                                                                }
                                                                                if (value.length > 10) {
                                                                                    errorMessage = "Hardware version can't be longer than 10 characters";
                                                                                }
                                                                                if (!value.match(/^[a-zA-Z0-9]*$/)) {
                                                                                    errorMessage = "Must be only numbers or latin letters";
                                                                                }
                                                                                return errorMessage;
                                                                            }} />
                                                                    </div>

                                                                    <div className="d-flex">
                                                                        <button
                                                                            id={`remove-hwVersion-${index}`}
                                                                            className="btn shadow-none text-dark btn-sm mt-n1"
                                                                            type="button"
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                arrayHelpers.remove(index);
                                                                            }}>
                                                                            <FaTrash size={15} />
                                                                        </button>
                                                                        <UncontrolledTooltip placement="top" target={`remove-hwVersion-${index}`}>Remove {hw}</UncontrolledTooltip>
                                                                        <button
                                                                            id={`add-hwVersion-${index}`}
                                                                            className="btn shadow-none text-dark btn-sm mt-n1"
                                                                            type="button"
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                arrayHelpers.push("");
                                                                            }}>
                                                                            <FaPlus size={15} />
                                                                        </button>
                                                                        <UncontrolledTooltip placement="top" target={`add-hwVersion-${index}`}>Add a new version</UncontrolledTooltip>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <>
                                                            <button
                                                                id="add-hwVersion"
                                                                className="btn shadow-none text-dark btn-sm mt-n1"
                                                                type="button"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    arrayHelpers.push("");
                                                                }}>
                                                                <FaPlus size={15} />
                                                            </button>
                                                            <UncontrolledTooltip placement="top" target="add-hwVersion">Add a new version</UncontrolledTooltip>
                                                        </>
                                                    )}

                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="invalid-feedback">{errors.hardwareVersions}</div>
                                </div>

                                {/* Size */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="size">Size</label>
                                    </div>
                                    <input
                                        disabled
                                        name="size"
                                        value={formatByteSize(props.existingFirmware!.size)}
                                        onBlur={handleBlur}
                                        className="form-control" />
                                </div>

                                {/* Release Notes */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="changelog">Release Notes</label>
                                    </div>
                                    <textarea
                                        name="changelog"
                                        value={values.changelog}
                                        onChange={(e) => {
                                            setFieldTouched(e.target.name);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        className={getInputClassNames(!!(touched.changelog && errors.changelog))}
                                        style={{ height: "328px" }} />
                                    <div className="invalid-feedback">{errors.changelog}</div>
                                </div>

                                {/* Internal Notes */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="notes">Internal Notes</label>
                                        <small className="float-right">*Optional</small>
                                    </div>
                                    <input name="notes" value={values.notes} onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }} onBlur={handleBlur} className={getInputClassNames(!!(touched.notes && errors.notes))} />
                                    <div className="invalid-feedback">{errors.notes}</div>
                                </div>

                                {/* Save */}
                                <div className="form-group mb-0">
                                    <button
                                        type="submit"
                                        onClick={() => handleSubmit()}
                                        className="btn btn-primary"
                                        disabled={!dirty || !isValid || props.updateRequestState === RequestState.InProgress}>
                                        {props.updateRequestState === RequestState.InProgress ? "Saving changes" : "Save changes"}
                                    </button>
                                    <button
                                        onClick={() => props.history.goBack()}
                                        className="btn btn-primary ml-3">Cancel</button>
                                    {props.updateRequestState === RequestState.Failed && <p className="text-danger mt-3">Failed to save changes</p>}
                                    {props.updateRequestState === RequestState.Succeeded && <div className="valid-feedback d-block">Firmware data successfully updated</div>}
                                </div>
                            </form>
                        }
                    </Formik>
                </div>
            </div>
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => ({ ...state.editFirmware }),
    actionCreators
)(EditFirmware as any)));