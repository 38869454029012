import * as React from "react";
import { FaCheckCircle, FaCircle, FaExclamationCircle, FaQuestionCircle } from "react-icons/fa";
import { EmailTemplate, EmailTemplateLanguage, EmailTemplateType, emailTemplateTypeToString, knownTemplateVariables } from "../../store/EmailTemplates";
import { UncontrolledTooltip } from "reactstrap";
import { WysiwygEditor, WysiwygEditorRef } from ".";

export interface EmailTemplateEditorProps {
    template?: EmailTemplate;
    templateType: EmailTemplateType;
    templateLanguage: EmailTemplateLanguage;
    setEmailTemplateLanguage: (language: EmailTemplateLanguage) => void;
    subject: string;
    setSubject: (subject: string) => void;
    htmlBody: string;
    setHtmlBody: (htmlBody: string) => void;
    subjectValidationError?: string;
    htmlBodyValidationError?: string;
}

export const EmailTemplateEditor = (props: EmailTemplateEditorProps) => {
    const editorRef = React.useRef<WysiwygEditorRef>();
    const allTemplateLanguages = [
        EmailTemplateLanguage.English,
        EmailTemplateLanguage.Spanish,
        EmailTemplateLanguage.French
    ];

    if (!props.template) {
        return (
            <div className="mt-3">
                <p className="text-danger mb-0">
                    <FaExclamationCircle className="mt-n1" />
                    &nbsp;Found no {EmailTemplateLanguage[props.templateLanguage]} template for "{emailTemplateTypeToString(props.templateType)}".</p>
            </div>
        );
    }

    return (
        <div className="my-3">

            {/* Template language selector */}
            <div className="form-inline">
                <div className="form-group">
                    <label className="mr-3" htmlFor="template-language"><b>Email Subject</b></label>
                    <input
                        value={props.subject}
                        onChange={e => props.setSubject(e.target.value)}
                        style={{ width: "400px" }}
                        className="form-control mr-3" />
                </div>
                <div className="form-group">
                    <label className="mr-3" htmlFor="template-language"><b>Email Language</b></label>
                    <select
                        className="form-control"
                        name="private-label"
                        onChange={e => props.setEmailTemplateLanguage(e.target.value as EmailTemplateLanguage)}
                        value={props.templateLanguage}>
                        {allTemplateLanguages.map(language => <option
                            key={language}
                            value={language}
                            label={EmailTemplateLanguage[language]} />)}
                    </select>
                </div>
            </div>

            <small className={`form-text my-1 ${!props.subjectValidationError ? "text-success" : "text-danger"}`}>
                {props.subjectValidationError && <><FaExclamationCircle className="mt-n1" />&nbsp;</>}
                {props.subjectValidationError ?? <>&nbsp;</>}
            </small>

            <h6><b>Email Body</b></h6>
            <small className="text-muted">
                <b>Tags <span id="tag-info"><FaQuestionCircle className="mt-n1" /></span></b>
            </small>
            <UncontrolledTooltip placement="top" target="tag-info">
                <div className="text-left">
                    <p className="mb-0"><FaCircle className="text-primary mt-n1" /> In use</p>
                    <p className="mb-0"><FaCircle className="text-danger mt-n1" /> Required, not in use</p>
                    <p className="mb-0"><FaCircle className="text-secondary mt-n1" /> Optional, not in use</p>
                </div>
            </UncontrolledTooltip>
            <div className="mb-3">
                {(knownTemplateVariables.get(props.templateType) || []).map(v => <span
                    onClick={() => editorRef.current!.addVariable(v)}
                    style={{ fontFamily: "monospace" }}
                    role="button"
                    className={`mr-1 badge ${props.htmlBody.includes(v.name) ? "badge-primary" : v.isRequired ? "badge-danger" : "badge-secondary"}`}
                    key={v.name}>{v.name}</span>)}
            </div>
            <WysiwygEditor
                ref={editorRef}
                templateVariables={(knownTemplateVariables.get(props.templateType) || [])}
                setModifiedHtml={props.setHtmlBody}
                originalHtml={props.template.htmlBody} />
            <small className={`form-text ${!props.htmlBodyValidationError ? "text-success" : "text-danger"}`}>
                {props.htmlBodyValidationError && <><FaExclamationCircle className="mt-n1" />&nbsp;</>}
                {!props.htmlBodyValidationError && <><FaCheckCircle className="mt-n1" />&nbsp;</>}
                {props.htmlBodyValidationError ?? "Valid"}
            </small>
        </div>
    );
};
