import React, { useState } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { MessageBox, MessageBoxType, Page, withAuthProps } from "../../Common";
import Wizard from "../../Common/Wizard/Wizard";
import WizardComponent from "../../Common/Wizard/WizardComponent";
import { actionCreators as retryThermostatsWizardActionCreators } from "../../../store/FirmwareUpdates/RetryThermostatWizard/actionCreators";
import { CommonState } from "../../../store/Common/state";
import { IAuthProps } from "../../../store/authTypes";
import { RequestState } from "../../../store/sharedTypes";
import SchedulerSelection, { SchedulerDefinitions } from "./Selectors/SchedulerSelection";
import ConfirmationSelector from "./Selectors/ConfirmationSelector";
import { RetryThermostats, RetryThermostatsWizardState } from "../../../store/FirmwareUpdates/RetryThermostatWizard/state";
import { RouteComponentProps } from "react-router";

type FirmwareWizardProps =
    RetryThermostatsWizardState &
    CommonState &
    typeof retryThermostatsWizardActionCreators &
    IAuthProps &
    RouteComponentProps;

const FirmwareWizard = (props: FirmwareWizardProps) => {
    const pageTitle = "Retry Thermostats";
    const [scheduler, setScheduler] = useState<SchedulerDefinitions>({});

    const { resetRetryWizard } = props;
    React.useEffect(() => {
        return () => {
            resetRetryWizard();
        };
    }, [resetRetryWizard]);

    if (!props.isAdmin) {
        return <Page title={pageTitle}>
            <MessageBox
                type={MessageBoxType.Error}
                title="Access Denied!"
                description="You do not have permissions to access this page.">
                <button
                    className="btn btn-primary mt-3"
                    onClick={props.history.goBack}>
                    Go Back
                </button>
            </MessageBox>
        </Page>;
    }

    const confirm = () => {
        const retryThermostats: RetryThermostats = {
            ids: props.ids,
            startAt: scheduler.startAt ?? null,
            startOfWindow: scheduler.startOfWindow ?? null,
            endOfWindow: scheduler.endOfWindow ?? null,
            errorThreshold: scheduler.errorThreshold ?? null
        };
        props.requestRetryThermostats(retryThermostats);
    };

    return (
        <div className="p-2">
            <Wizard>
                <WizardComponent
                    displayHeader="Scheduler"
                    displaySelection={""}
                    hasSelection={scheduler !== null}>
                    <SchedulerSelection
                        selected={scheduler}
                        setSelected={(selected) => setScheduler(selected)} />
                </WizardComponent>
                <WizardComponent
                    displayHeader="Confirmation"
                    displaySelection=""
                    hasSelection={props.retryRequestState === RequestState.Succeeded}>
                    <ConfirmationSelector
                        preconditions={props.ids.length > 0}
                        details={[{ header: "No. of Thermostats", value: props.ids.length.toString() }]}
                        scheduler={scheduler}
                        confirm={confirm} />
                    {props.retryRequestState === RequestState.Succeeded &&
                        <h6 className="text-success font-weight-light px-2 mt-3">Successfully sent the retry request.</h6>}
                </WizardComponent>
            </Wizard>
        </div>
    );
};

export default withAuthProps(connect(
    (state: ApplicationState) => state.retryThermostatWizard,
    { ...retryThermostatsWizardActionCreators }
)(FirmwareWizard as any));