import { Action, Reducer } from "redux";
import { RequestState } from "../sharedTypes";
import { KnownAction } from "./actions";
import { EmailTemplatesState } from "./state";

const unloadedState: EmailTemplatesState = {
    defaultTemplate: undefined,
    templates: [],
    getPrivateLabelTemplatesRequestState: RequestState.NotStarted,
    saveEmailTemplateRequestState: RequestState.NotStarted,
    restoreEmailTemplateRequestState: RequestState.NotStarted,
    sendTestEmailRequestState: RequestState.NotStarted
};

export const reducer: Reducer<EmailTemplatesState> = (state: EmailTemplatesState | undefined, incomingAction: Action): EmailTemplatesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        // Get private label templates
        case "REQUEST_PRIVATE_LABEL_TEMPLATES":
            return {
                ...state,
                getPrivateLabelTemplatesRequestState: RequestState.InProgress,
                saveEmailTemplateRequestState: RequestState.NotStarted,
                sendTestEmailRequestState: RequestState.NotStarted,
                templates: []
            };
        case "REJECT_PRIVATE_LABEL_TEMPLATES":
            return {
                ...state,
                getPrivateLabelTemplatesRequestState: RequestState.Failed,
                templates: []
            };
        case "RECEIVE_PRIVATE_LABEL_TEMPLATES":
            return {
                ...state,
                getPrivateLabelTemplatesRequestState: RequestState.Succeeded,
                templates: action.templates
            };

        // Save email template
        case "REQUEST_SAVE_EMAIL_TEMPLATE":
            return {
                ...state,
                saveEmailTemplateRequestState: RequestState.InProgress
            };
        case "REJECT_SAVE_EMAIL_TEMPLATE":
            return {
                ...state,
                saveEmailTemplateRequestState: RequestState.Failed
            };
        case "RECEIVE_SAVE_EMAIL_TEMPLATE":
            const templates = state.templates.map(template => {
                if (template.id !== action.id) {
                    return { ...template };
                }
                return {
                    ...template,
                    subject: action.subject,
                    htmlBody: action.htmlBody
                };
            });

            return {
                ...state,
                templates,
                saveEmailTemplateRequestState: RequestState.Succeeded
            };

        // Send test email
        case "REQUEST_SEND_TEST_EMAIL":
            return {
                ...state,
                sendTestEmailRequestState: RequestState.InProgress
            };
        case "REJECT_SEND_TEST_EMAIL":
            return {
                ...state,
                sendTestEmailRequestState: RequestState.Failed
            };
        case "RECEIVE_SEND_TEST_EMAIL":
            return {
                ...state,
                sendTestEmailRequestState: RequestState.Succeeded
            };

        // Restore email template
        case "REQUEST_DEFAULT_EMAIL_TEMPLATE":
            return {
                ...state,
                restoreEmailTemplateRequestState: RequestState.InProgress
            };
        case "REJECT_DEFAULT_EMAIL_TEMPLATE":
            return {
                ...state,
                restoreEmailTemplateRequestState: RequestState.Failed
            };
        case "RECEIVE_DEFAULT_EMAIL_TEMPLATE":
            return {
                ...state,
                restoreEmailTemplateRequestState: RequestState.Succeeded,
                defaultTemplate: action.template
            };
        default:
            return state;
    }
};