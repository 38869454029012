import * as React from "react";
import { confirmAlert } from "react-confirm-alert";
import { IDataTableColumn } from "react-data-table-component";
import { FaArrowAltCircleRight, FaRedo, FaSlidersH, FaStop, FaTrash } from "react-icons/fa";
import { connect } from "react-redux";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { ApplicationState } from "../../store";
import { IAuthProps } from "../../store/authTypes";
import { actionCreators } from "../../store/FirmwareUpdates/SessionSearch/actionCreators";
import { ConfirmAlert, MessageBox, MessageBoxType, Page, withAuthProps } from "../Common";
import { RequestState } from "../../store/sharedTypes";
import { SessionSearchResultItem, SessionSearchState, SessionState, SessionType } from "../../store/FirmwareUpdates/SessionSearch/state";
import { UncontrolledTooltip } from "reactstrap";
import { formatDateTime, maybePluralize } from "../../utils";
import SearchableDataTable, { DataTableFilterSelectorDetails } from "../Common/SearchableDataTable/SearchableDataTable";
import SearchRequest, { defaultSearchRequest } from "../../store/search";

type SessionPageProps =
    SessionSearchState &
    typeof actionCreators &
    IAuthProps &
    RouteComponentProps<{ query?: string }>;

const SessionPage = (props: SessionPageProps) => {
    // Component state
    const pageTitle = "Firmware Sessions";
    const query = props.match.params.query;

    const getColor = (state: string): string => {
        switch (state) {
            case "Idle":
                return "secondary";
            case "Downloading":
                return "secondary progress-bar-striped progress-bar-animated";
            case "Downloaded":
                return "warning";
            case "Validating":
                return "warning progress-bar-striped progress-bar-animated";
            case "Validated":
                return "info";
            case "Updating":
                return "info progress-bar-striped progress-bar-animated";
            case "Complete":
                return "success";
            case "Error":
                return "danger";
            case "Stopped":
                return "info";
            default:
                return "primary";
        }
    };

    const getWidth = (count: number, total: number): number => {
        return (count / total) * 100;
    };

    if (!props.isAdmin) {
        return <Page title={"Firmware Sessions"}>
            <MessageBox
                type={MessageBoxType.Error}
                title="Access Denied!"
                description="You do not have permissions to access this page.">
                <button
                    className="btn btn-primary mt-3"
                    onClick={props.history.goBack}>
                    Go Back
                </button>
            </MessageBox>
        </Page>;
    }

    const defaultSearch: SearchRequest = {
        ...defaultSearchRequest,
        query: query ?? ""
    };

    const goToFirmwareButton =
        <NavLink
            className="btn btn-primary btn-sm ml-3 mb-3"
            to="/firmware/">
            Firmware
        </NavLink>;

    const goToThermostatsButton =
        <NavLink
            className="btn btn-primary btn-sm ml-3 mb-3"
            to="/firmware/thermostats/">
            Thermostats
        </NavLink>;

    const contextComponent = (selected: SessionSearchResultItem[], clearRows: () => void) => (
        <>
            {maybePluralize(selected.length, "session")} selected
            <button
                className="btn btn-danger btn-sm ml-2"
                disabled={selected.length === 0}
                onClick={e => {
                    e.preventDefault();
                    confirmAlert({
                        customUI: ({ onClose }) => <ConfirmAlert
                            title="Are you sure?"
                            description={`You are about to permanently delete ${maybePluralize(selected.length, "session")}!`}
                            onCancel={onClose}
                            onConfirm={() => {
                                props.deleteSessionRange(selected.map(u => u.id));
                                onClose();
                                clearRows();
                            }}
                            confirmText="Yes, I am sure"
                            cancelText="Cancel" />
                    });
                }}><FaTrash className="mt-n1" /> Delete</button>
        </>);

    const filters: DataTableFilterSelectorDetails[] = [{
        field: "Type",
        values: Object.keys(SessionType).filter(key => isNaN(parseInt(key)))
    },
    {
        field: "State",
        values: Object.keys(SessionState).filter(key => isNaN(parseInt(key)))
    },
    {
        field: "Product",
        values: ["UWG5"]
    }];

    const columns: IDataTableColumn[] = [
        { name: "Created", selector: "createdDate", cell: s => formatDateTime(s.createdAt), sortable: true, maxWidth: "200px" },
        { name: "Software Version", selector: "softwareVersion", sortable: true, maxWidth: "150px" },
        { name: "Type", selector: "type", cell: s => s.sessionType, sortable: true, maxWidth: "125px" },
        {
            name: "State",
            selector: "state",
            cell: (session: SessionSearchResultItem) => (
                <p className="h6 w-100">
                    {session.sessionState.toString() === "Errors" &&
                        <span className="p-2 badge badge-danger w-100">{session.sessionState}</span>
                    }
                    {session.sessionState.toString() === "Stopped" &&
                        <span className="p-2 badge badge-secondary w-100">{session.sessionState}</span>
                    }
                    {session.sessionState.toString() === "Running" &&
                        <span className="p-2 badge badge-light w-100">{session.sessionState}</span>
                    }
                </p>
            ),
            sortable: true,
            maxWidth: "125px"
        },
        {
            name: "Status",
            cell: (session: SessionSearchResultItem) => (
                <div id={`progress-${session.id}`} className="progress w-100">
                    {session.thermostatStates.map(s => (
                        <div
                            key={`progress-${session.id}-${s.key}`}
                            id={`progress-${session.id}-${s.key}`}
                            className={`progress-bar bg-${getColor(s.key.toString())}`}
                            role="progressbar"
                            style={{ width: `${getWidth(s.count, session.thermostatCount)}%` }}>

                            <UncontrolledTooltip
                                placement="top"
                                target={`progress-${session.id}-${s.key}`}>
                                <span className={`p-1 m-1 badge badge-${getColor(s.key.toString())}`}>{s.count}</span> {s.key}
                            </UncontrolledTooltip>
                        </div>
                    ))}
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            minWidth: "500px"
        },
        {
            name: "No. Thermostats",
            cell: (session: SessionSearchResultItem) => (
                <p className="h5 p-2 w-100"><span className="badge badge-secondary w-100">{session.thermostatCount}</span></p>
            ),
            sortable: false,
            maxWidth: "150px"
        },
        { name: "Product", selector: "product", sortable: true, maxWidth: "100px" },
        {
            name: "Actions",
            cell: (session: SessionSearchResultItem) => (
                <div className="w-100">
                    <NavLink
                        id={`details-${session.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        to={`/firmware/session/${session.id}`}>
                        <FaSlidersH size={15} />
                    </NavLink>
                    <UncontrolledTooltip placement="top" target={`details-${session.id}`}>View Session Details</UncontrolledTooltip>

                    <NavLink
                        id={`thermostats-${session.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        to={`/firmware/thermostats/${session.id}`}>
                        <FaArrowAltCircleRight size={15} />
                    </NavLink>
                    <UncontrolledTooltip placement="top" target={`thermostats-${session.id}`}>View Session Thermostats</UncontrolledTooltip>

                    <button
                        id={`cancel-${session.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        type="button"
                        disabled={session.sessionState.toString() !== "Running" || props.deleteRequestState === RequestState.InProgress}
                        onClick={e => {
                            e.preventDefault();
                            confirmAlert({
                                customUI: ({ onClose }) => <ConfirmAlert
                                    title="Are you sure?"
                                    description={`Are you sure you want to cancel session ${session.id}?`}
                                    onCancel={onClose}
                                    onConfirm={() => {
                                        props.cancelSession(session.id);
                                        onClose();
                                    }}
                                    confirmText="Yes, I am sure"
                                    cancelText="Cancel" />
                            });
                        }}>
                        <FaStop size={15} />
                    </button>
                    <UncontrolledTooltip placement="top" target={`cancel-${session.id}`}>Cancel {session.id}</UncontrolledTooltip>

                    <button
                        id={`delete-${session.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        type="button"
                        disabled={session.sessionState.toString() === "Running" || props.deleteRequestState === RequestState.InProgress}
                        onClick={e => {
                            e.preventDefault();
                            confirmAlert({
                                customUI: ({ onClose }) => <ConfirmAlert
                                    title="Are you sure?"
                                    description={`Are you sure you want to permanently delete session ${session.id}?`}
                                    onCancel={onClose}
                                    onConfirm={() => {
                                        props.deleteSession(session.id);
                                        onClose();
                                    }}
                                    confirmText="Yes, I am sure"
                                    cancelText="Cancel" />
                            });
                        }}>
                        <FaTrash size={15} />
                    </button>
                    <UncontrolledTooltip placement="top" target={`delete-${session.id}`}>Delete {session.id}</UncontrolledTooltip>

                    {(session.sessionState.toString() !== "Running" &&
                        session.thermostatStates.filter(t => t.key.toString() === "Error").length > 0) &&
                        <>
                            <NavLink
                                id={`retry-${session.id}`}
                                className={"btn shadow-none text-dark btn-sm mt-n1"}
                                to={`/firmware/sessions/retry/${session.id}`}>
                                <FaRedo size={15} />
                            </NavLink>
                            <UncontrolledTooltip placement="top" target={`retry-${session.id}`}>Retry {session.id}</UncontrolledTooltip>
                        </>}
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            minWidth: "175px"
        }
    ];

    return (
        <Page title={pageTitle}>
            {goToFirmwareButton}
            {goToThermostatsButton}

            {props.deleteRequestState === RequestState.Failed && <MessageBox type={MessageBoxType.Error} className="mb-3" title="Failed to delete" description="An error occurred when deleting firmware(s)." />}
            <SearchableDataTable
                state={props.searchRequestState}
                columns={columns}
                response={props.searchResult}
                search={props.searchSessions}
                defaultSearchRequest={defaultSearch}
                filters={filters}
                displayText={"session"}
                pluralSuffix={"s"}
                contextComponent={contextComponent} />
        </Page>
    );
};

export default withAuthProps(connect(
    (state: ApplicationState) => state.sessionSearch,
    actionCreators
)(SessionPage as any));
