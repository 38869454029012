import { callApiGateway } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import FirmwareData from "../Shared/FirmwareData";
import { KnownAction } from "./actions";

export const actionCreators = {
    /**
     * Get firmware by id.
     */
    getFirmwareById: (firmwareId: string): AppThunkAction<KnownAction> => (dispatch) => {
        callApiGateway<{}, FirmwareData>(`firmware/${firmwareId}`)
            .then(response => dispatch({ type: "RECEIVE_FIRMWARE_BY_ID", firmware: response }))
            .catch(() => dispatch({ type: "REJECT_FIRMWARE_BY_ID" }));
        dispatch({ type: "REQUEST_FIRMWARE_BY_ID" });
    },

    /**
     * Reset detail request states to NotStarted.
     */
    resetRequestStates: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "RESET_FIRMWARE_DETAILS_STATE" });
    },
};