import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState, IAuthProps, RequestState } from "../../../store";
import { CommonState } from "../../../store/Common/state";
import { MessageBox, MessageBoxType, Page, Spinner, withAuthProps, withCommonProps } from "../../Common";
import AppVersionState from "../../../store/FirmwareUpdates/AppVersions/state";
import { actionCreators } from "../../../store/FirmwareUpdates/AppVersions/actionCreators";
import { useHistory } from "react-router";
import AppVersionComponent from "./AppVersionComponent";
import { FaStar } from "react-icons/fa";

type AppVersionsPageProps =
    CommonState &
    AppVersionState &
    typeof actionCreators &
    IAuthProps;

const AppVersionsPage = (props: AppVersionsPageProps) => {
    const pageTitle = "App Versions";
    const history = useHistory();
    const [privateLabelId, setPrivateLabelId] = React.useState<string>();

    const { getAppVersions } = props;
    React.useEffect(() => {
        if (privateLabelId) {
            getAppVersions(privateLabelId);
        }
    }, [privateLabelId, getAppVersions]);


    return (
        <Page title={pageTitle}>

            {props.getRequestState === RequestState.InProgress && <Spinner />}

            {props.getRequestState === RequestState.Failed && <MessageBox
                type={MessageBoxType.Error}
                title="Failed to load"
                description="An error occurred while loading page">
                <button className="btn btn-primary mt-3" onClick={() => history.go(0)}>Reload</button>
            </MessageBox>}

            {/* Private label dropdown */}
            {props.privateLabels && <>
                <div style={{ width: "500px" }} className="input-group mb-3">
                    <div className="input-group-prepend">
                        <div className="input-group-text" style={{ width: "150px" }}>
                            <FaStar />&nbsp;
                            Private Label
                        </div>
                    </div>
                    <select
                        className="form-control"
                        name="private-label"
                        onChange={e => setPrivateLabelId(e.target.value)}
                        value={privateLabelId}>
                        <option value="" label="Choose private label..." />
                        {props.privateLabels.filter(x => x.appIdentifier).map(pl => <option
                            key={pl.id}
                            value={pl.id}
                            label={pl.displayName} />)}
                    </select>
                </div>
            </>}

            {props.getRequestState === RequestState.Succeeded && privateLabelId &&
                <div className="d-flex pl-5 py-2">
                    {props.appVersions?.map(x =>
                        <div className="d-flex pr-5" style={{ width: "275px" }} key={x.platform}>
                            <AppVersionComponent
                                appVersion={x}
                                updateState={props.updateRequestState}
                                currentlyUpdating={props.currentlyUpdating}
                                update={(appVersion) => props.updateAppVersion(x.id, appVersion)} />
                        </div>)}
                </div>}
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => state.appVersions,
    actionCreators
)(AppVersionsPage as any)));
