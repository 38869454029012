import { callApiGateway } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import { KnownAction } from "./actions";
import { FirmwareScheduledUpdate, ScheduledSessionResponse } from "./state";

export const actionCreators = {
    requestScheduledUpdate: (scheduledUpdate: FirmwareScheduledUpdate): AppThunkAction<KnownAction> => (dispatch) => {
        callApiGateway<FirmwareScheduledUpdate, ScheduledSessionResponse>("firmware/update/scheduled", "POST", scheduledUpdate)
            .then(response => dispatch({ type: "SCHEDULED_UPDATE_RESPONSE", response: response }))
            .catch(() => dispatch({ type: "SCHEDULED_UPDATE_REJECTED" }));
        dispatch({ type: "REQUEST_SCHEDULED_UPDATE" });
    },

    resetFirmwareWizard: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "RESET_FIRMWARE_WIZARD_STATE" });
    },
};