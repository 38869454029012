import * as React from "react";
import { BuildingLookupTree } from "../../store/UserInformationView/state";
import { formatUTCDate, isValidGuid } from "../../utils";
import { CopyToClipboardText } from "../Common/CopyToClipboardText";

export type AwayModeDataProps = {
    buildingsTreeData: BuildingLookupTree[];
}

export const AwayModeDataCard = (props: AwayModeDataProps) => {
    return (
        <div className="card">
            <div className="card-header p-1 pl-3">
                <b>Away Mode</b>
            </div>
            <div className="card-body py-2">
                {props.buildingsTreeData?.map((building, index) => (
                    <div key={index} className={`${index === 0 ? "mt-2" : "mt-4"}`}>
                        <div className="d-flex align-items-center bg-secondary text-white rounded pt-2 pl-2 pr-2 pb-0 m-0">
                            <h6>{building.name}</h6>
                            <h6 className={`ml-auto border-0 ${building.awayData ? "badge badge-success" : "badge badge-danger"}`}>{building.awayData ? "Active" : "Not active"}</h6>
                        </div>
                        {building.awayData !== undefined ?
                            <table className="table table-sm text-break">
                                <tbody>
                                    <tr>
                                        <th scope="row">ID: </th>
                                        <td>{building.awayData.id !== undefined && isValidGuid(building.awayData.id) ?
                                            <CopyToClipboardText btnText={building.awayData.id} /> : "No data"}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Building ID: </th>
                                        <td>{building.awayData.buildingId !== undefined && isValidGuid(building.awayData.buildingId) ?
                                            <CopyToClipboardText btnText={building.awayData.buildingId} /> : "No data"}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Temperature limit max.: </th>
                                        <td>{building.awayData.temperatureLimitMax + "℃"}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Start time: </th>
                                        <td>{formatUTCDate(building.awayData.startTime)}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">End time: </th>
                                        <td>{formatUTCDate(building.awayData.endTime)}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Is planned: </th>
                                        <td>{building.awayData.isPlanned ? "Yes" : "No" }</td>
                                    </tr>
                                </tbody>
                            </table>
                            : <div className="ml-1">
                                <hr className="mt-0 mb-1"></hr>
                                <b>Away mode not active for this building.</b>
                            </div>
                        }
                    </div>
                ))}
            </div>
        </div>
    );
};