import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import { IAuthProps } from "../../../store/authTypes";
import { CommonState } from "../../../store/Common/state";
import { actionCreators as thermostatsListActionCreators } from "../../../store/ThermostatsList/actionCreators";
import { actionCreators as editVisibilityActionCreators } from "../../../store/FirmwareUpdates/EditVisibility/actionCreators";
import { actionCreators as editFirmwareActionCreators } from "../../../store/FirmwareUpdates/EditFirmware/actionCreators";
import { EditVisibilityState } from "../../../store/FirmwareUpdates/EditVisibility/state";
import { MessageBox, MessageBoxType, Page, withAuthProps, withCommonProps } from "../../Common";
import Wizard from "../../Common/Wizard/Wizard";
import WizardComponent from "../../Common/Wizard/WizardComponent";
import ThermostatDataTable from "./ThermostatDataTable";
import VisibilityDataTable from "./VisibilityDataTable";
import { SearchFilterOperation, ThermostatsListState } from "../../../store/ThermostatsList/state";
import { EditFirmwareState } from "../../../store/FirmwareUpdates/EditFirmware/state";
import { RequestState } from "../../../store/sharedTypes";

type EditVisibilityProps =
    EditVisibilityState &
    ThermostatsListState &
    EditFirmwareState &
    CommonState &
    typeof editVisibilityActionCreators &
    typeof thermostatsListActionCreators &
    typeof editFirmwareActionCreators &
    IAuthProps &
    RouteComponentProps<{ firmwareId: string }>;

const EditVisibility = (props: EditVisibilityProps) => {
    const pageTitle = "Edit Visibility";
    const firmwareId = props.match.params.firmwareId;
    const { resetRequestStates, resetEditRequestStates } = props;

    React.useEffect(() => {
        return () => {
            // Anything in here is fired on component unmount.
            resetEditRequestStates();
            resetRequestStates();
        };
    }, [resetRequestStates, resetEditRequestStates]);

    const { getFirmwareById } = props;
    React.useEffect(() => {
        getFirmwareById(firmwareId);
    }, [getFirmwareById, firmwareId]);

    const { existingFirmware, getRequestState, thermostatsSearchRequest, changeThermostatsSearchRequest, loadThermostats } = props;
    React.useEffect(() => {
        if (getRequestState === RequestState.Succeeded && existingFirmware) {
            const filters: { field: string, value: string, operation: SearchFilterOperation }[] = existingFirmware?.hardwareVersions?.map(version => {
                const filter = {
                    field: "ThermostatHardwareVersion",
                    value: version,
                    operation: SearchFilterOperation.OR
                };
                return filter;
            }) ?? [];
            changeThermostatsSearchRequest({
                ...thermostatsSearchRequest,
                filters: filters
            });
            loadThermostats();
        }
    }, [existingFirmware, getRequestState, thermostatsSearchRequest, changeThermostatsSearchRequest, loadThermostats]);

    if (!props.isAdmin) {
        return <Page title={pageTitle}>
            <MessageBox
                type={MessageBoxType.Error}
                title="Access Denied!"
                description="You do not have permissions to access this page.">
                <button
                    className="btn btn-primary mt-3"
                    onClick={props.history.goBack}>
                    Go Back
                </button>
            </MessageBox>
        </Page>;
    }

    return (
        <Page title={pageTitle} id="edit-visibility-page">
            <Wizard>
                <WizardComponent
                    displayHeader="Firmware visibility"
                    displaySelection={props.searchResult?.total.toString() ?? ""}
                    hasSelection={false}>
                    <VisibilityDataTable
                        firmwareId={firmwareId}
                        searchResult={props.searchResult}
                        searchRequestState={props.visibilitySearchRequestState}
                        deleteRequestState={props.deleteRequestState}
                        deleteVisibility={(thermostatIds) => props.deleteVisibility(firmwareId, { thermostatIds })}
                        searchVisibility={props.searchVisibility} />
                </WizardComponent>
                <WizardComponent
                    displayHeader="Search thermostats"
                    displaySelection={props.thermostatsResult?.total.toString() ?? ""}
                    hasSelection={false}>
                    <ThermostatDataTable
                        searchRequest={props.thermostatsSearchRequest}
                        searchResult={props.thermostatsResult}
                        searchRequestState={props.searchRequestState}
                        changeSearch={props.changeThermostatsSearchRequest}
                        search={props.loadThermostats}
                        firmwareId={firmwareId}
                        addRequestState={props.createRequestState}
                        addVisibility={(thermostatIds) => props.createVisibility(firmwareId, { thermostatIds: thermostatIds })} />
                </WizardComponent>
            </Wizard>
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => ({ ...state.editVisibility, ...state.thermostatsList, ...state.editFirmware }),
    { ...editVisibilityActionCreators, ...thermostatsListActionCreators, ...editFirmwareActionCreators }
)(EditVisibility as any)));