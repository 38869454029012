import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { PrivateLabelEditState } from "./state";

const unloadedState: PrivateLabelEditState = {
    getRequestState: RequestState.NotStarted,
    updateRequestState: RequestState.NotStarted,
    existingPrivateLabel: undefined
};

export const reducer: Reducer<PrivateLabelEditState> = (state: PrivateLabelEditState | undefined, incomingAction: Action): PrivateLabelEditState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        // Get private label
        case "REQUEST_PRIVATE_LABEL_BY_ID":
            return {
                ...state,
                getRequestState: RequestState.InProgress,
                updateRequestState: RequestState.NotStarted,
                existingPrivateLabel: undefined
            };
        case "RECEIVE_PRIVATE_LABEL_BY_ID":
            return {
                ...state,
                getRequestState: RequestState.Succeeded,
                existingPrivateLabel: action.privateLabel
            };
        case "REJECT_PRIVATE_LABEL_BY_ID":
            return {
                ...state,
                getRequestState: RequestState.Failed,
                existingPrivateLabel: undefined
            };

        // Update private label
        case "REQUEST_UPDATE_PRIVATE_LABEL":
            return {
                ...state,
                updateRequestState: RequestState.InProgress
            };
        case "RECEIVE_UPDATE_PRIVATE_LABEL":
            if (!state.existingPrivateLabel) {
                return state;
            }

            return {
                ...state,
                existingPrivateLabel: { ...state.existingPrivateLabel, ...action.privateLabel },
                updateRequestState: RequestState.Succeeded
            };
        case "REJECT_UPDATE_PRIVATE_LABEL":
            return {
                ...state,
                updateRequestState: RequestState.Failed
            };

        // Reset edit request states
        case "RESET_EDIT_PRIVATE_LABEL_STATE":
            return {
                ...state,
                ...unloadedState
            };

        default:
            return state;
    }
};
