import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { FirmwareThermostatSearchState } from "./state";

const unloadedState: FirmwareThermostatSearchState = {
    searchRequestState: RequestState.NotStarted,
    cancelRequestState: RequestState.NotStarted,
    searchResult: undefined
};

export const reducer: Reducer<FirmwareThermostatSearchState> = (state: FirmwareThermostatSearchState | undefined, incomingAction: Action): FirmwareThermostatSearchState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        // Search
        case "REQUEST_SEARCH_FIRMWARE_THERMOSTATS":
            return {
                ...state,
                searchRequestState: RequestState.InProgress,
                cancelRequestState: RequestState.NotStarted
            };
        case "RECEIVE_SEARCH_FIRMWARE_THERMOSTATS":
            return {
                ...state,
                searchRequestState: RequestState.Succeeded,
                searchResult: action.searchResult
            };
        case "REJECT_SEARCH_FIRMWARE_THERMOSTATS":
            return {
                ...state,
                searchRequestState: RequestState.Failed
            };

        // Cancel session
        case "REQUEST_CANCEL_FIRMWARE_THERMOSTAT":
            return { ...state, cancelRequestState: RequestState.InProgress };
        case "REJECT_CANCEL_FIRMWARE_THERMOSTAT":
            return { ...state, cancelRequestState: RequestState.Failed };
        case "RECEIVE_CANCEL_FIRMWARE_THERMOSTAT":
            return {
                ...state,
                cancelRequestState: RequestState.Succeeded
            };

        default:
            return state;
    }
};
