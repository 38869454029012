import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { MessageBox, MessageBoxType, Page, Spinner, withAuthProps } from "../../Common";
import Wizard from "../../Common/Wizard/Wizard";
import WizardComponent from "../../Common/Wizard/WizardComponent";
import { actionCreators as thermostatSearchActionCreators } from "../../../store/ThermostatsList/actionCreators";
import { actionCreators as firmwareWizardActionCreators } from "../../../store/FirmwareUpdates/FirmwareWizard/actionCreators";
import { actionCreators as firmwareEditActionCreators } from "../../../store/FirmwareUpdates/EditFirmware/actionCreators";
import { FirmwareScheduledUpdate, FirmwareWizardState, ScheduledSessionFailed } from "../../../store/FirmwareUpdates/FirmwareWizard/state";
import { CommonState } from "../../../store/Common/state";
import { SearchFilterOperation, ThermostatsListState } from "../../../store/ThermostatsList/state";
import { IAuthProps } from "../../../store/authTypes";
import { RouteComponentProps } from "react-router";
import { RequestState } from "../../../store/sharedTypes";
import ThermostatSelection from "./Selectors/ThermostatSelection";
import SchedulerSelection, { SchedulerDefinitions } from "./Selectors/SchedulerSelection";
import { EditFirmwareState } from "../../../store/FirmwareUpdates/EditFirmware/state";
import ConfirmationSelector from "./Selectors/ConfirmationSelector";

type FirmwareWizardProps =
    FirmwareWizardState &
    ThermostatsListState &
    EditFirmwareState &
    CommonState &
    typeof thermostatSearchActionCreators &
    typeof firmwareWizardActionCreators &
    typeof firmwareEditActionCreators &
    IAuthProps &
    RouteComponentProps<{ firmwareId: string }>;

const FirmwareWizard = (props: FirmwareWizardProps) => {
    const pageTitle = "Firmware Wizard";
    const firmwareId = props.match.params.firmwareId;
    const [thermostatIds, setThermostatIds] = useState<string[]>([]);
    const [scheduler, setScheduler] = useState<SchedulerDefinitions>({});
    const [isDifferentSession, setIsDifferentSession] = useState<boolean>(true);

    const { resetFirmwareWizard, resetEditRequestStates } = props;

    React.useEffect(() => {

        return () => {
            // Anything in here is fired on component unmount.
            resetEditRequestStates();
            resetFirmwareWizard();
        };
    }, [resetFirmwareWizard, resetEditRequestStates]);

    const { getFirmwareById } = props;
    React.useEffect(() => {
        getFirmwareById(firmwareId);
    }, [getFirmwareById, firmwareId]);

    const { existingFirmware, getRequestState, thermostatsSearchRequest, changeThermostatsSearchRequest, loadThermostats } = props;
    React.useEffect(() => {
        if (getRequestState === RequestState.Succeeded && existingFirmware) {
            const filters: { field: string, value: string, operation: SearchFilterOperation }[] = existingFirmware?.hardwareVersions?.map(version => {
                const filter = {
                    field: "ThermostatHardwareVersion",
                    value: version,
                    operation: SearchFilterOperation.OR
                };
                return filter;
            }) ?? [];
            changeThermostatsSearchRequest({
                ...thermostatsSearchRequest,
                filters: filters
            });
            loadThermostats();
        }
    }, [existingFirmware, getRequestState, thermostatsSearchRequest, changeThermostatsSearchRequest, loadThermostats]);

    React.useEffect(() => {
        if (props.scheduledUpdateState === RequestState.Succeeded || props.scheduledUpdateState === RequestState.Failed) {
            setIsDifferentSession(false);
        }
    }, [props.scheduledUpdateState]);

    const handleSelectedThermostats = (selected : string[]) => {
        setIsDifferentSession(true);
        setThermostatIds(selected);
    };

    const handleSelectedSchedulerDefiniton = (selected : SchedulerDefinitions) => {
        const nextScheduler = selected as SchedulerDefinitions;
        setScheduler(selected);
        setIsDifferentSession(nextScheduler.errorThreshold === scheduler.errorThreshold
            || nextScheduler.endOfWindowSanitized === scheduler.endOfWindowSanitized
            || nextScheduler.startOfWindowSanitized === scheduler.startOfWindowSanitized
            || nextScheduler.startAtSanitized === scheduler.startOfWindow);
    };

    if (props.getRequestState === RequestState.InProgress || props.getRequestState === RequestState.NotStarted) {
        return (
            <Page title={pageTitle}>
                <Spinner />
            </Page>
        );
    }

    if (props.getRequestState === RequestState.Failed) {
        return <Page title={pageTitle}>
            <MessageBox
                type={MessageBoxType.Error}
                title="Failed to load"
                description="An error occurred when loading the firmware.">
                <button
                    className="btn btn-primary mt-3"
                    onClick={() => props.getFirmwareById(firmwareId)}>Try again</button>
            </MessageBox>
        </Page>;
    }

    if (!props.isAdmin) {
        return <Page title={pageTitle}>
            <MessageBox
                type={MessageBoxType.Error}
                title="Access Denied!"
                description="You do not have permissions to access this page.">
                <button
                    className="btn btn-primary mt-3"
                    onClick={props.history.goBack}>
                    Go Back
                </button>
            </MessageBox>
        </Page>;
    }

    const confirm = () => {
        const scheduledUpdate: FirmwareScheduledUpdate = {
            firmwareId: firmwareId,
            thermostatIds: thermostatIds,
            startAt: scheduler.startAtSanitized ?? null,
            startOfWindow: scheduler.startOfWindowSanitized ?? null,
            endOfWindow: scheduler.endOfWindowSanitized ?? null,
            errorThreshold: scheduler.errorThreshold ?? null
        };
        props.requestScheduledUpdate(scheduledUpdate);
    };

    return (
        <div className="p-2">
            <Wizard>
                <WizardComponent
                    displayHeader="Thermostats"
                    displaySelection={thermostatIds.length === 0 ? "" : thermostatIds.length.toString() + " thermostats"}
                    hasSelection={thermostatIds.length !== 0}>
                    <ThermostatSelection
                        searchRequest={props.thermostatsSearchRequest}
                        searchResult={props.thermostatsResult}
                        searchState={props.searchRequestState}
                        changeSearch={props.changeThermostatsSearchRequest}
                        search={props.loadThermostats}
                        selected={thermostatIds}
                        setSelected={(selected) => handleSelectedThermostats(selected)} />
                </WizardComponent>
                <WizardComponent
                    displayHeader="Scheduler"
                    displaySelection={""}
                    hasSelection={scheduler !== null}>
                    <SchedulerSelection
                        selected={scheduler}
                        setSelected={(selected) => handleSelectedSchedulerDefiniton(selected)} />
                </WizardComponent>
                <WizardComponent
                    displayHeader="Confirmation"
                    displaySelection=""
                    hasSelection={props.scheduledUpdateState === RequestState.Succeeded}>
                    <ConfirmationSelector
                        preconditions={!!firmwareId && thermostatIds.length > 0 && isDifferentSession}
                        details={[{ header: "No. of Thermostats", value: thermostatIds.length.toString() }]}
                        scheduler={scheduler}
                        confirm={confirm} />
                    {props.scheduledUpdateState === RequestState.Failed &&
                        <h6 className="text-danger font-weight-light px-2 mt-3">Failed to send the request to create a new session.</h6>}
                    {props.scheduledUpdateState === RequestState.Succeeded &&
                        <div>
                            <h6 className="text-success font-weight-light px-2 mt-3">Successfully sent the request to create a new session.</h6>
                            {props.scheduledUpdateResponse.failed.map((failed, index) =>
                                <div key={index} className="mt-6">
                                    <span className="text-danger font-weight-light px-4">{failed.description}</span>
                                    <div>
                                        {failed.serialNumbers.map(serial =>
                                            <span key={serial} className="font-weight-light px-5">
                                                {serial}
                                            </span>)}
                                    </div>
                                </div>)}
                        </div>}
                </WizardComponent>
            </Wizard>
        </div>
    );
};

export default withAuthProps(connect(
    (state: ApplicationState) => ({ ...state.thermostatsList, ...state.firmwareWizard, ...state.editFirmware }),
    { ...thermostatSearchActionCreators, ...firmwareWizardActionCreators, ...firmwareEditActionCreators }
)(FirmwareWizard as any));