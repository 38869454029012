import React, { ReactElement, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import WizardComponent, { WizardComponentProps } from "./WizardComponent";

export interface WizardProps {
    children: Array<ReactElement<WizardComponentProps>>
}

export const Wizard = (props: WizardProps) => {
    const maxIndex = props.children.length - 1;
    const [index, setIndex] = useState<number>(0);
    const progress = (index / (maxIndex + 1)) * 100;

    return (
        <div className="d-flex flex-column">

            <div className="d-flex mx-auto">
                <button
                    className="btn shadow-none text-dark btn-sm mt-n1"
                    disabled={index <= 0}
                    onClick={() => setIndex(index - 1)}>
                    <FaArrowLeft size="36" />
                </button>
                <button
                    className="btn shadow-none text-dark btn-sm mt-n1"
                    disabled={index >= maxIndex}
                    onClick={() => setIndex(index + 1)}>
                    <FaArrowRight size="36" />
                </button>
            </div>

            <div className="progress mx-4 my-2">
                <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                    aria-valuenow={index}
                    aria-valuemin={0}
                    aria-valuemax={maxIndex} />
            </div>

            <div className="mx-4 my-2">
                <WizardComponent
                    {...props.children[index].props}
                    index={index} />
            </div>
        </div>
    );
};

export default Wizard;