import { Action, Reducer } from "redux";
import { RequestState } from "../sharedTypes";
import { KnownAction } from "./actions";
import { CommonState } from "./state";

const unloadedState: CommonState = {
    loadCountriesRequestState: RequestState.NotStarted,
    countries: undefined,
    loadPrivateLabelsRequestState: RequestState.NotStarted,
    privateLabels: undefined,
    loadDistributorsRequestState: RequestState.NotStarted,
    distributors: undefined
};

export const reducer: Reducer<CommonState> = (state: CommonState | undefined, incomingAction: Action): CommonState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_COUNTRIES_LOOKUP":
            return { ...state, loadCountriesRequestState: RequestState.InProgress };
        case "RECEIVE_COUNTRIES_LOOKUP":
            return { ...state, loadCountriesRequestState: RequestState.Succeeded, countries: action.countries };
        case "REJECT_COUNTRIES_LOOKUP":
            return { ...state, loadCountriesRequestState: RequestState.Failed };
        case "RESET_COUNTRIES_LOOKUP":
            return { ...state, loadCountriesRequestState: RequestState.NotStarted, countries: undefined };
        case "REQUEST_PRIVATE_LABELS_LOOKUP":
            return { ...state, loadPrivateLabelsRequestState: RequestState.InProgress };
        case "RECEIVE_PRIVATE_LABELS_LOOKUP":
            return { ...state, loadPrivateLabelsRequestState: RequestState.Succeeded, privateLabels: action.privateLabels };
        case "REJECT_PRIVATE_LABELS_LOOKUP":
            return { ...state, loadPrivateLabelsRequestState: RequestState.Failed };
        case "RESET_PRIVATE_LABELS_LOOKUP":
            return { ...state, loadPrivateLabelsRequestState: RequestState.NotStarted, privateLabels: undefined };
        case "REQUEST_DISTRIBUTORS_LOOKUP":
            return { ...state, loadDistributorsRequestState: RequestState.InProgress };
        case "RECEIVE_DISTRIBUTORS_LOOKUP":
            return { ...state, loadDistributorsRequestState: RequestState.Succeeded, distributors: action.distributors };
        case "REJECT_DISTRIBUTORS_LOOKUP":
            return { ...state, loadDistributorsRequestState: RequestState.Failed };
        case "RESET_DISTRIBUTORS_LOOKUP":
            return { ...state, loadDistributorsRequestState: RequestState.NotStarted, privateLabels: undefined };
        default:
            return state;
    }
};
