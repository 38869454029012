import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { SessionDetailsState } from "./state";

const unloadedState: SessionDetailsState = {
    getRequestState: RequestState.NotStarted,
    session: undefined
};

export const reducer: Reducer<SessionDetailsState> = (state: SessionDetailsState | undefined, incomingAction: Action): SessionDetailsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        // Get session
        case "REQUEST_SESSION_BY_ID":
            return {
                ...state,
                getRequestState: RequestState.InProgress,
                session: undefined
            };
        case "RECEIVE_SESSION_BY_ID":
            return {
                ...state,
                getRequestState: RequestState.Succeeded,
                session: action.session
            };
        case "REJECT_SESSION_BY_ID":
            return {
                ...state,
                getRequestState: RequestState.Failed,
                session: undefined
            };

        // Reset detail request states
        case "RESET_SESSION_DETAILS_STATE":
            return {
                ...state,
                getRequestState: RequestState.NotStarted,
            };

        default:
            return state;
    }
};
