import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store";
import { IAuthProps } from "../../store/authTypes";
import { RequestState } from "../../store/sharedTypes";
import { actionCreators } from "../../store/FirmwareUpdates/SessionDetails/actionCreators";
import { formatDateTime } from "../../utils";
import { MessageBox, MessageBoxType, Page, Spinner, withAuthProps, withCommonProps } from "../Common";
import { CommonState } from "../../store/Common/state";
import { SessionDetailsState } from "../../store/FirmwareUpdates/SessionDetails/state";
import { NavLink } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

type SessionDetailsProps =
    SessionDetailsState &
    typeof actionCreators &
    IAuthProps &
    CommonState &
    RouteComponentProps<{ sessionId: string }>;

const SessionDetailsPage = (props: SessionDetailsProps) => {

    const { resetRequestStates } = props;
    React.useEffect(() => {
        return () => {
            resetRequestStates();
        };
    }, [resetRequestStates]);

    if (props.getRequestState === RequestState.NotStarted) {
        props.getSessionById(props.match.params.sessionId);
    }

    if (props.getRequestState === RequestState.InProgress || props.getRequestState === RequestState.NotStarted) {
        return (
            <Page title="Session details">
                <Spinner />
            </Page>
        );
    }

    if (props.getRequestState === RequestState.Failed || !props.session) {
        return <MessageBox
            title="Failed to load session"
            description="An error occurred when loading the session."
            type={MessageBoxType.Error}>
            <button
                className="btn btn-primary mt-3"
                onClick={() => props.getSessionById(props.match.params.sessionId)}>Try again</button>
        </MessageBox>;
    }

    // Render
    return (
        <Page id="session-page" title={`Session Details for '${props.session.sessionId}'`}>
            <div className="row">
                <fieldset className="col-md 6">
                    <legend>General</legend>
                    <table className="table table-sm table-striped">
                        <tbody>
                            <tr>
                                <th scope="row">Session Type:</th>
                                <td>{props.session.sessionType}</td>
                            </tr>
                            <tr>
                                <th scope="row">Session State:</th>
                                <td>{props.session.sessionState}</td>
                            </tr>
                            <tr>
                                <th scope="row">Firmware Version:</th>
                                <td>
                                    <NavLink id="edit-firmware-link" to={`/firmware/edit/${props.session.firmwareId}`}>
                                        {props.session.softwareVersion}
                                    </NavLink>
                                    <UncontrolledTooltip placement="top" target="edit-firmware-link">Edit firmware</UncontrolledTooltip>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Firmware Product:</th>
                                <td>{props.session.product}</td>
                            </tr>
                            <tr>
                                <th scope="row">Created Date:</th>
                                <td>{formatDateTime(props.session.createdAt)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Updated Date:</th>
                                <td>{formatDateTime(props.session.updatedDate)}</td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
                <fieldset className="col-md 6">
                    <legend>Technical</legend>
                    <table className="table table-sm table-striped">
                        <tbody>
                            <tr>
                                <th scope="row">ID:</th>
                                <td>{props.session.sessionId}</td>
                            </tr>
                            <tr>
                                <th scope="row">Firmware ID:</th>
                                <td>
                                    <NavLink id="edit-firmware-link" to={`/firmware/edit/${props.session.firmwareId}`}>
                                        {props.session.firmwareId}
                                    </NavLink>
                                    <UncontrolledTooltip placement="top" target="edit-firmware-link">Edit firmware</UncontrolledTooltip>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Chunk Count:</th>
                                <td>{props.session.chunksCount}</td>
                            </tr>
                            <tr>
                                <th scope="row">Chunk Size:</th>
                                <td>{props.session.chunkSize}</td>
                            </tr>
                            <tr>
                                <th scope="row">Thermostat Count:</th>
                                <td>{props.session.thermostatCount}</td>
                            </tr>
                        </tbody>
                    </table>
                </fieldset>
            </div>

            {props.session.schedulerInfo &&
                <div className="row w-50">
                    <fieldset className="col-md 6">
                        <legend>Scheduler</legend>
                        <table className="table table-sm table-striped">
                            <tbody>
                                <tr>
                                    <th scope="row">Running:</th>
                                    <td>{props.session.schedulerInfo.isRemoved ? "No" : "Yes"}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Priority:</th>
                                    <td>{props.session.schedulerInfo.priority}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Executed</th>
                                    <td>{props.session.schedulerInfo.executed}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Last Executed At:</th>
                                    <td>{formatDateTime(props.session.schedulerInfo.lastExecutedAt)}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Job Data:</th>
                                    <td>{props.session.schedulerInfo.jobData}</td>
                                </tr>
                                <tr>
                                    <th scope="row">First Run At:</th>
                                    <td>{props.session.schedulerInfo.startAt && formatDateTime(props.session.schedulerInfo.startAt)}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Start Of Window (UTC):</th>
                                    <td>{props.session.schedulerInfo.startOfWindow ?? ""}</td>
                                </tr>
                                <tr>
                                    <th scope="row">End Of Window (UTC):</th>
                                    <td>{props.session.schedulerInfo.endOfWindow ?? ""}</td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                </div>}
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => state.sessionDetails,
    actionCreators
)(SessionDetailsPage as any)));
