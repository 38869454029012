import * as React from "react";
import { FaPrint, FaRedoAlt } from "react-icons/fa";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState, IAuthProps, RequestState } from "../../store";
import { CommonState } from "../../store/Common/state";
import { actionCreators } from "../../store/UserInformationView/actionCreators";
import { UserInformationViewState } from "../../store/UserInformationView/state";
import { MessageBox, MessageBoxType, Page, Spinner, withAuthProps, withCommonProps } from "../Common";
import { TabButton } from "../Common/TabButton";
import { AwayModeDataCard } from "./AwayModeDataCard";
import { BuildingsAndZonesDataCard } from "./BuildingsAndZonesDataCard";
import { ProfileDataCard } from "./ProfileDataCard";
import { ScheduleDataCard } from "./ScheduleDataCard";
import "./UserInformationView.css";

enum Tab {
    Profile,
    BuildingsAndZones,
    Schedule,
    AwayMode
}

export type UserInformationViewProps =
    UserInformationViewState &
    typeof actionCreators &
    IAuthProps &
    CommonState &
    RouteComponentProps<{ userId: string }>;

export const PrintContext = React.createContext({
    isPrinting: false,
});


export const UserInformationView = (props: UserInformationViewProps) => {
    const [tab, setTab] = React.useState(Tab.Profile);
    const [showAllTabs, setShowAllTabs] = React.useState(true);

    const [isPrinting, setIsPrinting] = React.useState(false);
    const printContext = { isPrinting };

    const userId = props.match.params.userId;
    const fetchData = props.loadInformationViewData;
    function print() {
        setIsPrinting(true);

        // Wait 500ms before printing so that there is time for the
        // collapsible elements to play their expansion animation
        setTimeout(() => {
            window.print();
            setIsPrinting(false);
        }, 500);
    }

    function shouldShowTab(subjectTab: Tab): boolean {
        return showAllTabs || tab === subjectTab;
    }

    /**
     * Checks if a tab is currently being displayed
     */
    function isTabActive(subjectTab: Tab): boolean {
        return !showAllTabs && tab === subjectTab;
    }

    /**
     * Fetch initial data
     */
    React.useEffect(() => {
        fetchData(userId);
    }, [fetchData, userId]);

    // Render Spinner if loading.
    if (props.requestState === RequestState.InProgress) {
        return <Spinner description="Loading..." />;
    }

    // In case of error, display error message with retry button
    if (props.requestState === RequestState.Failed) {
        return <Page title="User Information">
            <MessageBox
                type={MessageBoxType.Error}
                title="Failed to load"
                description="An error occurred when loading user data.">
                <button
                    className="btn btn-primary mt-3"
                    onClick={() => fetchData(userId)}>Try again</button>
            </MessageBox>
        </Page>;
    }

    if (props.data === undefined ||
        props.data.schedulesData === undefined ||
        props.data.profileData === undefined ||
        props.data.buildingsTreeData === undefined ||
        props.privateLabels === undefined) {
        return <MessageBox title="User Information View" description="Invalid data." type={MessageBoxType.Error} />;
    }

    return (
        <Page title="User Information" id="user-info-view-page">
            <div className="pb-2 d-flex justify-content-between">
                <div className="row d-flex align-items-center ml-0">
                    <button className="btn btn-primary mr-1" onClick={() => fetchData(userId)}><FaRedoAlt size={18} className="mt-n1" /></button>
                    <TabButton text="All" active={showAllTabs} onClick={() => {
                        setShowAllTabs(true);
                    }} />
                    <TabButton text="Profile" active={isTabActive(Tab.Profile)} onClick={() => {
                        setTab(Tab.Profile);
                        setShowAllTabs(false);
                    }} />
                    <TabButton text="Buildings And Zones" active={isTabActive(Tab.BuildingsAndZones)} onClick={() => {
                        setTab(Tab.BuildingsAndZones);
                        setShowAllTabs(false);
                    }} />
                    <TabButton text="Schedule" active={isTabActive(Tab.Schedule)} onClick={() => {
                        setTab(Tab.Schedule);
                        setShowAllTabs(false);
                    }} />
                    <TabButton text="Away Mode" active={isTabActive(Tab.AwayMode)} onClick={() => {
                        setTab(Tab.AwayMode);
                        setShowAllTabs(false);
                    }} />
                </div>
                <div>
                    <button className="btn btn-primary" onClick={() => print()} style={{ float: "right" }}><FaPrint size={18} className="mt-n1" /></button>
                </div>
            </div>
            <div className="card-columns">
                <PrintContext.Provider value={printContext}>
                    {shouldShowTab(Tab.Profile) && <ProfileDataCard profileData={props.data.profileData} privateLabels={props.privateLabels} />}
                    {shouldShowTab(Tab.BuildingsAndZones) && <BuildingsAndZonesDataCard buildingsTreeData={props.data.buildingsTreeData} />}
                    {shouldShowTab(Tab.Schedule) && <ScheduleDataCard schedulesData={props.data.schedulesData} />}
                    {shouldShowTab(Tab.AwayMode) && <AwayModeDataCard buildingsTreeData={props.data.buildingsTreeData} />}
                </PrintContext.Provider>
            </div>
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => state.userInfo,
    actionCreators
)(UserInformationView as any)));
