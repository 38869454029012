import React, { useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";

export interface DataTableFilterSelectorProps {
    field: string;
    values: string[];
    selected: string[];
    disableButton: boolean;
    addFilter: (field: string, value: string) => void;
    removeFilter: (field: string, value: string) => void;
    display?: string;
}

export const DataTableFilterSelector = (props: DataTableFilterSelectorProps) => {
    const [show, setShow] = useState<boolean>(false);

    return (
        <div
            className="m-2 dropdown"
            style={{ maxWidth: 150 }}>
            <button
                disabled={props.disableButton}
                className="btn btn-outline-secondary dropdown-toggle"
                onClick={() => setShow(!show)}>
                <span className="badge badge-pill badge-secondary mr-2">
                    {props.selected.length}
                </span>
                {props.display ?? props.field}
            </button>
            <div className={`dropdown-menu ${show ? "d-block" : "d-none"}`}>
                {props.values.map(value =>
                    <button
                        key={value}
                        className="dropdown-item"
                        type="button"
                        onClick={() => (props.selected.includes(value) ? props.removeFilter(props.field, value) : props.addFilter(props.field, value))}>
                        {props.selected.includes(value) && <AiOutlineCheckCircle className="my-auto mr-1" />}
                        {value}
                    </button>
                )}
            </div>
        </div>
    );
};

export default DataTableFilterSelector;