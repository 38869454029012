import { RequestState } from "../../sharedTypes";
import ThermostatState from "../Shared/ThermostatState";

export enum SessionType {
    Instant = 0,
    Scheduled = 1
}

export enum SessionState {
    Running = 0,
    Errors = 1,
    Stopped = 2
}

export interface StateCountItem {
    key: ThermostatState,
    count: number
}

export interface SessionSearchResultItem {
    id: string;
    firmwareId: string,
    sessionType: SessionType,
    sessionState: SessionState,
    softwareVersion: string;
    product: string;
    chunksCount: number,
    chunkSize: number,
    thermostatCount: number,
    thermostatStates: StateCountItem[],
    createdAt: string
}

export interface SessionSearchResponse {
    items: SessionSearchResultItem[];
    total: number;
}

export interface SessionSearchState {
    searchRequestState: RequestState;
    cancelRequestState: RequestState;
    deleteRequestState: RequestState;
    searchResult?: SessionSearchResponse;
}
