import { callApiGateway } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import { KnownAction } from "./actions";
import { DistributorData } from "./state";
import { ResetSearchDistributors } from "../DistributorsList/actions";
import { ResetDistributorsLookup } from "../../Common/actions";

export const actionCreators = {
    /**
     * Create private label.
     */
    createDistributor: (distributor: DistributorData): AppThunkAction<KnownAction | ResetSearchDistributors | ResetDistributorsLookup> => (dispatch) => {
        dispatch({ type: "REQUEST_CREATE_DISTRIBUTOR", distributor });
        callApiGateway<DistributorData, { id: string }>("distributors", "POST", distributor)
            .then(response => {
                dispatch({ type: "RECEIVE_CREATE_DISTRIBUTOR", newId: response.id });

                // refresh shared distributors lookup if created successfully
                dispatch({ type: "RESET_DISTRIBUTORS_LOOKUP" });

                // reset distributors search state so new items appear there
                dispatch({ type: "RESET_SEARCH_DISTRIBUTOR" });
            })
            .catch(() => dispatch({ type: "REJECT_CREATE_DISTRIBUTOR" }));
    },

    /**
     * Reset create request states to NotStarted.
     */
    resetCreateRequestStates: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "RESET_CREATE_DISTRIBUTOR_STATE" });
    },
};
