import React from "react";
import { FaInfoCircle } from "react-icons/fa";
import { UncontrolledTooltip } from "reactstrap";

export interface SchedulerDefinitions {
    startAt?: string;
    startAtSanitized?: string;
    startOfWindow?: string;
    startOfWindowSanitized?: string;
    endOfWindow?: string;
    endOfWindowSanitized?: string;
    errorThreshold?: number;
}

interface SchedulerSelectionProps {
    selected: SchedulerDefinitions;
    setSelected: (selected: SchedulerDefinitions) => void;
}

export const SchedulerSelection = (props: SchedulerSelectionProps) => {
    const selectStartAt = (value: string) => {
        if (value) {
            props.setSelected({
                ...props.selected,
                startAt: value,
                startAtSanitized: new Date(value).toISOString()
            });
        } else {
            props.setSelected({
                ...props.selected,
                startAt: undefined,
                startAtSanitized: undefined
            });
        }
    };

    const selectStartOfWindow = (value: string) => {
        if (value) {
            const split = value.split(":");
            props.setSelected({
                ...props.selected,
                startOfWindow: value,
                startOfWindowSanitized: new Date(1, 1, 1, Number(split[0]), Number(split[1])).toISOString()
            });
        } else {
            props.setSelected({
                ...props.selected,
                startOfWindow: undefined,
                startOfWindowSanitized: undefined
            });
        }
    };

    const selectEndOfWindow = (value: string) => {
        if (value) {
            const split = value.split(":");
            props.setSelected({
                ...props.selected,
                endOfWindow: value,
                endOfWindowSanitized: new Date(1, 1, 1, Number(split[0]), Number(split[1])).toISOString()
            });
        } else {
            props.setSelected({
                ...props.selected,
                endOfWindow: undefined,
                endOfWindowSanitized: undefined
            });
        }
    };

    const selectErrorThreshold = (value: string) => {
        const number = Number(value);
        if (number > 100) {
            return;
        }

        if (number < 0) {
            return;
        }

        if (value) {
            props.setSelected({
                ...props.selected,
                errorThreshold: number
            });
        } else {
            props.setSelected({
                ...props.selected,
                errorThreshold: undefined
            });
        }
    };

    return (
        <div className="d-flex flex-column w-25">

            <div className="form-group">
                <div>
                    <label htmlFor="startAt">First run at</label>
                    <small className="float-right">*Optional</small>
                </div>
                <div className="d-flex">
                    <input
                        name="startAt"
                        type="datetime-local"
                        className="form-control"
                        value={props.selected.startAt ?? ""}
                        onChange={(e) => selectStartAt(e.target.value)} />
                    <FaInfoCircle id="startAt" className="ml-2 my-auto" size={16} />
                    <UncontrolledTooltip placement="top" target="startAt">Indicates when the session should start. Empty means start as soon as possible.</UncontrolledTooltip>
                </div>
            </div>

            <div className="form-group">
                <div>
                    <label htmlFor="startOfWindow">Start of window</label>
                    <small className="float-right">*Optional</small>
                </div>
                <div className="d-flex">
                    <input
                        name="startOfWindow"
                        type="time"
                        className="form-control"
                        value={props.selected.startOfWindow ?? ""}
                        onChange={(e) => selectStartOfWindow(e.target.value)} />
                    <FaInfoCircle id="startOfWindow" className="ml-2 my-auto" size={16} />
                    <UncontrolledTooltip placement="top" target="startOfWindow">Indicates when during the day the session should start running. Times are in local, empty means no limit.</UncontrolledTooltip>
                </div>
            </div>

            <div className="form-group">
                <div>
                    <label htmlFor="endOfWindow">End of window</label>
                    <small className="float-right">*Optional</small>
                </div>
                <div className="d-flex">
                    <input
                        name="endOfWindow"
                        type="time"
                        className="form-control"
                        value={props.selected.endOfWindow ?? ""}
                        onChange={(e) => selectEndOfWindow(e.target.value)} />
                    <FaInfoCircle id="endOfWindow" className="ml-2 my-auto" size={16} />
                    <UncontrolledTooltip placement="top" target="endOfWindow">Indicates when during the day the session should stop running. Times are in local, empty means no limit.</UncontrolledTooltip>
                </div>
            </div>

            <div className="form-group">
                <div>
                    <label htmlFor="errorThreshold">Error Threshold</label>
                    <small className="float-right">*Optional</small>
                </div>
                <div className="d-flex">
                    <input
                        name="errorThreshold"
                        type="number"
                        className="form-control"
                        max={100}
                        value={props.selected.errorThreshold ?? ""}
                        onChange={(e) => selectErrorThreshold(e.target.value)} />
                    <FaInfoCircle id="errorThreshold" className="ml-2 my-auto" size={16} />
                    <UncontrolledTooltip placement="top" target="errorThreshold">The max percentage of thermostats that can go into an error state before aborting the session.</UncontrolledTooltip>
                </div>
            </div>
        </div>
    );
};

export default SchedulerSelection;