import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { CreateFirmwareState } from "./state";

const unloadedState: CreateFirmwareState = {
    createRequestState: RequestState.NotStarted,
    progress: 0,
    newId: undefined,
    errorMessage: undefined
};

export const reducer: Reducer<CreateFirmwareState> = (state: CreateFirmwareState | undefined, incomingAction: Action): CreateFirmwareState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_CREATE_FIRMWARE":
            return {
                ...state,
                createRequestState: RequestState.InProgress,
                progress: 0,
                newId: undefined,
                errorMessage: undefined
            };
        case "PROGRESS_CREATE_FIRMWARE":
            return {
                ...state,
                progress: action.progress
            };
        case "RECEIVE_CREATE_FIRMWARE":
            return {
                ...state,
                createRequestState: RequestState.Succeeded,
                newId: action.firmwareId,
                errorMessage: undefined
            };
        case "REJECT_CREATE_FIRMWARE":
            return {
                ...state,
                createRequestState: RequestState.Failed,
                errorMessage: action.message
            };
        case "RESET_NEW_FIRMWARE_STATE":
            return {
                ...state,
                progress: 0,
                newId: undefined,
                errorMessage: undefined
            };
        default:
            return state;
    }
};
