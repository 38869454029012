import { Formik } from "formik";
import * as React from "react";
import { Spinner } from "reactstrap";
import * as Yup from "yup";
import { ChangeEmailRequest } from "../../store/UserManagement/EditUser/state";
import { getInputClassNames, validationSchemaCreators } from "../../utils";

interface ChangeEmailFormProps {
    isEmailConfirmed: boolean;
    isEmailChangeSuccessful: boolean;
    isEmailChangeSubmitting: boolean;
    changeEmailRequest: ChangeEmailRequest | null;
    changeEmail: (request: string) => void;
    isVerifyRequestSuccessful: boolean;
    isVerifyRequestFailed: boolean;
    isVerifyRequestSubmitting: boolean;
    initializeEmailVerification: () => void;
}

interface ChangeEmailFormValues extends ChangeEmailRequest {

}

export const ChangeEmailForm: React.FC<ChangeEmailFormProps> = (props) => {

    const initialValues: ChangeEmailFormValues =
        !!props.changeEmailRequest ? {
            email: props.changeEmailRequest.email
        } : {
            email: ""
        };

    const validationSchema = Yup.object().shape<ChangeEmailFormValues>({
        email: validationSchemaCreators.emailSchema()
    });

    return (
        <Formik enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                props.changeEmail(values.email);
            }}>
            {({ values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit, setFieldTouched }) =>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <div className="input-group">
                        <input name="email" value={values.email} onChange={(e) => {
                            handleChange(e);
                            setFieldTouched(e.target.name);
                        }} onBlur={handleBlur}
                        className={getInputClassNames({ isValid: (touched.email && !(!!errors.email)) })} />
                        <div className="input-group-append">
                            <button
                                type="submit"
                                onClick={() => handleSubmit()}
                                className="btn btn-primary rounded"
                                disabled={!dirty || !isValid || props.isEmailChangeSubmitting}>
                                {props.isEmailChangeSubmitting ?
                                    <React.Fragment>
                                        <Spinner color="info" className="spinnder-border spinner-border-sm" />
                                        {"Changing..."}
                                    </React.Fragment>
                                    :
                                    isValid ? "Save email" : "Fix Errors!"
                                }
                            </button>
                        </div>
                        {props.isEmailChangeSuccessful && <div className="valid-feedback d-block">Email successfully updated</div>}
                        <div className="invalid-feedback">{errors.email}</div>
                    </div>
                    <p className="mt-3">Email is <b>{(props.isEmailConfirmed ? "confirmed" : "not confirmed")}</b>.</p>
                    <button
                        disabled={props.isVerifyRequestSubmitting || props.isVerifyRequestSuccessful}
                        onClick={() => props.initializeEmailVerification()}
                        className="btn btn-primary btn-sm">Re-send confirmation email</button>
                    {props.isVerifyRequestFailed && <span className="text-danger ml-3">Failed to re-send confirmation email</span>}
                    {props.isVerifyRequestSuccessful && <div className="valid-feedback d-block">Successfully re-sent confirmation email</div>}
                </div>
            }
        </Formik>
    );
};