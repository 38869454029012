import { connectRouter, routerMiddleware } from "connected-react-router";
import { History } from "history";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { ApplicationState, reducers } from "./";

export default function configureStore(history: History, initialState?: ApplicationState) {

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    if (process.env.NODE_ENV === "development") {
        const { createLogger } = require("redux-logger");
        const logger = createLogger({
            // ...options, see https://github.com/LogRocket/redux-logger#options
        });
        middleware.push(logger);
    }

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    const enhancers = [];
    const windowIfDefined = typeof window === "undefined" ? null : window as any;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
