import * as React from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { ScheduleTable } from "./ScheduleTable";
import { formatUTCDate } from "../../utils";
import { CopyToClipboardText } from "../Common/CopyToClipboardText";
import { ScheduleItem } from "../../store/UserInformationView/state";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { PrintContext } from "./UserInformationView";

export type ScheduleDataProps = {
    schedulesData: ScheduleItem[];
}

export const ScheduleDataCard = (props: ScheduleDataProps) => {

    // Holds the keys of expanded daySchedules
    const [expandList, setExpandList] = React.useState<string[]>([]);

    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const [dropdownSelectedSchedule, setDropdownSelectedSchedule] = React.useState<ScheduleItem | undefined>(!props.schedulesData ? undefined : props.schedulesData[0]);

    const { isPrinting } = React.useContext(PrintContext);

    /**
     * Expands all daySchedules
     */
    function expandAll() {
        if (!props.schedulesData) {
            return;
        }
        const allDayScheduleKeys = [];
        for (const x of props.schedulesData) {
            for (const y of x.daySchedules) {
                allDayScheduleKeys.push(y.key);
            }
        }
        setExpandList(allDayScheduleKeys);
    }

    /**
     * Collapses all daySchedules
     */
    function collapseAll() {
        setExpandList([]);
    }

    /**
     * Toggles the visibility of a given daySchedule
     */
    const toggleDaySchedule = (keyToToggle: string) => {
        if (expandList.find(key => key === keyToToggle) !== undefined) {
            // Remove key
            setExpandList(expandList.filter(listKey => listKey !== keyToToggle));
        } else {
            // Add key
            setExpandList([...expandList, keyToToggle]);
        }
    };

    function areVisibleDaySchedulesExpanded(): boolean {
        for (const daySchedule of dropdownSelectedSchedule!.daySchedules) {
            // Skip dayschedules with no schedule events, as these are not shown in the schedule card
            // (otherwise leading to plus/minus button not changing state when all daySchedules are expanded/collapsed)
            if (daySchedule.schedulesEvents.length === 0) {
                continue;
            }
            // If we're missing a daySchedule key from the expandList, return false
            if (!expandList.includes(daySchedule.key)) {
                return false;
            }
        }

        return true;
    }

    return (
        <div className="card d-print-flex">
            <div className="card-header p-1 pl-3 d-flex align-items-center">
                <b>Schedules</b>
            </div>
            <div className="card-body py-2">
                {props.schedulesData === undefined ? <>No data</> :
                    <div className="d-flex mb-0 mt-0 pt-0 pb-0 align-items-center">
                        <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} className="mt-1">
                            <DropdownToggle caret>
                                {dropdownSelectedSchedule ? dropdownSelectedSchedule?.name : "Select Schedule"}
                            </DropdownToggle>
                            <DropdownMenu>
                                {props.schedulesData !== undefined && props.schedulesData?.map((schedule, sIndex) => (
                                    <DropdownItem key={"dropdown-schedule-" + schedule.id}
                                        onClick={() => setDropdownSelectedSchedule(schedule)}>
                                        {schedule.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <div className="ml-auto">
                            <button className="btn-sm btn-primary border-0" onClick={() => {
                                !areVisibleDaySchedulesExpanded() ? expandAll() : collapseAll();
                            }}>
                                {!areVisibleDaySchedulesExpanded() ? <AiOutlinePlus className="mt-n1" fontSize="1.3em" /> : <AiOutlineMinus className="mt-n1" fontSize="1.3em" />}
                            </button>
                        </div>
                    </div>
                }
                {props.schedulesData !== undefined && props.schedulesData.map((schedule, sIndex) => (
                    <div key={"schedule-" + sIndex}>
                        <h5 className="d-none mt-3 d-print-inline-block">{schedule.name}</h5>
                        <div id={"schedule-" + sIndex} className={`${dropdownSelectedSchedule?.id === schedule.id || isPrinting ? "" : "d-none"}`}>
                            <table className="table table-sm mt-2 mb-0 text-break">
                                <tbody>
                                    <tr>
                                        <th scope="row">Schedule name: </th>
                                        <td>{schedule.name}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Base temperature: </th>
                                        <td>{schedule.baseTemperature}℃</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Default schedule type: </th>
                                        <td>{schedule.defaultScheduleType}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Schedule type: </th>
                                        <td>{schedule.scheduleType}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Schedule ID: </th>
                                        <td><CopyToClipboardText btnText={schedule.id} /></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Created: </th>
                                        <td>{schedule.creationDate !== undefined ? formatUTCDate(schedule.creationDate) : "No data"}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr className="mt-2 mb-2"></hr>
                            {schedule.daySchedules !== undefined && schedule.daySchedules.map((daySchedule, dsIndex) => (
                                <div key={"daySchedule-" + dsIndex}>
                                    <ScheduleTable
                                        scheduleEvents={daySchedule.schedulesEvents}
                                        day={daySchedule.days.join(", ")}
                                        toggleCollapse={() => toggleDaySchedule(daySchedule.key)}
                                        show={isPrinting || expandList.includes(daySchedule.key)} />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};