import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { EditFirmwareState } from "./state";

const unloadedState: EditFirmwareState = {
    getRequestState: RequestState.NotStarted,
    updateRequestState: RequestState.NotStarted,
    existingFirmware: undefined
};

export const reducer: Reducer<EditFirmwareState> = (state: EditFirmwareState | undefined, incomingAction: Action): EditFirmwareState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        // Get firmware
        case "REQUEST_FIRMWARE_BY_ID":
            return {
                ...state,
                getRequestState: RequestState.InProgress,
                updateRequestState: RequestState.NotStarted,
                existingFirmware: undefined
            };
        case "RECEIVE_FIRMWARE_BY_ID":
            return {
                ...state,
                getRequestState: RequestState.Succeeded,
                existingFirmware: action.firmware
            };
        case "REJECT_FIRMWARE_BY_ID":
            return {
                ...state,
                getRequestState: RequestState.Failed,
                existingFirmware: undefined
            };

        // Update firmware
        case "REQUEST_UPDATE_FIRMWARE":
            return {
                ...state,
                updateRequestState: RequestState.InProgress
            };
        case "RECEIVE_UPDATE_FIRMWARE":
            if (!state.existingFirmware) {
                return state;
            }

            return {
                ...state,
                existingFirmware: { ...state.existingFirmware, ...action.firmware },
                updateRequestState: RequestState.Succeeded
            };
        case "REJECT_UPDATE_FIRMWARE":
            return {
                ...state,
                updateRequestState: RequestState.Failed
            };

        // Reset edit request states
        case "RESET_EDIT_FIRMWARE_STATE":
            return {
                ...state,
                getRequestState: RequestState.NotStarted,
                updateRequestState: RequestState.NotStarted
            };

        default:
            return state;
    }
};
