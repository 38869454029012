import { Formik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import { ChangeRoleRequest } from "../../store/UserManagement/EditUser/state";
import { getInputClassNames, validationSchemaCreators } from "../../utils";

interface ChangeRoleFormProps {
    isAdmin: boolean;
    isSuccessful: boolean;
    isSubmitting: boolean;
    changeRoleRequest: ChangeRoleRequest | null;
    changeRole: (request: string) => void;
}

interface ChangeRoleFormValues extends ChangeRoleRequest {

}

export const ChangeRoleForm: React.FC<ChangeRoleFormProps> = (props) => {

    const initialValues: ChangeRoleFormValues =
        !!props.changeRoleRequest ? {
            role: props.changeRoleRequest.role
        } : {
            role: ""
        };

    const validationSchema = Yup.object().shape<ChangeRoleFormValues>({
        role: validationSchemaCreators.roleSchema()
    });

    return (
        <Formik enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                props.changeRole(values.role);
            }}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldTouched }) =>
                <div className="form-group">
                    <label htmlFor="role">Role</label>
                    <select
                        name="role"
                        disabled={props.isSubmitting}
                        className={getInputClassNames({ isValid: (touched.role && !(!!errors.role)) })}
                        onChange={(e) => {
                            setFieldTouched(e.target.name);
                            handleChange(e);
                            handleSubmit();
                        }}
                        onBlur={handleBlur}
                        value={values.role}>
                        <option value="" label="Choose role..." />
                        <option value="user" label="User" />
                        <option value="supporter" label="Supporter" />
                        {props.isAdmin && <option value="administrator" label="Administrator" />}
                    </select>
                    <div className="invalid-feedback">{errors.role}</div>
                    {props.isSuccessful && <div className="valid-feedback d-block">Role successfully updated</div>}
                </div>
            }
        </Formik>
    );
};