import { Formik, FormikProps } from "formik";
import * as React from "react";
import {
    Spinner
} from "reactstrap";
import * as Yup from "yup";
import { ChangePasswordRequest } from "../../store/UserManagement/EditUser/state";
import { getInputClassNames, validationSchemaCreators } from "../../utils";
import { PasswordField } from "../Common";

interface ChangePasswordFormProps {
    isSuccessful: boolean;
    isSubmitting: boolean;
    changePasswordError: string | null;
    changePasswordRequest: ChangePasswordRequest | null;
    changePassword: (request: ChangePasswordRequest) => void;
    cancelEdit: () => void;
}

interface ChangePasswordFormValues extends ChangePasswordRequest {
    newPasswordConfirm: string;
}

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = (props) => {
    const formikRef = React.useRef<FormikProps<ChangePasswordFormValues> | null>(null);

    const initialValues: ChangePasswordFormValues =
        !!props.changePasswordRequest ? {
            oldPassword: props.changePasswordRequest.oldPassword,
            newPassword: props.changePasswordRequest.newPassword,
            newPasswordConfirm: props.changePasswordRequest.newPassword
        } : {
            oldPassword: "",
            newPassword: "",
            newPasswordConfirm: ""
        };

    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string()
            .required("*No old password specified!"),
        newPassword: validationSchemaCreators.passwordSchema(),
        newPasswordConfirm: Yup.string()
            .oneOf([Yup.ref("newPassword")], "*Passwords must match!")
            .required("*Password confirmation is required!")
    });

    React.useEffect(() => {
        if(props.isSuccessful && formikRef.current !== null){
            formikRef.current.resetForm({ values: initialValues });
        }
    }, [props.isSuccessful, initialValues]);

    return (
        <Formik enableReinitialize={true}
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                props.changePassword(values);
            }}>
            {({
                values,
                errors,
                touched,
                dirty,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                isValid,
                setFieldTouched
            }) =>
                (<div className="mt-2">

                    <div className="form-group">
                        <label className="text-danger">Note:</label>
                        <div className="text-danger mt-1">You are changing your password!</div>
                        <div className="text-danger mt-1">Do not share your password with anyone!</div>
                    </div>

                    <div className="form-group">
                        <label>Old Password:</label>
                        <PasswordField
                            name="oldPassword"
                            placeholder="Enter your existing password"
                            className={getInputClassNames({ isValid: !(touched.oldPassword && errors.oldPassword) })}
                            onChange={(e) => {
                                setFieldTouched(e.target.name);
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                            value={values.oldPassword} />
                        <div className="invalid-feedback">{errors.oldPassword}</div>
                    </div>

                    <div className="form-group">
                        <label>New Password:</label>
                        <PasswordField
                            name="newPassword"
                            placeholder="Enter your new password"
                            className={getInputClassNames({ isValid: !(touched.newPassword && errors.newPassword) })}
                            onChange={(e) => {
                                setFieldTouched(e.target.name);
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                            value={values.newPassword} />
                        <div className="invalid-feedback">
                            {errors.newPassword && errors.newPassword.split("\n").map(error => <>{error}<br /></>)}
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Confirm New Password:</label>
                        <PasswordField
                            name="newPasswordConfirm"
                            placeholder="Confirm your new password"
                            className={getInputClassNames({ isValid: !(touched.newPasswordConfirm && errors.newPasswordConfirm) })}
                            onChange={(e) => {
                                setFieldTouched(e.target.name);
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                            value={values.newPasswordConfirm} />
                        <div className="invalid-feedback">{errors.newPasswordConfirm}</div>
                    </div>

                    {!props.changePasswordError ? null : <p className="text-danger mb-3">
                        {props.changePasswordError.split("\n").map((e, i) => (i === 0 ? e : <li key={i}>{e}</li>))}
                    </p>}

                    <div className="form-group">
                        <button className={!dirty || props.isSubmitting ? "btn btn-sm btn-secondary" : isValid ? "btn btn-sm btn-primary" : "btn btn-sm btn-warning"}
                            onClick={() => handleSubmit()}
                            disabled={!dirty || props.isSubmitting || !isValid}>
                            {props.isSubmitting ?
                                (<React.Fragment>
                                    <Spinner color="info" className="spinner-border spinner-border-sm" />
                                    {" Changing..."}
                                </React.Fragment>) :
                                dirty ? isValid ? "Change Password" : "Fix Errors!" : "No Changes"}
                        </button>
                        <button className={props.isSubmitting ? "btn btn-sm btn-secondary ml-2" : "btn btn-sm btn-light ml-2"}
                            type="button"
                            disabled={props.isSubmitting}
                            onClick={e => {
                                e.preventDefault();
                                handleReset(e);
                                props.cancelEdit();
                            }}>
                            {"Cancel"}
                        </button>
                    </div>
                </div>
                )}
        </Formik>
    );
};