import { ApiError, callApiGateway } from "../../../ApiGateway";
import { hasViolation } from "../../../utils";
import { AppThunkAction } from "../../index";
import { KnownAction } from "./actions";
import { RejectReason, NewUserProfile } from "./state";

export const actionCreators = {
    /**
     * Create user.
     */
    createUser: (user: NewUserProfile): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_CREATE_USER" });
        callApiGateway<NewUserProfile, { userId: string }>("users", "POST", user)
            .then(response => dispatch({ type: "RECEIVE_CREATE_USER", userId: response.userId }))
            .catch(error => {
                let rejectReason = RejectReason.InternalError;

                if (error instanceof ApiError && !!error.status) {
                    if (hasViolation(error.status, "Specified email is already occupied")) {
                        rejectReason = RejectReason.EmailAlreadyUsed;
                    } else if (hasViolation(error.status, "Specified username is already occupied")) {
                        rejectReason = RejectReason.UsernameAlreadyUsed;
                    }
                }

                dispatch({ type: "REJECT_CREATE_USER", reason: rejectReason });
            });
    },

    /**
     * Reset new user state.
     */
    resetNewUserState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RESET_NEW_USER_STATE" });
    }
};
