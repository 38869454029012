import * as React from "react";
import { BuildingLookupTree } from "../../store/UserInformationView/state";
import { Tree } from "./Tree";
import { UserInformationViewProps } from "./UserInformationView";

export type BuildingsAndZonesDataProps = {
    buildingsTreeData: BuildingLookupTree[];
}

export const BuildingsAndZonesDataCard = (props: BuildingsAndZonesDataProps) => {

    return (
        <div className="card">
            <div className="card-header p-1 pl-3">
                <b>Buildings & Zones</b>
            </div>
            {props.buildingsTreeData === null ? <>No data</> :
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <Tree data={props.buildingsTreeData} />
                </div>
            }
        </div>
    );
};
