import * as React from "react";
import { FaStar } from "react-icons/fa";
import { EmailTemplateLanguage, EmailTemplateType, emailTemplateTypeToString } from "../../store/EmailTemplates";
import { PrivateLabelListItem } from "../../store/PrivateLabels/PrivateLabelsList/state";
import { RequestState } from "../../store";
import { MessageBox, MessageBoxType, Spinner } from "../Common";

export interface EmailTemplateSelectorProps {
    isAdmin: boolean;
    privateLabels?: PrivateLabelListItem[];
    privateLabelId: string;
    setPrivateLabelId: (privateLabelId: string) => void;
    getPrivateLabelTemplatesRequestState: RequestState;
    getPrivateLabelTemplates: (privateLabelId: string) => void;
    templateType: EmailTemplateType;
    setEmailTemplateType: (type: EmailTemplateType) => void;
    templateLanguage: EmailTemplateLanguage;
    setEmailTemplateLanguage: (language: EmailTemplateLanguage) => void;
}

export const EmailTemplateSelector = (props: React.PropsWithChildren<EmailTemplateSelectorProps>) => {
    const allTemplateTypes = [
        EmailTemplateType.ConfirmEmail,
        EmailTemplateType.ResetPassword,
        EmailTemplateType.AccountLocked
    ];

    return (<>
        {/* Private label dropdown */}
        {props.isAdmin && props.privateLabels && <>
            <div style={{ width: "500px" }} className="input-group mb-3">
                <div className="input-group-prepend">
                    <div className="input-group-text" style={{ width: "150px" }}>
                        <FaStar />&nbsp;
                        Private Label
                    </div>
                </div>
                <select
                    className="form-control"
                    name="private-label"
                    onChange={e => props.setPrivateLabelId(e.target.value)}
                    value={props.privateLabelId}>
                    {props.privateLabels.map(pl => <option
                        key={pl.id}
                        value={pl.id}
                        label={pl.displayName} />)}
                </select>
            </div>
        </>}

        {/* State for getting private label templates */}
        {props.getPrivateLabelTemplatesRequestState === RequestState.InProgress && <Spinner />}
        {props.getPrivateLabelTemplatesRequestState === RequestState.Failed && <MessageBox
            type={MessageBoxType.Error}
            title="Request failed"
            description={"Failed to load email templates."}>
            <button
                onClick={() => props.getPrivateLabelTemplates(props.privateLabelId)}
                className="btn btn-primary mt-3">Try again</button>
        </MessageBox>}

        {props.getPrivateLabelTemplatesRequestState === RequestState.Succeeded && <>
            {/* Template type selector */}
            <ul className="nav nav-tabs">
                {allTemplateTypes.map(type => <li className="nav-item" key={type}>
                    <button
                        className={`btn nav-link ${props.templateType === type ? "active font-weight-bold" : ""}`}
                        onClick={() => props.setEmailTemplateType(type)}>
                        {emailTemplateTypeToString(type)}
                    </button>
                </li>)}
            </ul>

            <div className="border-left border-bottom border-right rounded-bottom p-3">
                {props.children}
            </div>
        </>}
    </>);
};
