import { callApiGateway } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import { KnownAction } from "./actions";
import { SessionData } from "./state";

export const actionCreators = {
    /**
     * Get session by id.
     */
    getSessionById: (sessionId: string): AppThunkAction<KnownAction> => (dispatch) => {
        callApiGateway<{}, SessionData>(`firmware/sessions/${sessionId}`)
            .then(response => dispatch({ type: "RECEIVE_SESSION_BY_ID", session: response }))
            .catch(() => dispatch({ type: "REJECT_SESSION_BY_ID" }));
        dispatch({ type: "REQUEST_SESSION_BY_ID" });
    },

    /**
     * Reset detail request states to NotStarted.
     */
    resetRequestStates: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "RESET_SESSION_DETAILS_STATE" });
    },
};