import * as React from "react";

type FileUploadZoneProps = {
    onDragStateChange?: (isDragActive: boolean) => void;
    onDrag?: () => void;
    onDragIn?: () => void;
    onDragOut?: () => void;
    onDrop?: () => void;
    onFileDrop?: (file: File) => void;
}

export const FileUploadZone = React.memo(
    (props: React.PropsWithChildren<FileUploadZoneProps>) => {
        const {
            onDragStateChange,
            onFileDrop,
            onDrag,
            onDragIn,
            onDragOut,
            onDrop
        } = props;

        const [isDragActive, setIsDragActive] = React.useState(false);
        const fileUploadZoneRef = React.useRef<null | HTMLDivElement>(null);

        const handleDragIn = React.useCallback(
            (event) => {
                event.preventDefault();
                event.stopPropagation();
                onDragIn?.();

                if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
                    setIsDragActive(true);
                }
            }, [onDragIn]);

        const handleDragOut = React.useCallback(
            (event) => {
                event.preventDefault();
                event.stopPropagation();
                onDragOut?.();

                setIsDragActive(false);
            }, [onDragOut]);

        const handleDrag = React.useCallback(
            (event) => {
                event.preventDefault();
                event.stopPropagation();
                onDrag?.();

                if (!isDragActive) {
                    setIsDragActive(true);
                }
            }, [isDragActive, onDrag]);

        const handleDrop = React.useCallback(
            (event) => {
                event.preventDefault();
                event.stopPropagation();
                setIsDragActive(false);
                onDrop?.();

                if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
                    const fileToUpload: File = event.dataTransfer.files.item(0);
                    onFileDrop?.(fileToUpload);
                    event.dataTransfer.clearData();
                }
            }, [onDrop, onFileDrop]);

        React.useEffect(() => {
            onDragStateChange?.(isDragActive);
        }, [onDragStateChange, isDragActive]);

        React.useEffect(() => {
            const tempZoneRef = fileUploadZoneRef.current;

            tempZoneRef?.addEventListener("dragenter", handleDragIn);
            tempZoneRef?.addEventListener("dragleave", handleDragOut);
            tempZoneRef?.addEventListener("dragover", handleDrag);
            tempZoneRef?.addEventListener("drop", handleDrop);

            return () => {
                tempZoneRef?.removeEventListener("dragenter", handleDragIn);
                tempZoneRef?.removeEventListener("dragleave", handleDragOut);
                tempZoneRef?.removeEventListener("dragover", handleDrag);
                tempZoneRef?.removeEventListener("drop", handleDrop);
            };
        });

        const inputFile = React.useRef<HTMLInputElement | null>(null);

        const selectFile = (files: FileList | null) => {
            if (files && files.length > 0) {
                onFileDrop?.(files.item(0)!);
            }
        };

        return (
            <div ref={fileUploadZoneRef} onClick={() => inputFile.current?.click()} className="w-100 d-flex flex-column text-center" style={{ cursor: "pointer" }}>
                <input type="file" id="file" ref={inputFile} onChange={(e) => selectFile(e.currentTarget.files)} style={{ display: "none" }} />
                {props.children}
            </div>
        );
    }
);

FileUploadZone.displayName = "FileUploadZone";