import * as React from "react";
import { Prompt } from "react-router-dom";

export interface UnloadPromptProps {
    hasUnsavedChanges: boolean;
}

export const unloadWarningMessage = "It looks like you have been editing something. If you leave before saving, your changes will be lost.";

export const UnloadPrompt = (props: UnloadPromptProps) => {
    const handler = (e: BeforeUnloadEvent) => {
        if (!props.hasUnsavedChanges) {
            return null;
        }

        e.preventDefault();
        e.returnValue = unloadWarningMessage;
        return unloadWarningMessage;
    };

    React.useEffect(() => {
        window.addEventListener("beforeunload", handler);
        return () => {
            window.removeEventListener("beforeunload", handler);
        };
    }, [handler]);

    return <Prompt when={props.hasUnsavedChanges} message={unloadWarningMessage} />;
};
