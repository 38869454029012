import * as React from "react";
import { Collapse } from "reactstrap";
import { ScheduleEvent } from "../../store/UserInformationView/state";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

export interface ScheduleTableProps {
    scheduleEvents: ScheduleEvent[];
    day: string;
    toggleCollapse: Function;
    show: boolean;
}

export const ScheduleTable = (props: ScheduleTableProps) => {

    return (
        <div className="d-block mb-1">
            {props.scheduleEvents.length !== 0 && <>
                <button className="btn-sm btn-primary border-0 schedule-table-btn" onClick={() => props.toggleCollapse()}>
                    <b>{props.day + " "}{props.show ? <AiFillCaretUp className="mt-n1" /> : <AiFillCaretDown />}</b>
                </button>
                <div>
                    <Collapse isOpen={props.show}>
                        <table className="table table-sm d-print-table">
                            <thead>
                                <tr>
                                    <td><b>Temp</b></td>
                                    <td><b>Start Time</b></td>
                                    <td><b>End Time</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                {props.scheduleEvents.map((event, eventIndex) => (
                                    <tr key={eventIndex} className="d-print-table-row">
                                        <td>
                                            {event.temperature}℃
                                        </td>
                                        <td>
                                            {event.start}
                                        </td>
                                        <td>
                                            {event.end}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Collapse>
                </div>
            </>}
        </div>
    );
};