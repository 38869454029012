import * as React from "react";
import { confirmAlert } from "react-confirm-alert";
import { IDataTableColumn } from "react-data-table-component";
import { FaArrowAltCircleRight, FaDownload, FaRedo, FaSlidersH, FaStop, FaTrash, FaUserAlt } from "react-icons/fa";
import { connect } from "react-redux";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { ApplicationState } from "../../store";
import { IAuthProps } from "../../store/authTypes";
import { actionCreators } from "../../store/FirmwareUpdates/ThermostatSearch/actionCreators";
import { actionCreators as retryWizardActionCreators } from "../../store/FirmwareUpdates/RetryThermostatWizard/actionCreators";
import { ConfirmAlert, MessageBox, MessageBoxType, Page, withAuthProps } from "../Common";
import { UncontrolledTooltip } from "reactstrap";
import { formatShortDateTime, maybePluralize } from "../../utils";
import SearchableDataTable, { DataTableFilterSelectorDetails } from "../Common/SearchableDataTable/SearchableDataTable";
import SearchRequest, { defaultSearchRequest } from "../../store/search";
import { FirmwareThermostatSearchState, ThermostatSearchResultItem } from "../../store/FirmwareUpdates/ThermostatSearch/state";
import ThermostatState from "../../store/FirmwareUpdates/Shared/ThermostatState";
import ThermostatError from "../../store/FirmwareUpdates/Shared/ThermostatError";
import { RequestState } from "../../store/sharedTypes";

type ThermostatPageProps =
    FirmwareThermostatSearchState &
    typeof actionCreators &
    typeof retryWizardActionCreators &
    IAuthProps &
    RouteComponentProps<{ query?: string }>;

const ThermostatPage = (props: ThermostatPageProps) => {
    // Component state
    const pageTitle = "Firmware Thermostats";
    const query = props.match.params.query;

    if (!props.isAdmin) {
        return <Page title={"Firmware Thermostats"}>
            <MessageBox
                type={MessageBoxType.Error}
                title="Access Denied!"
                description="You do not have permissions to access this page.">
                <button
                    className="btn btn-primary mt-3"
                    onClick={props.history.goBack}>
                    Go Back
                </button>
            </MessageBox>
        </Page>;
    }

    const goToFirmwareButton =
        <NavLink
            className="btn btn-primary btn-sm ml-3 mb-3"
            to="/firmware/">
            Firmware
        </NavLink>;

    const goToSessionsButton =
        <NavLink
            className="btn btn-primary btn-sm ml-3 mb-3"
            to="/firmware/sessions/">
            Sessions
        </NavLink>;

    const defaultSearch: SearchRequest = {
        ...defaultSearchRequest,
        query: query ?? "",
        order: {
            terms: [{
                field: "CreatedDate",
                desc: true
            }]
        }
    };

    const getColor = (state: string): string => {
        switch (state) {
            case "Idle":
                return "secondary";
            case "Downloading":
                return "secondary progress-bar-striped progress-bar-animated";
            case "Downloaded":
                return "warning";
            case "Validating":
                return "warning progress-bar-striped progress-bar-animated";
            case "Validated":
                return "info";
            case "Updating":
                return "info progress-bar-striped progress-bar-animated";
            case "Complete":
                return "success";
            case "Error":
                return "danger";
            case "Stopped":
                return "info";
            default:
                return "primary";
        }
    };

    const contextComponent = (selected: ThermostatSearchResultItem[], clearRows: () => void) => (
        <>
            {maybePluralize(selected.length, "thermostat")} selected
            <div className="d-flex">
                {(selected.length > 0 && selected.find(s => s.state.toString() === "Error")) &&
                    <NavLink
                        className="btn btn-info btn-sm ml-2"
                        to={"/firmware/thermostats/retry"}
                        onClick={() => {
                            const ids = selected
                                .filter(x => x.state.toString() === "Error")
                                .map(x => x.id);
                            props.selectRetryThermostats(ids);
                        }}>
                        <FaRedo className="mt-n1" /> Retry Errored
                    </NavLink>}

                <button
                    className="btn btn-danger btn-sm ml-2"
                    disabled={selected.length === 0}
                    onClick={e => {
                        e.preventDefault();
                        confirmAlert({
                            customUI: ({ onClose }) => <ConfirmAlert
                                title="Are you sure?"
                                description={`You are about to cancel updates for ${maybePluralize(selected.length, "thermostat")}!`}
                                onCancel={onClose}
                                onConfirm={() => {
                                    props.cancelThermostatRange(
                                        selected.filter(u =>
                                            u.state.toString() === "Complete"
                                            || u.state.toString() === "Error"
                                            || u.state.toString() === "Stopped")
                                            .map(u => u.thermostatId));
                                    onClose();
                                    clearRows();
                                }}
                                confirmText="Yes, I am sure"
                                cancelText="Cancel" />
                        });
                    }}><FaStop className="mt-n1" /> Cancel</button>
            </div>
        </>);

    const filters: DataTableFilterSelectorDetails[] = [{
        field: "State",
        values: Object.keys(ThermostatState).filter(key => isNaN(parseInt(key)))
    },
    {
        field: "ErrorState",
        values: Object.keys(ThermostatError).filter(key => isNaN(parseInt(key))),
        display: "Errors"
    }];

    const columns: IDataTableColumn[] = [
        { name: "Serial Number", selector: "serialNumber", sortable: true, maxWidth: "100px" },
        { name: "User Email", selector: "userEmail", sortable: true, maxWidth: "250px" },
        {
            name: "State",
            selector: "state",
            cell: (thermostat: ThermostatSearchResultItem) => (
                <p className="h6 w-100">
                    {thermostat.state.toString() === "Error"
                        ? <span className="p-2 badge badge-danger w-100">{thermostat.errorState}</span>
                        : <span className={`p-2 w-100 badge badge-${getColor(thermostat.state.toString())}`}>{thermostat.state}</span>
                    }
                </p>
            ),
            sortable: true,
            minWidth: "150px"
        },
        {
            name: "Download Progress",
            selector: "lastConfirmedChunk",
            cell: (thermostat: ThermostatSearchResultItem) => {
                const progress = ((thermostat.lastConfirmedChunk + 1) / thermostat.chunkCount);
                return (
                    <p
                        key={`progress-${thermostat.id}`}
                        id={`progress-${thermostat.id}`}
                        className="h6 w-100">
                        <span className={`p-2 w-100 badge text-center ${progress === 1 ? "badge-success" : "badge-light"}`}>
                            {`${(progress * 100).toFixed(1)}%`}
                        </span>
                        <UncontrolledTooltip
                            placement="top"
                            target={`progress-${thermostat.id}`}>
                            <span className="p-1 m-1">
                                {thermostat.lastConfirmedChunk + 1} / {thermostat.chunkCount}
                            </span>
                        </UncontrolledTooltip>
                    </p>
                );
            },
            sortable: true,
            maxWidth: "125px"
        },
        { name: "Originating Software Version", selector: "initialSoftwareVersion", sortable: true, maxWidth: "125px" },
        { name: "Update to Software Version", selector: "firmwareSoftwareVersion", sortable: true, maxWidth: "125px" },
        { name: "Originating WiFi Version", selector: "initialWifiVersion", sortable: true, maxWidth: "125px" },
        { name: "Update to WiFi Version", selector: "firmwareWifiVersion", sortable: true, maxWidth: "125px" },
        {
            name: "Started At", selector: "startedAt", cell: s => {
                return s.startedAt ? formatShortDateTime(s.startedAt) : "N/A";
            }, sortable: true, maxWidth: "125px"
        },
        {
            name: "Finished At", selector: "endedAt", cell: s => {
                return s.endedAt ? formatShortDateTime(s.endedAt) : "N/A";
            }, sortable: true, maxWidth: "125px"
        },
        {
            name: "Actions",
            cell: (thermostat: ThermostatSearchResultItem) => (
                <div className="w-100">
                    <NavLink
                        id={`thermostat-${thermostat.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        to={`/thermostat/${thermostat.thermostatId}`}>
                        <FaSlidersH size={15} />
                    </NavLink>
                    <UncontrolledTooltip placement="top" target={`thermostat-${thermostat.id}`}>See Thermostat Details</UncontrolledTooltip>

                    <NavLink
                        id={`owner-${thermostat.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        to={`/users/edit/${thermostat.userId}`}>
                        <FaUserAlt size={15} />
                    </NavLink>
                    <UncontrolledTooltip placement="top" target={`owner-${thermostat.id}`}>See Associated User Account</UncontrolledTooltip>

                    <NavLink
                        id={`session-${thermostat.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        to={`/firmware/session/${thermostat.sessionId}`}>
                        <FaArrowAltCircleRight size={15} />
                    </NavLink>
                    <UncontrolledTooltip placement="top" target={`session-${thermostat.id}`}>See Session</UncontrolledTooltip>

                    <NavLink
                        id={`firmware-${thermostat.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        to={`/firmware/${thermostat.firmwareId}`}>
                        <FaDownload size={15} />
                    </NavLink>
                    <UncontrolledTooltip placement="top" target={`firmware-${thermostat.id}`}>See Firmware</UncontrolledTooltip>

                    <button
                        id={`cancel-${thermostat.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        type="button"
                        disabled={thermostat.state.toString() === "Complete"
                            || thermostat.state.toString() === "Error"
                            || thermostat.state.toString() === "Stopped"
                            || props.cancelRequestState === RequestState.InProgress}
                        onClick={e => {
                            e.preventDefault();
                            confirmAlert({
                                customUI: ({ onClose }) => <ConfirmAlert
                                    title="Are you sure?"
                                    description={`Are you sure you want to cancel thermostat ${thermostat.thermostatId}?`}
                                    onCancel={onClose}
                                    onConfirm={() => {
                                        props.cancelThermostat(thermostat.thermostatId);
                                        onClose();
                                    }}
                                    confirmText="Yes, I am sure"
                                    cancelText="Cancel" />
                            });
                        }}>
                        <FaStop size={15} />
                    </button>
                    <UncontrolledTooltip placement="top" target={`cancel-${thermostat.id}`}>Cancel {thermostat.thermostatId}</UncontrolledTooltip>

                    {thermostat.state.toString() === "Error" &&
                        <>
                            <NavLink
                                id={`retry-${thermostat.id}`}
                                className="btn shadow-none text-dark btn-sm mt-n1"
                                to={"/firmware/thermostats/retry"}
                                onClick={() => {
                                    props.selectRetryThermostats([thermostat.id]);
                                }}>
                                <FaRedo size={15} />
                            </NavLink>
                            <UncontrolledTooltip placement="top" target={`retry-${thermostat.id}`}>Retry Thermostat</UncontrolledTooltip>
                        </>

                    }
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            minWidth: "250px"
        }
    ];

    return (
        <Page title={pageTitle}>
            {goToFirmwareButton}
            {goToSessionsButton}

            <SearchableDataTable
                state={props.searchRequestState}
                columns={columns}
                response={props.searchResult}
                search={props.searchThermostats}
                defaultSearchRequest={defaultSearch}
                filters={filters}
                displayText={"thermostat"}
                pluralSuffix={"s"}
                contextComponent={contextComponent} />
        </Page>
    );
};

export default withAuthProps(connect(
    (state: ApplicationState) => state.firmwareThermostatsSearch,
    { ...actionCreators, ...retryWizardActionCreators }
)(ThermostatPage as any));
