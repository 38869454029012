import * as React from "react";

interface ComfirmAlertProps {
    title: string;
    description: string | React.ReactNode;
    onConfirm: () => void;
    onCancel?: () => void;
    confirmText?: string;
    cancelText?: string;
}

export const ConfirmAlert = (props: ComfirmAlertProps) => {
    return (
        <div style={{ maxWidth: 700 }} className="p-3 rounded-lg shadow bg-white border border-primary">
            <h3>{props.title}</h3>
            <p>{props.description}</p>
            <div className="text-right">
                {!props.onCancel ? null : <button
                    className="btn btn-outline-primary"
                    onClick={props.onCancel}>{props.cancelText || "Cancel"}</button>}
                <button
                    className="btn btn-primary ml-3"
                    onClick={props.onConfirm}>{props.confirmText || "Confirm"}</button>
            </div>
        </div>
    );
};
