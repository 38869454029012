import { Field, FieldArray, Formik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Redirect, RouteComponentProps } from "react-router";
import { MessageBox, MessageBoxType, Page, withAuthProps, withCommonProps } from "../Common";
import { ApplicationState } from "../../store";
import { CommonState } from "../../store/Common/state";
import { IAuthProps } from "../../store/authTypes";
import { actionCreators } from "../../store/FirmwareUpdates/CreateFirmware/actionCreators";
import { RequestState } from "../../store/sharedTypes";
import { getInputClassNames, validationSchemaCreators } from "../../utils";
import { CreateFirmwareState } from "../../store/FirmwareUpdates/CreateFirmware/state";
import { FileUploadZone } from "../Common/FileUploadZone";
import { FaDownload, FaPlus, FaTrash } from "react-icons/fa";
import FirmwareVisibility from "../../store/FirmwareUpdates/Shared/FirmwareVisibility";
import EditableFirmwareData from "../../store/FirmwareUpdates/Shared/EditableFirmwareData";
import { UncontrolledTooltip } from "reactstrap";
import { Parse } from "../../store/FirmwareUpdates/CreateFirmware/headerParser";

type InputFieldProps = {
    value: string | undefined;
    name: string;
    label: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
    isValid: boolean;
    invalidFeedback?: string;
    optional?: boolean;
}

const TextInputField = (props: InputFieldProps) => {
    return (
        <div className="form-group">
            <div>
                <label htmlFor={props.name}>{props.label}</label>
                {props.optional && <small className="float-right">*Optional</small>}
            </div>
            <input name={props.name} value={props.value} onChange={props.onChange} onBlur={props.onBlur}
                className={getInputClassNames({ isValid: props.isValid })} />
            <div className="invalid-feedback">{props.invalidFeedback}</div>
        </div>
    );
};

type CreateFirmwareProps =
    CreateFirmwareState &
    CommonState &
    typeof actionCreators &
    IAuthProps &
    RouteComponentProps;

const CreateFirmware = (props: CreateFirmwareProps) => {
    const pageTitle = "Create New Firmware";
    const [redirectToEdit, setRedirectToEdit] = React.useState(false);

    const [isDropActive, setIsDropActive] = React.useState(false);
    const [file, setFile] = React.useState<File | null>(null);

    const onDragStateChange = React.useCallback((dragActive: boolean) => {
        setIsDropActive(dragActive);
    }, []);

    const onFileDrop = React.useCallback(async (newFile: File) => {
        const firmwareFileArrayBuffer = await newFile.arrayBuffer();
        const firmwareFileBuffer = Buffer.from(firmwareFileArrayBuffer);
        const header = await Parse(firmwareFileBuffer);

        // Only changing initialValues to not overwrite existing input
        initialValues.softwareVersion = header.softwareVersion;
        initialValues.wifiVersion = header.wifiVersion;
        initialValues.hardwareVersions = [header.hardwareVersion];
        setFile(newFile);
    }, []);

    // Redirect to EditFirmware
    if (!!props.newId && redirectToEdit) {
        props.resetNewFirmwareState();
        return <Redirect to={`/firmware/edit/${props.newId}`} />;
    }

    // Show success
    if (!!props.newId && !redirectToEdit) {
        setTimeout(() => setRedirectToEdit(true), 3000);
        return <Page title={pageTitle}><p>The new firmware has been created. You will be redirected to the edit page now.</p></Page>;
    }

    if (!props.isAdmin) {
        return <Page title={pageTitle}>
            <MessageBox
                type={MessageBoxType.Error}
                title="Access Denied!"
                description="You do not have permissions to access this page.">
                <button
                    className="btn btn-primary mt-3"
                    onClick={props.history.goBack}>
                    Go Back
                </button>
            </MessageBox>
        </Page>;
    }

    type FormValues = {
        visibility: string,
        axVersion: string | undefined,
        softwareVersion: string | undefined,
        wifiVersion: string | undefined,
        hardwareVersions: string[],
        product: string | undefined,
        changelog: string | undefined,
        notes: string | undefined
    };

    const initialValues: FormValues = {
        visibility: "Private",
        axVersion: "",
        softwareVersion: "",
        wifiVersion: "",
        hardwareVersions: [],
        product: "",
        changelog: "",
        notes: ""
    };

    const validationSchema = Yup.object().shape<FormValues>({
        visibility: validationSchemaCreators.visibilitySchema(),
        axVersion: validationSchemaCreators.axVersionSchema(),
        softwareVersion: validationSchemaCreators.swVersionSchema(),
        wifiVersion: validationSchemaCreators.wifiVersionSchema(),
        hardwareVersions: validationSchemaCreators.hwVersionsSchema(),
        product: validationSchemaCreators.productVersionSchema(),
        changelog: validationSchemaCreators.changelogSchema(),
        notes: validationSchemaCreators.notesSchema()
    });

    const submit = (values: FormValues) => {
        if (file !== null) {
            const str: FirmwareVisibility = values.visibility as unknown as FirmwareVisibility;
            const vals: EditableFirmwareData = { ...values, visibility: str };
            props.createFirmware(vals, file);
        }
    };

    return (
        <Page title={pageTitle} id="create-firmware-page">
            <Formik
                onSubmit={submit}
                validateOnChange
                validationSchema={validationSchema}
                initialValues={initialValues}>
                { /* eslint-disable-next-line complexity */}
                {({ values, dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, setFieldTouched }) =>
                    <form onSubmit={e => e.preventDefault()}>
                        {/* File */}
                        <div className="form-group">
                            <label htmlFor="file">Firmware File</label>
                            <div className="border rounded" style={{ backgroundColor: isDropActive ? "hsl(210, 100%, 93%)" : "white" }}>
                                <FileUploadZone onDragStateChange={onDragStateChange} onFileDrop={onFileDrop}>
                                    <h2><FaDownload /></h2>
                                    {file === null ?
                                        <h6>Upload file</h6> :
                                        <h6>{file.name}</h6>}
                                </FileUploadZone>
                            </div>
                        </div>

                        {/* Visibility */}
                        <div className="form-group">
                            <label htmlFor="visibility">Visibility</label>
                            <select
                                name="visibility"
                                className={getInputClassNames({ isValid: !(touched.visibility && errors.visibility) })}
                                onChange={(e) => {
                                    setFieldTouched(e.target.name);
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values.visibility}>
                                <option value="Private" label="Private" />
                                <option value="Unlisted" label="Unlisted" />
                                <option value="Public" label="Public" />
                            </select>
                            <div className="invalid-feedback">{errors.visibility}</div>
                        </div>

                        {/* Product */}
                        <div className="form-group">
                            <label htmlFor="product">Product</label>
                            <select
                                name="product"
                                className={getInputClassNames({ isValid: !(touched.product && errors.product) })}
                                onChange={(e) => {
                                    setFieldTouched(e.target.name);
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values.product}>
                                <option value="" label="Choose product..." />
                                <option value="UWG5" label="UWG5" />
                            </select>
                            <div className="invalid-feedback">{errors.product}</div>
                        </div>

                        {/* Ax Version */}
                        <TextInputField
                            name="axVersion" label="Ax Version"
                            isValid={!(touched.axVersion && errors.axVersion)} invalidFeedback={errors.axVersion}
                            value={values.axVersion} onChange={(e) => {
                                setFieldTouched(e.target.name);
                                handleChange(e);
                            }} onBlur={handleBlur} />

                        {/* Software Version */}
                        <TextInputField
                            name="softwareVersion" label="Software Version"
                            isValid={!(touched.softwareVersion && errors.softwareVersion)} invalidFeedback={errors.softwareVersion}
                            value={values.softwareVersion} onChange={(e) => {
                                setFieldTouched(e.target.name);
                                handleChange(e);
                            }} onBlur={handleBlur} />

                        {/* WiFi Version */}
                        <TextInputField
                            name="wifiVersion" label="WiFi Version"
                            isValid={!(touched.wifiVersion && errors.wifiVersion)} invalidFeedback={errors.wifiVersion}
                            value={values.wifiVersion} onChange={(e) => {
                                setFieldTouched(e.target.name);
                                handleChange(e);
                            }} onBlur={handleBlur} />

                        {/* Hardware Version */}
                        <div className="form-group">
                            <div>
                                <label className="" htmlFor="hardwareVersions">Hardware Versions</label>
                            </div>

                            <div className={!!(touched.hardwareVersions && errors.hardwareVersions) || values.hardwareVersions.length === 0 ? "is-invalid" : ""}>
                                <FieldArray
                                    name="hardwareVersions"
                                    render={arrayHelpers => (
                                        <div>
                                            {values.hardwareVersions && values.hardwareVersions.length > 0 ? (
                                                values.hardwareVersions.map((hw, index) => (
                                                    <div key={index}>
                                                        <div className="d-flex w-100 m-1" key={index}>
                                                            <div className="flex-grow-1">
                                                                <Field
                                                                    name={`hardwareVersions.${index}`}
                                                                    className="form-control"
                                                                    validate={(value: string) => {
                                                                        let errorMessage = "";
                                                                        if (value.length < 4) {
                                                                            errorMessage = "Hardware version must have at least 4 characters";
                                                                        }
                                                                        if (value.length > 10) {
                                                                            errorMessage = "Hardware version can't be longer than 10 characters";
                                                                        }
                                                                        if (!value.match(/^[a-zA-Z0-9]*$/)) {
                                                                            errorMessage = "Must be only numbers or latin letters";
                                                                        }
                                                                        return errorMessage;
                                                                    }} />
                                                            </div>

                                                            <div className="d-flex">
                                                                <button
                                                                    id={`remove-hwVersion-${index}`}
                                                                    className="btn shadow-none text-dark btn-sm mt-n1"
                                                                    type="button"
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        arrayHelpers.remove(index);
                                                                    }}>
                                                                    <FaTrash size={15} />
                                                                </button>
                                                                <UncontrolledTooltip placement="top" target={`remove-hwVersion-${index}`}>Remove {hw}</UncontrolledTooltip>
                                                                <button
                                                                    id={`add-hwVersion-${index}`}
                                                                    className="btn shadow-none text-dark btn-sm mt-n1"
                                                                    type="button"
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        arrayHelpers.push("");
                                                                    }}>
                                                                    <FaPlus size={15} />
                                                                </button>
                                                                <UncontrolledTooltip placement="top" target={`add-hwVersion-${index}`}>Add a new version</UncontrolledTooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <>
                                                    <button
                                                        id="add-hwVersion"
                                                        className="btn shadow-none text-dark btn-sm mt-n1"
                                                        type="button"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            arrayHelpers.push("");
                                                        }}>
                                                        <FaPlus size={15} />
                                                    </button>
                                                    <UncontrolledTooltip placement="top" target="add-hwVersion">Add a new version</UncontrolledTooltip>
                                                </>
                                            )}

                                        </div>
                                    )}
                                />
                            </div>
                            <div className="invalid-feedback">{errors.hardwareVersions}</div>
                        </div>

                        {/* Release Notes */}
                        <div className="form-group">
                            <div>
                                <label className="" htmlFor="changelog">Release Notes</label>
                            </div>
                            <textarea
                                name="changelog"
                                value={values.changelog}
                                onChange={(e) => {
                                    setFieldTouched(e.target.name);
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                className={getInputClassNames({ isValid: !(touched.changelog && errors.changelog) })}
                                style={{ height: "328px" }} />
                            <div className="invalid-feedback">{errors.changelog}</div>
                        </div>

                        {/* Internal Notes */}
                        <TextInputField
                            name="notes" label="Internal Notes" optional
                            isValid={!(touched.notes && errors.notes)} invalidFeedback={errors.notes}
                            value={values.notes} onChange={(e) => {
                                setFieldTouched(e.target.name);
                                handleChange(e);
                            }} onBlur={handleBlur} />

                        {/* Save */}
                        <div className="form-group mb-0">
                            {props.createRequestState === RequestState.InProgress &&
                                <div className="mt-3 progress">
                                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: (props.progress * 100) + "%" }}></div>
                                </div>}
                            <button
                                type="submit"
                                onClick={() => handleSubmit()}
                                className="btn btn-primary"
                                disabled={!dirty || !isValid || props.createRequestState === RequestState.InProgress}
                            >{props.createRequestState === RequestState.InProgress ? "Creating new firmware" : "Create new firmware"}</button>
                            <button onClick={() => props.history.goBack()} className="btn btn-primary ml-3">Cancel</button>
                            {props.createRequestState === RequestState.Failed && <p className="text-danger mt-3">{props.errorMessage}</p>}
                        </div>
                    </form>
                }
            </Formik>
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => ({ ...state.createFirmware }),
    actionCreators
)(CreateFirmware as any)));