import * as crypto from "crypto";
import { callApiGateway } from "../../../ApiGateway";
import { encode } from "base64-arraybuffer";
import { AppThunkAction } from "../../index";
import { KnownAction } from "./actions";
import {
    CreateFirmwareData,
    UploadFirmwareData
} from "./state";
import { EditableFirmwareData } from "../Shared/EditableFirmwareData";

export const actionCreators = {
    /**
     * Create firmware.
     */
    createFirmware: (firmware: EditableFirmwareData, file: File): AppThunkAction<KnownAction> => async (dispatch) => {
        try {
            // The max size on ingress is 1 MB, gRPC is 4 MB
            const chunkSize = 524288; // 0.5 MB
            const firmwareFileArrayBuffer = await file.arrayBuffer();
            const firmwareFileBuffer = Buffer.from(firmwareFileArrayBuffer);
            const fileSize = firmwareFileBuffer.byteLength;
            const chunksCount = Math.ceil(fileSize / chunkSize);

            /* Compute MD5 hash for data integrity verification */
            const md5 = crypto.createHash("md5").update(firmwareFileBuffer).digest("hex");

            /* Initial CreateFirmware request (meta about entire blob) */
            const createFirmwareData: CreateFirmwareData = firmware as CreateFirmwareData;
            createFirmwareData.size = fileSize;
            createFirmwareData.md5 = md5;
            createFirmwareData.incomingChunksCount = chunksCount;

            dispatch({ type: "REQUEST_CREATE_FIRMWARE" });
            callApiGateway<CreateFirmwareData, { firmwareId: string }>("firmware", "POST", createFirmwareData)
                .then(async response => {
                    /* Firmware created and ready for sequential upload */
                    let chunkNumber = 1;
                    const firmwareId = response.firmwareId;

                    while (chunkNumber <= chunksCount) {
                        // Compute bounds
                        const offset = (chunkNumber - 1) * chunkSize;
                        const proposedEnd = chunkNumber * chunkSize;
                        const actualEnd = proposedEnd > fileSize ? fileSize : proposedEnd;

                        // Upload bounded slice
                        const slice = firmwareFileBuffer.slice(offset, actualEnd);
                        const upload: UploadFirmwareData = {
                            firmwareChunk: encode(slice),
                            incomingChunkNumber: chunkNumber
                        };
                        await callApiGateway<UploadFirmwareData, {}>(`firmware/upload/${firmwareId}`, "POST", upload);

                        dispatch({ type: "PROGRESS_CREATE_FIRMWARE", progress: (offset / fileSize) });
                        chunkNumber++;
                    }

                    setTimeout(() => dispatch({ type: "RECEIVE_CREATE_FIRMWARE", firmwareId: response.firmwareId }), 500);
                })
                .catch(() => {
                    dispatch({
                        type: "REJECT_CREATE_FIRMWARE",
                        message: "Failed to create the firmware due to an internal error."
                    });
                });
        } catch (err) {
            dispatch({
                type: "REJECT_CREATE_FIRMWARE",
                message: "Failed to create the firmware due to an exception. Perhaps the file is in use / locked?"
            });
        }
    },

    /**
     * Reset new firmware state.
     */
    resetNewFirmwareState: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "RESET_NEW_FIRMWARE_STATE" });
    }
};
