import * as React from "react";
import { ContentState, EditorState, Modifier, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EmailTemplateVariable } from "../../store/EmailTemplates";
import "./WysiwygEditor.css";

export interface WysiwygEditorProps {
    originalHtml: string;
    setModifiedHtml: (html: string) => void;
    templateVariables: EmailTemplateVariable[];
}

export interface WysiwygEditorRef {
    addVariable: (v: EmailTemplateVariable) => void
}

export const WysiwygEditor = React.forwardRef((props: WysiwygEditorProps, ref) => {
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());

    React.useImperativeHandle<unknown, WysiwygEditorRef>(ref, () => ({
        addVariable(v: EmailTemplateVariable) {
            const currentContent = editorState.getCurrentContent();
            const currentSelection = editorState.getSelection();
            const currentStyle = editorState.getCurrentInlineStyle();
            const text = v.linkText ? `link:${Date.now()}` : v.name;
            let content = Modifier.replaceText(currentContent, currentSelection, text, currentStyle);

            if (v.linkText) {
                const html = draftToHtml(convertToRaw(content)).replace(text, `<a href="${v.name}">${v.linkText}</a>`);
                const draft = htmlToDraft(html);
                content = ContentState.createFromBlockArray(draft.contentBlocks, draft.entityMap);
            }

            setEditorState(EditorState.createWithContent(content));
        }
    }));

    React.useEffect(() => {
        const draft = htmlToDraft(props.originalHtml);
        const content = ContentState.createFromBlockArray(draft.contentBlocks, draft.entityMap);
        const state = EditorState.createWithContent(content);
        setEditorState(state);
    }, [props.originalHtml]);

    React.useEffect(() => {
        const content = editorState.getCurrentContent();
        const raw = convertToRaw(content);
        const html = draftToHtml(raw);
        props.setModifiedHtml(html);
    }, [editorState]);

    return (
        <div className="border rounded">
            <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                toolbar={{
                    options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "link",
                        "emoji",
                        "image"
                    ],
                    inline: {
                        inDropdown: false,
                        options: ["bold", "italic", "underline", "strikethrough"]
                    },
                    blockType: {
                        inDropdown: true,
                        options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote"]
                    },
                    fontSize: {
                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
                    },
                    fontFamily: {
                        options: ["Arial", "Georgia", "Impact", "Tahoma", "Times New Roman", "Verdana"]
                    },
                    list: {
                        options: ["unordered", "ordered", "indent", "outdent"],
                    },
                    textAlign: {
                        inDropdown: true,
                        options: ["left", "center", "right", "justify"],
                    },
                    colorPicker: {
                        colors: ["rgb(97,189,109)", "rgb(26,188,156)", "rgb(84,172,210)", "rgb(44,130,201)",
                            "rgb(147,101,184)", "rgb(71,85,119)", "rgb(204,204,204)", "rgb(65,168,95)", "rgb(0,168,133)",
                            "rgb(61,142,185)", "rgb(41,105,176)", "rgb(85,57,130)", "rgb(40,50,78)", "rgb(0,0,0)",
                            "rgb(247,218,100)", "rgb(251,160,38)", "rgb(235,107,86)", "rgb(226,80,65)", "rgb(163,143,132)",
                            "rgb(239,239,239)", "rgb(255,255,255)", "rgb(250,197,28)", "rgb(243,121,52)", "rgb(209,72,65)",
                            "rgb(184,49,47)", "rgb(124,112,107)", "rgb(209,213,216)"],
                    },
                    link: {
                        options: ["link", "unlink"],
                        showOpenOptionOnHover: false
                    },
                    emoji: {
                        emojis: [
                            "😀", "😁", "😂", "😃", "😉", "😋", "😎", "😍", "😗", "🤗", "🤔", "😣", "😫", "😴", "😌", "🤓",
                            "😛", "😜", "😠", "😇", "😷", "😈", "👻", "😺", "😸", "😹", "😻", "😼", "😽", "🙀", "🙈",
                            "🙉", "🙊", "👼", "👮", "🕵", "💂", "👳", "🎅", "👸", "👰", "👲", "🙍", "🙇", "🚶", "🏃", "💃",
                            "⛷", "🏂", "🏌", "🏄", "🚣", "🏊", "⛹", "🏋", "🚴", "👫", "💪", "👈", "👉", "👉", "👆", "🖕",
                            "👇", "🖖", "🤘", "🖐", "👌", "👍", "👎", "✊", "👊", "👏", "🙌", "🙏", "🐵", "🐶", "🐇", "🐥",
                            "🐸", "🐌", "🐛", "🐜", "🐝", "🍉", "🍄", "🍔", "🍤", "🍨", "🍪", "🎂", "🍰", "🍾", "🍷", "🍸",
                            "🍺", "🌍", "🚑", "⏰", "🌙", "🌝", "🌞", "⭐", "🌟", "🌠", "🌨", "🌩", "⛄", "🔥", "🎄", "🎈",
                            "🎉", "🎊", "🎁", "🎗", "🏀", "🏈", "🎲", "🔇", "🔈", "📣", "🔔", "🎵", "🎷", "💰", "🖊", "📅",
                            "✅", "❎", "💯",
                        ]
                    },
                    image: {
                        urlEnabled: true,
                        uploadEnabled: false,
                        alignmentEnabled: false,
                        previewImage: true,
                        inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                        defaultSize: {
                            height: "auto",
                            width: "auto",
                        }
                    }
                }}
            />
        </div>
    );
});