import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState, IAuthProps } from "../../store";
import { MessageBox, MessageBoxType, Page, withAuthProps } from "./../Common";
import { RouteComponentProps, NavLink as RouterNavLink } from "react-router-dom";

type AdminToolsPageProps =
    IAuthProps &
    RouteComponentProps;

const AdminToolsPage = (props: AdminToolsPageProps) => {
    const pageTitle = "Administrator Tools";

    if (!props.isAdmin) {
        return <Page title={pageTitle}>
            <MessageBox
                type={MessageBoxType.Error}
                title="Access Denied!"
                description="You do not have permissions to access this page.">
                <button
                    className="btn btn-primary mt-3"
                    onClick={props.history.goBack}>
                    Go Back
                </button>
            </MessageBox>
        </Page>;
    }

    return (
        <Page title={pageTitle}>
            <RouterNavLink to="/app-versions" title="App Versions">
                <button className="btn btn-primary m-1">
                    Manage mobile app versions
                </button>
            </RouterNavLink>
        </Page>
    );
};

export default withAuthProps(connect((state: ApplicationState) => state)(AdminToolsPage as any));