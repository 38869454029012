import { KnownAction } from "./actions";
import { RequestState } from "../sharedTypes";
import { AppThunkAction } from "..";
import { EmailTemplate, EnqueueEmailData, TemplateEditableData } from "./state";
import { callApiGateway } from "../../ApiGateway";

export const actionCreators = {
    getPrivateLabelTemplates: (privateLabelId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState.emailTemplates && appState.emailTemplates.getPrivateLabelTemplatesRequestState === RequestState.InProgress) {
            return;
        }

        dispatch({ type: "REQUEST_PRIVATE_LABEL_TEMPLATES" });
        callApiGateway<{}, EmailTemplate[]>("/emails/templates/" + privateLabelId, "GET")
            .then(data => dispatch({ type: "RECEIVE_PRIVATE_LABEL_TEMPLATES", templates: data }))
            .catch(_ => dispatch({ type: "REJECT_PRIVATE_LABEL_TEMPLATES" }));
    },

    restoreEmailTemplate: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState.emailTemplates && appState.emailTemplates.restoreEmailTemplateRequestState === RequestState.InProgress) {
            return;
        }

        dispatch({ type: "REQUEST_DEFAULT_EMAIL_TEMPLATE" });
        callApiGateway<{}, EmailTemplate>("/emails/templates/default/" + id, "PUT")
            .then(data => dispatch({ type: "RECEIVE_DEFAULT_EMAIL_TEMPLATE", template: data }))
            .catch(_ => dispatch({ type: "REJECT_DEFAULT_EMAIL_TEMPLATE" }));
    },

    saveEmailTemplate: (id: string, template: TemplateEditableData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState.emailTemplates && appState.emailTemplates.saveEmailTemplateRequestState === RequestState.InProgress) {
            return;
        }

        dispatch({ type: "REQUEST_SAVE_EMAIL_TEMPLATE" });
        callApiGateway<TemplateEditableData, {}>("/emails/templates/" + id, "PUT", template)
            .then(_ => dispatch({ type: "RECEIVE_SAVE_EMAIL_TEMPLATE", id, ...template }))
            .catch(_ => dispatch({ type: "REJECT_SAVE_EMAIL_TEMPLATE" }));
    },

    sendTestEmail: (email: EnqueueEmailData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState.emailTemplates && appState.emailTemplates.sendTestEmailRequestState === RequestState.InProgress) {
            return;
        }

        dispatch({ type: "REQUEST_SEND_TEST_EMAIL" });
        callApiGateway("/emails", "POST", email)
            .then(_ => dispatch({ type: "RECEIVE_SEND_TEST_EMAIL" }))
            .catch(_ => dispatch({ type: "REJECT_SEND_TEST_EMAIL" }));
    }
};
