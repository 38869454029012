import React from "react";

export interface WizardComponentProps {
    displayHeader: string;
    displaySelection: string;
    hasSelection: boolean;
    index?: number;
    children: React.ReactNode;
}

export const WizardComponent = (props: WizardComponentProps) => {
    return (
        <div className="d-flex flex-column">

            <div className={`d-flex bg-white shadow p-3 px-4 mb-1 border-left ${props.hasSelection ? "border-success" : "border-secondary"}`}>
                <div className="flex-grow-1">
                    <h5 className="font-bold text-muted text-uppercase my-auto">
                        {props.displayHeader}
                    </h5>
                </div>
                <div>
                    <h5 className="text-black-50 text-uppercase my-auto">
                        {props.displaySelection}
                    </h5>
                </div>
            </div>

            <div className={`d-flex border-left p-3 ml-2 ${props.hasSelection ? "border-success" : "border-secondary"}`}>
                <div className="mx-3 w-100">
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default WizardComponent;