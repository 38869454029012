// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

import { Action, Reducer } from "redux";
import { RequestState } from "../sharedTypes";
import { KnownAction } from "./actions";
import { UserInformationViewState } from "./state";

const unloadedState: UserInformationViewState = {
    requestState: RequestState.NotStarted,
    data: undefined
};

export const reducer: Reducer<UserInformationViewState> =
    (state: UserInformationViewState | undefined, incomingAction: Action):
        UserInformationViewState => {
        if (state === undefined) {
            return unloadedState;
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case "REQUEST_USER_INFO_BY_ID":
                return {
                    ...state,
                    data: undefined,
                    requestState: RequestState.InProgress
                };
            case "RECEIVE_USER_INFO_BY_ID":
                return {
                    ...state,
                    data: action.data,
                    requestState: RequestState.Succeeded
                };
            case "REJECT_USER_INFO_BY_ID":
                return {
                    ...state,
                    data: undefined,
                    requestState: RequestState.Failed
                };
            default:
                return state;
        }
    };
