import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { EditUserState } from "./state";

const unloadedState: EditUserState = {
    getUserRequestState: RequestState.NotStarted,
    updateUserRequestState: RequestState.NotStarted,
    changePasswordRequestState: RequestState.NotStarted,
    changeEmailRequestState: RequestState.NotStarted,
    changeUsernameRequestState: RequestState.NotStarted,
    changeRoleRequestState: RequestState.NotStarted,
    verifyEmailRequestState: RequestState.NotStarted,
    temporaryPasswordRequestState: RequestState.NotStarted,

    changePasswordError: null,
    existingUser: undefined
};

export const reducer: Reducer<EditUserState> = (state: EditUserState | undefined, incomingAction: Action): EditUserState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        // Get user
        case "REQUEST_USER_BY_ID":
            return {
                ...state,
                getUserRequestState: RequestState.InProgress,
                updateUserRequestState: RequestState.NotStarted,
                changePasswordRequestState: RequestState.NotStarted,
                changeEmailRequestState: RequestState.NotStarted,
                changeUsernameRequestState: RequestState.NotStarted,
                changeRoleRequestState: RequestState.NotStarted,
                verifyEmailRequestState: RequestState.NotStarted,
                temporaryPassword: undefined,
                existingUser: undefined,
                changePasswordError: null
            };
        case "RECEIVE_USER_BY_ID":
            return {
                ...state,
                getUserRequestState: RequestState.Succeeded,
                existingUser: action.user
            };
        case "REJECT_USER_BY_ID":
            return {
                ...state,
                getUserRequestState: RequestState.Failed,
                existingUser: undefined
            };

        // Update user
        case "REQUEST_UPDATE_USER":
            return {
                ...state,
                updateUserRequestState: RequestState.InProgress
            };
        case "RECEIVE_UPDATE_USER":
            if (!state.existingUser) {
                return state;
            }

            return {
                ...state,
                existingUser: { ...state.existingUser, ...action.user },
                updateUserRequestState: RequestState.Succeeded
            };
        case "REJECT_UPDATE_USER":
            return {
                ...state,
                updateUserRequestState: RequestState.Failed
            };

        // Initialize email verification
        case "REQUEST_INITIALIZE_EMAIL_VERIFICATION":
            return {
                ...state,
                verifyEmailRequestState: RequestState.InProgress
            };
        case "RECEIVE_INITIALIZE_EMAIL_VERIFICATION":
            return {
                ...state,
                verifyEmailRequestState: RequestState.Succeeded
            };
        case "REJECT_INITIALIZE_EMAIL_VERIFICATION":
            return {
                ...state,
                verifyEmailRequestState: RequestState.Failed
            };

        // Change role
        case "REQUEST_CHANGE_ROLE":
            return {
                ...state,
                changeRoleRequestState: RequestState.InProgress
            };
        case "RECEIVE_CHANGE_ROLE":
            if (!state.existingUser) {
                return state;
            }
            return {
                ...state,
                changeRoleRequestState: RequestState.Succeeded,
                existingUser: { ...state.existingUser, roles: [action.role] }
            };
        case "REJECT_CHANGE_ROLE":
            return {
                ...state,
                changeRoleRequestState: RequestState.Failed
            };

        // Change email
        case "RECEIVE_CHANGE_EMAIL":
            if (!state.existingUser) {
                return state;
            }
            return {
                ...state,
                changeEmailRequestState: RequestState.Succeeded,
                existingUser: {
                    ...state.existingUser,
                    isEmailConfirmed: false,
                    email: action.email
                }
            };
        case "REQUEST_CHANGE_EMAIL":
            return {
                ...state,
                changeEmailRequestState: RequestState.InProgress
            };
        case "REJECT_CHANGE_EMAIL":
            return {
                ...state,
                changeEmailRequestState: RequestState.Failed
            };

        // Change username
        case "REQUEST_CHANGE_USERNAME":
            return {
                ...state,
                changeUsernameRequestState: RequestState.InProgress
            };
        case "RECEIVE_CHANGE_USERNAME":
            if (!state.existingUser) {
                return state;
            }
            return {
                ...state,
                changeUsernameRequestState: RequestState.Succeeded,
                existingUser: { ...state.existingUser, username: action.username }
            };
        case "REJECT_CHANGE_USERNAME":
            return {
                ...state,
                changeUsernameRequestState: RequestState.Failed
            };

        // Change password
        case "REQUEST_CHANGE_PASSWORD":
            return {
                ...state,
                changePasswordRequestState: RequestState.InProgress
            };
        case "RECEIVE_CHANGE_PASSWORD":
            return {
                ...state,
                changePasswordRequestState: RequestState.Succeeded,
                changePasswordError: null
            };
        case "REJECT_CHANGE_PASSWORD":
            return {
                ...state,
                changePasswordRequestState: RequestState.Failed,
                changePasswordError: action.error
            };

        // Temporary password
        case "REQUEST_TEMPORARY_PASSWORD":
            return {
                ...state,
                temporaryPasswordRequestState: RequestState.InProgress
            };
        case "RECEIVE_TEMPORARY_PASSWORD":
            return {
                ...state,
                temporaryPasswordRequestState: RequestState.Succeeded,
                temporaryPassword: action.temporaryPassword
            };
        case "REJECT_TEMPORARY_PASSWORD":
            return {
                ...state,
                temporaryPasswordRequestState: RequestState.Failed
            };
        case "RESET_TEMPORARY_PASSWORD":
            return {
                ...state,
                temporaryPasswordRequestState: RequestState.NotStarted,
                temporaryPassword: undefined
            };

        // Reset edit request states
        case "RESET_EDIT_REQUEST_STATES":
            return {
                ...state,
                updateUserRequestState: RequestState.NotStarted,
                changePasswordRequestState: RequestState.NotStarted,
                changeEmailRequestState: RequestState.NotStarted,
                changeUsernameRequestState: RequestState.NotStarted,
                changeRoleRequestState: RequestState.NotStarted,
                verifyEmailRequestState: RequestState.NotStarted,
                changePasswordError: null
            };

        default:
            return state;
    }
};
