import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import AppVersionState from "./state";

const unloadedState: AppVersionState = {
    getRequestState: RequestState.NotStarted,
    updateRequestState: RequestState.NotStarted,
    currentlyUpdating: null,
    appVersions: undefined
};

export const reducer: Reducer<AppVersionState> = (state: AppVersionState | undefined, incomingAction: Action): AppVersionState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        // Get app versions
        case "REQUEST_APP_VERSIONS":
            return {
                ...state,
                getRequestState: RequestState.InProgress,
                updateRequestState: RequestState.NotStarted,
                appVersions: undefined
            };
        case "RECEIVE_APP_VERSIONS":
            return {
                ...state,
                getRequestState: RequestState.Succeeded,
                appVersions: action.appVersions
            };
        case "REJECT_APP_VERSIONS":
            return {
                ...state,
                getRequestState: RequestState.Failed,
                appVersions: undefined
            };

        // Update app version
        case "REQUEST_UPDATE_APP_VERSION":
            return {
                ...state,
                updateRequestState: RequestState.InProgress,
                currentlyUpdating: action.id
            };
        case "RECEIVE_UPDATE_APP_VERSION":
            if (!state.appVersions) {
                return state;
            }

            const oldState = state.appVersions;
            const newState = oldState.map(appVersion => (appVersion.id === action.id ? { ...appVersion, ...action.appVersion } : appVersion));
            return {
                ...state,
                appVersions: newState,
                updateRequestState: RequestState.Succeeded,
                currentlyUpdating: action.id
            };
        case "REJECT_UPDATE_APP_VERSION":
            return {
                ...state,
                updateRequestState: RequestState.Failed,
                currentlyUpdating: action.id
            };

        // Reset edit request states
        case "RESET_APP_VERSION_STATE":
            return {
                ...state,
                getRequestState: RequestState.NotStarted,
                updateRequestState: RequestState.NotStarted,
                currentlyUpdating: null
            };

        default:
            return state;
    }
};
