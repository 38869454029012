import * as React from "react";
import { FaCheckCircle, FaExclamationCircle, FaHourglassHalf, FaWindowRestore, FaSave } from "react-icons/fa";
import { RequestState } from "../../store";

export interface EmailTemplateRestoreBoxProps {
    templateId: string;
    privateLabelId: string;
    restoreEmailTemplate: (templateId: string) => void;
    getPrivateLabelTemplates: (privateLabelId: string) => void;
    restoreEmailTemplateRequestState: RequestState;
}

export const EmailTemplateRestoreBox = (props: EmailTemplateRestoreBoxProps) => <>
    <div className="alert alert-primary p-3 mb-0">
        <div className="input-group mb-3">
            <button
                onClick={
                    () => {
                        if (window.confirm("Template will be replaced with the default one! Are you sure?")) {
                            props.restoreEmailTemplate(props.templateId);
                            props.getPrivateLabelTemplates(props.privateLabelId);
                        }
                    }
                }
                className="btn btn-primary">
                <FaWindowRestore className="mt-n1" /> Restore email template
            </button>
        </div>

        {props.restoreEmailTemplateRequestState === RequestState.InProgress &&
            <span className="ml-3 text-primary"><FaHourglassHalf className="mt-n1" /> Restoring email template...</span>}

        {props.restoreEmailTemplateRequestState === RequestState.Failed &&
            <span className="ml-3 text-danger"><FaExclamationCircle className="mt-n1" /> Failed to restore email template</span>}

        {props.restoreEmailTemplateRequestState === RequestState.Succeeded &&
            <span className="ml-3 text-success"><FaCheckCircle className="mt-n1" /> Default template successfully restored</span>}
    </div>
</>;