import * as React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { v4 as uuid } from "uuid";

export interface CTCProps {
    btnText: string;
}

export const CopyToClipboardText = (props: CTCProps) => {
    const id = "btnToolTip-" + uuid();
    return (
        <div>
            <button id={id} className="btn btn-light m-0 px-2 py-0" onClick={() => {
                navigator.clipboard.writeText(props.btnText);
            }}>
                {props.btnText}
            </button>
            <UncontrolledTooltip placement="right" target={id}>Copy</UncontrolledTooltip>
        </div>
    );
};
