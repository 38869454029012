import * as React from "react";
import { Page } from "./Common";

export default () => {
    return (
        <Page title="Not found">
            <p>The page you're looking for was not found.</p>
        </Page>
    );
};
