import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { EditVisibilityState } from "./state";

const unloadedState: EditVisibilityState = {
    visibilitySearchRequestState: RequestState.NotStarted,
    createRequestState: RequestState.NotStarted,
    deleteRequestState: RequestState.NotStarted,
    searchResult: undefined
};

export const reducer: Reducer<EditVisibilityState> = (state: EditVisibilityState | undefined, incomingAction: Action): EditVisibilityState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        // Get
        case "SEARCH_FIRMWARE_VISIBILITY":
            return {
                ...state,
                visibilitySearchRequestState: RequestState.InProgress,
                searchResult: undefined
            };
        case "RECEIVE_FIRMWARE_VISIBILITY":
            return {
                ...state,
                visibilitySearchRequestState: RequestState.Succeeded,
                searchResult: action.response
            };
        case "REJECT_FIRMWARE_VISIBILITY":
            return {
                ...state,
                visibilitySearchRequestState: RequestState.Failed,
                searchResult: undefined
            };

        // Create
        case "CREATE_FIRMWARE_VISIBILITY":
            return {
                ...state,
                createRequestState: RequestState.InProgress
            };
        case "RECEIVE_CREATE_FIRMWARE_VISIBILITY":
            if (!state.searchResult) {
                return state;
            }

            return {
                ...state,
                createRequestState: RequestState.Succeeded
            };
        case "REJECT_CREATE_FIRMWARE_VISIBILITY":
            return {
                ...state,
                createRequestState: RequestState.Failed
            };

        // Delete
        case "DELETE_FIRMWARE_VISIBILITY":
            return {
                ...state,
                deleteRequestState: RequestState.InProgress
            };
        case "RECEIVE_DELETE_FIRMWARE_VISIBILITY":
            if (!state.searchResult) {
                return state;
            }

            const items = state.searchResult.items.filter(item => !action.visibility.thermostatIds.includes(item.thermostatId));

            return {
                ...state,
                searchResult: { ...state.searchResult, items: items },
                deleteRequestState: RequestState.Succeeded
            };
        case "REJECT_DELETE_FIRMWARE_VISIBILITY":
            return {
                ...state,
                deleteRequestState: RequestState.Failed
            };

        // Reset edit request states
        case "RESET_FIRMWARE_VISIBILITY_STATE":
            return {
                ...state,
                visibilitySearchRequestState: RequestState.NotStarted,
                createRequestState: RequestState.NotStarted,
                deleteRequestState: RequestState.NotStarted
            };

        default:
            return state;
    }
};
