// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

import { Action, Reducer } from "redux";
import { RequestState } from "../sharedTypes";
import { KnownAction } from "./actions";
import { ThermostatsListState, ThermostatsSearchFormValues } from "./state";

class Defaults {
    constructor() {
        this.paginationRowsPerPageOptions = [10, 20, 50, 100, 200, 500];
        this.defaultPageSize = this.paginationRowsPerPageOptions[2];
        this.defaultPageNumber = 0;
        this.maxPageSize = 1000;
        this.defaultSortAsc = true;
        this.defaultSortField = "name";
        this.defaultThermostatsSearchFormValues = {
            query: "",
            isOnline: ""
        };
    }

    readonly paginationRowsPerPageOptions: number[];
    readonly defaultPageNumber: number;
    readonly defaultPageSize: number;
    readonly maxPageSize: number;
    readonly defaultSortAsc: boolean;
    readonly defaultSortField: string;
    readonly defaultThermostatsSearchFormValues: ThermostatsSearchFormValues;
}

export const DefaultSettings = new Defaults();

const unloadedState: ThermostatsListState = {
    thermostatsResult: undefined,
    searchRequestState: RequestState.NotStarted,
    thermostatsSearchFormValues: { ...DefaultSettings.defaultThermostatsSearchFormValues },
    thermostatsSearchRequest: {
        query: DefaultSettings.defaultThermostatsSearchFormValues.query,
        filters: [],
        limit: DefaultSettings.defaultPageSize,
        offset: 0,
        orderBy: DefaultSettings.defaultSortField,
        orderDescending: !DefaultSettings.defaultSortAsc
    },
    unlinkThermostatState: RequestState.NotStarted,
    deleteThermostatState: RequestState.NotStarted
};

export const reducer: Reducer<ThermostatsListState> =
    (state: ThermostatsListState | undefined, incomingAction: Action):
        ThermostatsListState => {
        if (state === undefined) {
            return unloadedState;
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case "CHANGE_THERMOSTATS_SEARCH_REQUEST":
                const isOnlineFilter = (action.request.filters || []).find(f => f.field === "isOnline");
                const formValues: ThermostatsSearchFormValues = {
                    query: action.request.query || "",
                    isOnline: !!isOnlineFilter ? isOnlineFilter.value : ""
                };

                return {
                    ...state,
                    thermostatsSearchRequest: Object.assign(state.thermostatsSearchRequest!, action.request),
                    thermostatsSearchFormValues: formValues
                };
            case "REQUEST_THERMOSTATS":
                return {
                    ...state,
                    thermostatsSearchRequest: action.request,
                    searchRequestState: RequestState.InProgress,
                    unlinkThermostatState: RequestState.NotStarted
                };
            case "RECEIVE_THERMOSTATS":
                return {
                    ...state,
                    thermostatsResult: action.result,
                    searchRequestState: RequestState.Succeeded
                };
            case "REJECT_THERMOSTATS":
                return {
                    ...state,
                    searchRequestState: RequestState.Failed
                };

            // Unlink
            case "REQUEST_UNLINK_THERMOSTAT":
                return { ...state, unlinkThermostatState: RequestState.InProgress };

            case "RECEIVE_UNLINK_THERMOSTAT":
                return { ...state, unlinkThermostatState: RequestState.Succeeded };

            case "REJECT_UNLINK_THERMOSTAT":
                return { ...state, unlinkThermostatState: RequestState.Failed };

            // Delete
            case "REQUEST_DELETE_THERMOSTAT":
                return { ...state, deleteThermostatState: RequestState.InProgress };

            case "RECEIVE_DELETE_THERMOSTAT":
                return { ...state, deleteThermostatState: RequestState.Succeeded };

            case "REJECT_DELETE_THERMOSTAT":
                return { ...state, deleteThermostatState: RequestState.Failed };

            default:
                return state;
        }
    };
