import * as React from "react";
import { MessageBox, MessageBoxType } from "./MessageBox";
import { Page } from "./Page";

type FailureRetryPageProps = {
    pageTitle: string,
    title?: string,
    description?: string,
    retryText?: string,
    onRetry: Function
};

export const FailureRetryPage = (props: FailureRetryPageProps) => {
    return (
        <Page title={props.pageTitle}>
            <MessageBox
                type={MessageBoxType.Error}
                title={props.title ?? "Failed to load"}
                description={props.description ?? "An error occurred when loading data."}>
                <button
                    className="btn btn-primary mt-3"
                    onClick={() => props.onRetry()}>{props.retryText ?? "Try again" }</button>
            </MessageBox>
        </Page>
    );
};
