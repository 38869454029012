// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

import { Action, Reducer } from "redux";
import { RequestState } from "../sharedTypes";
import { KnownAction } from "./actions";
import { ThermostatDetailsState } from "./state";

const unloadedState: ThermostatDetailsState = {
    loadThermostatRequestState: RequestState.NotStarted,
    thermostat: undefined,
    thermostatOwner: undefined,
    requestedThermostatId: undefined
};

export const reducer: Reducer<ThermostatDetailsState> =
    (state: ThermostatDetailsState | undefined, incomingAction: Action):
        ThermostatDetailsState => {
        if (state === undefined) {
            return unloadedState;
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case "REQUEST_THERMOSTAT_BY_ID":
                return {
                    ...state,
                    thermostat: undefined,
                    thermostatOwner: undefined,
                    loadThermostatRequestState: RequestState.InProgress,
                    requestedThermostatId: action.id
                };
            case "RECEIVE_THERMOSTAT_BY_ID":
                return {
                    ...state,
                    thermostat: action.thermostat,
                    thermostatOwner: action.user,
                    loadThermostatRequestState: RequestState.Succeeded
                };
            case "REJECT_THERMOSTAT_BY_ID":
                return {
                    ...state,
                    thermostat: undefined,
                    thermostatOwner: undefined,
                    loadThermostatRequestState: RequestState.Failed
                };

            default:
                return state;
        }
    };
