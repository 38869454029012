import React from "react";
import { formatDateTime } from "../../../../utils";
import { SchedulerDefinitions } from "./SchedulerSelection";

export interface ConfirmationDetail {
    header: string;
    value: string;
}

export interface ConfirmationSelectorProps {
    preconditions: boolean;
    details: ConfirmationDetail[];
    scheduler: SchedulerDefinitions;
    confirm: () => void;
}

export const ConfirmationSelector = (props: ConfirmationSelectorProps) => {
    return (
        <>
            <div className="d-flex flex-column w-75">
                <h4 className="text-muted p-2 my-3">Details</h4>
                {props.details.map(detail => (
                    <div key={detail.header} className="d-flex mx-3">
                        <h5 className="text-muted px-2">{detail.header}</h5>
                        <h5 className="font-weight-light px-2">{detail.value}</h5>
                    </div>
                ))}
            </div>

            <div className="d-flex flex-column w-75">
                <h4 className="text-muted p-2 my-3">Scheduler</h4>
                <div className="d-flex mx-3">
                    <h5 className="text-muted px-2">Start at</h5>
                    <h5 className="font-weight-light px-2">{props.scheduler.startAt ? formatDateTime(props.scheduler.startAt) : "N/A"}</h5>
                </div>
                <div className="d-flex mx-3">
                    <h5 className="text-muted px-2">Start of window</h5>
                    <h5 className="font-weight-light px-2">{props.scheduler.startOfWindow ? props.scheduler.startOfWindow : "N/A"}</h5>
                </div>
                <div className="d-flex mx-3">
                    <h5 className="text-muted px-2">End of window</h5>
                    <h5 className="font-weight-light px-2">{props.scheduler.endOfWindow ? props.scheduler.endOfWindow : "N/A"}</h5>
                </div>
                <div className="d-flex mx-3">
                    <h5 className="text-muted px-2">Error threshold</h5>
                    <h5 className="font-weight-light px-2">{props.scheduler.errorThreshold ? `${props.scheduler.errorThreshold}%` : "N/A"}</h5>
                </div>
            </div>

            <div className="d-flex flex-column my-3">
                <h6 className="font-weight-light px-2">You are about to create a new session.</h6>
                <h6 className="font-weight-light px-2">Please verify everything is correct before confirming.</h6>
            </div>

            <div className="w-25 mx-3">
                <button
                    disabled={!props.preconditions}
                    className="btn btn-outline-secondary border-0 w-100" type="button"
                    onClick={() => props.confirm()}>
                    Confirm
                </button>
            </div>
        </>
    );
};

export default ConfirmationSelector;