import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { DistributorSearchFormValues, DistributorSearchSearchResponse, DistributorSearchState } from "./state";

class Defaults {
    constructor() {
        this.paginationRowsPerPageOptions = [10, 20, 50, 100, 200, 500];
        this.defaultPageSize = this.paginationRowsPerPageOptions[2];
        this.defaultPageNumber = 0;
        this.maxPageSize = 1000;
        this.defaultSortAsc = true;
        this.defaultSortField = "";
        this.defaultSearchFormValues = {
            query: "",
            privateLabelId: ""
        };
    }

    readonly paginationRowsPerPageOptions: number[];
    readonly defaultPageNumber: number;
    readonly defaultPageSize: number;
    readonly maxPageSize: number;
    readonly defaultSortAsc: boolean;
    readonly defaultSortField: string;
    readonly defaultSearchFormValues: DistributorSearchFormValues;
}

export const DefaultSettings = new Defaults();

const unloadedState: DistributorSearchState = {
    searchFormValues: { ...DefaultSettings.defaultSearchFormValues },
    searchRequestState: RequestState.NotStarted,
    deleteRequestState: RequestState.NotStarted,
    deleteError: undefined,
    searchResult: undefined,
    searchRequest: {
        limit: DefaultSettings.defaultPageSize,
        offset: 0,
        query: DefaultSettings.defaultSearchFormValues.query,
        orderBy: DefaultSettings.defaultSortField,
        orderDescending: !DefaultSettings.defaultSortAsc,
        filters: [],
    }
};

export const reducer: Reducer<DistributorSearchState> = (state: DistributorSearchState | undefined, incomingAction: Action): DistributorSearchState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        // Search
        case "CHANGE_DISTRIBUTORS_SEARCH_REQUEST":
            const privateLabelIdFilter = (action.request.filters || []).find(f => f.field === "privateLabelId");
            const formValues: DistributorSearchFormValues = {
                query: action.request.query || "",
                privateLabelId: !!privateLabelIdFilter ? privateLabelIdFilter.value : ""
            };

            return {
                ...state,
                searchRequest: Object.assign(state.searchRequest!, action.request),
                searchFormValues: formValues
            };
        case "REQUEST_SEARCH_DISTRIBUTORS":
            return {
                ...state,
                searchRequest: action.request,
                searchRequestState: RequestState.InProgress,
                deleteRequestState: RequestState.NotStarted
            };
        case "RECEIVE_SEARCH_DISTRIBUTORS":
            return {
                ...state,
                searchRequestState: RequestState.Succeeded,
                searchResult: action.searchResult
            };
        case "REJECT_SEARCH_DISTRIBUTOR":
            return {
                ...state,
                searchRequestState: RequestState.Failed
            };

        // Delete firmware
        case "REQUEST_DELETE_DISTRIBUTOR":
            return { ...state, deleteRequestState: RequestState.InProgress };
        case "REJECT_DELETE_DISTRIBUTOR":
            return {
                ...state,
                deleteRequestState: RequestState.Failed,
                deleteError: action.error
            };
        case "RECEIVE_DELETE_DISTRIBUTOR":
            const newSearchResult: DistributorSearchSearchResponse = {
                items: state.searchResult!.items.filter(u => u.id !== action.id),
                total: state.searchResult!.total - 1
            };

            return {
                ...state,
                searchResult: newSearchResult,
                deleteRequestState: RequestState.Succeeded
            };

        // Reset search page
        case "RESET_SEARCH_DISTRIBUTOR":
            return {
                ...state,
                ...unloadedState
            };
        default:
            return state;
    }
};
