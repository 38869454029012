import * as React from "react";
import { Page } from "../Common";

const PrivacyPolicy = () => {
    return (
        <Page title="Privacy Policy">
            <h5>TBD</h5>
        </Page>
    );
};

export default PrivacyPolicy;