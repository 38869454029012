import React, { useState } from "react";
import { AppVersionData, AppVersionEditableData, Platform } from "../../../store/FirmwareUpdates/AppVersions/state";
import { RequestState } from "../../../store";
import { FaAndroid, FaApple } from "react-icons/fa";

export interface AppVersionComponentProps {
    appVersion: AppVersionData;
    updateState: RequestState;
    currentlyUpdating: string | null;
    update: (appVersion: AppVersionEditableData) => void;
}

export const AppVersionComponent = (props: AppVersionComponentProps) => {
    const { appVersion, updateState, currentlyUpdating, update } = props;
    const [major, setMajor] = useState<number>(appVersion.major);
    const [minor, setMinor] = useState<number>(appVersion.minor);
    const [build, setBuild] = useState<number>(appVersion.build);
    const isValid = major >= 0 && minor >= 0 && build >= 0;

    return (
        <div className="w-100">
            <div className="d-flex align-items-end m-1">
                {appVersion.platform.toString() === "Android" && <FaAndroid size={64} />}
                {appVersion.platform.toString() === "IOS" && <FaApple size={64} />}

                <span className="mx-2 h3 py-1">{appVersion.platform}</span>
            </div>
            <div className="d-flex form-group mb-0 w-100">
                <input className="form-control" type="number" value={major} onChange={(e) => setMajor(Number(e.target.value))} />
                <input className="form-control" type="number" value={minor} onChange={(e) => setMinor(Number(e.target.value))} />
                <input className="form-control" type="number" value={build} onChange={(e) => setBuild(Number(e.target.value))} />
            </div>
            <div className="d-flex justify-content-around m-1">
                <span className="font-weight-light">Major</span>
                <span className="font-weight-light">Minor</span>
                <span className="font-weight-light">Build</span>
            </div>
            <button
                disabled={!isValid}
                className="btn btn-primary m-1 w-100"
                onClick={() => update({ platform: appVersion.platform, major: major, minor: minor, build: build })}>
                Update
            </button>

            {!isValid && <p className="text-danger">Versions cannot be negative</p>}
            {currentlyUpdating === appVersion.id && updateState === RequestState.Failed && <p className="text-danger">Failed to save {appVersion.platform} version</p>}
            {currentlyUpdating === appVersion.id && updateState === RequestState.Succeeded && <div className="valid-feedback d-block">{appVersion.platform} version successfully updated</div>}
        </div>
    );
};

export default AppVersionComponent;