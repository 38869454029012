import * as React from "react";
import { PrivateLabelLookupItem } from "../../store/Common/state";
import { DateFormat, ProfileData, TemperatureUnit, TimeFormat } from "../../store/UserInformationView/state";
import { formatUTCDate, isValidGuid, undefNullCheck } from "../../utils";
import { CopyToClipboardText } from "../Common/CopyToClipboardText";
import { UserInformationViewProps } from "./UserInformationView";

export type ProfileDataProps = {
    profileData: ProfileData;
    privateLabels: PrivateLabelLookupItem[];
};

// eslint-disable-next-line complexity
export const ProfileDataCard = (props: ProfileDataProps) => {

    const distributorLabelFormat = (): string => {
        const privateLabelName = props.privateLabels?.find(x => x.id === props.profileData?.privateLabelId)?.name || "N/A";
        return `${privateLabelName}`;
    };

    return (
        <div className="card">
            <div className="card-header p-1 pl-3 d-flex">
                <b>Profile</b>
                <a className="ml-auto" href={`/users/edit/${props.profileData.userId}`}>
                    <button className="btn-sm btn-primary border-0">Edit</button>
                </a>
            </div>
            <div className="card-body py-2">
                {props.profileData === undefined ? <>No data</> :
                    <table className="table table-sm text-break">
                        <tbody>
                            <tr>
                                <th scope="row">User ID:</th>
                                <td>
                                    {props.profileData?.userId !== undefined && isValidGuid(props.profileData?.userId) ?
                                        <CopyToClipboardText btnText={props.profileData?.userId} /> : "No data" }
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Private label ID:</th>
                                <td>
                                    {props.profileData?.privateLabelId !== undefined && isValidGuid(props.profileData?.privateLabelId) ?
                                        <>
                                            <CopyToClipboardText btnText={props.profileData?.privateLabelId} />
                                        </> : "No data"}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Private label:</th>
                                <td>
                                    {props.profileData?.privateLabelId !== undefined && distributorLabelFormat()}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">User name:</th>
                                <td>{undefNullCheck(props.profileData?.username)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Email:</th>
                                <td>{undefNullCheck(props.profileData?.email)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Email confirmed:</th>
                                <td>{props.profileData?.isEmailConfirmed !== undefined ?
                                    props.profileData?.isEmailConfirmed ? "Yes" : "No" : "No data" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Is locked:</th>
                                <td>{props.profileData?.isLocked !== undefined ?
                                    props.profileData?.isLocked ? "Yes" : "No" : "No data" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Roles:</th>
                                <td>{props.profileData?.roles !== undefined ?
                                    props.profileData?.roles.join(", ") : "No data"}</td>
                            </tr>
                            <tr>
                                <th scope="row">First name:</th>
                                <td>{undefNullCheck(props.profileData?.firstName)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Last name:</th>
                                <td>{undefNullCheck(props.profileData?.lastName)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Address:</th>
                                <td>{undefNullCheck(props.profileData?.address)}</td>
                            </tr>
                            <tr>
                                <th scope="row">City:</th>
                                <td>{undefNullCheck(props.profileData?.city)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Postal code:</th>
                                <td>{undefNullCheck(props.profileData?.postalCode)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Country ISO code:</th>
                                <td>{undefNullCheck(props.profileData?.countryIsoCode)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Phone number:</th>
                                <td>{undefNullCheck(props.profileData?.phoneNumber)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Language ISO code:</th>
                                <td>{undefNullCheck(props.profileData?.languageIsoCode)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Temperature unit:</th>
                                <td>{props.profileData?.temperatureUnit !== undefined ?
                                    TemperatureUnit[props.profileData?.temperatureUnit] : "No data" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Time format:</th>
                                <td>{props.profileData?.timeFormat !== undefined ?
                                    TimeFormat[props.profileData?.timeFormat] : "No data" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Date format:</th>
                                <td>{props.profileData?.dateFormat !== undefined ?
                                    DateFormat[props.profileData?.dateFormat] : "No data" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Birth date:</th>
                                <td>{props.profileData?.birthDate !== undefined ?
                                    formatUTCDate(props.profileData?.birthDate) : "No data"}</td>
                            </tr>
                        </tbody>
                    </table>
                }
            </div>
        </div>
    );
};