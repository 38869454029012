import { callApiGateway } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import { KnownAction } from "./actions";
import { AppVersionData, AppVersionEditableData } from "./state";

export const actionCreators = {
    /**
     * Get app versions.
     */
    getAppVersions: (privateLabelId: string): AppThunkAction<KnownAction> => (dispatch) => {
        callApiGateway<{}, AppVersionData[]>(`firmware/app/${privateLabelId}`)
            .then(response => dispatch({ type: "RECEIVE_APP_VERSIONS", appVersions: response }))
            .catch(() => dispatch({ type: "REJECT_APP_VERSIONS" }));
        dispatch({ type: "REQUEST_APP_VERSIONS" });
    },

    /**
     * Update app version.
     */
    updateAppVersion: (id: string, appVersion: AppVersionEditableData): AppThunkAction<KnownAction> => (dispatch) => {
        callApiGateway<AppVersionEditableData, {}>(`firmware/app/${id}`, "PUT", appVersion)
            .then(() => dispatch({ type: "RECEIVE_UPDATE_APP_VERSION", id, appVersion }))
            .catch(() => dispatch({ type: "REJECT_UPDATE_APP_VERSION", id }));
        dispatch({ type: "REQUEST_UPDATE_APP_VERSION", id });
    },

    /**
     * Reset edit request states to NotStarted.
     */
    resetAppVersionState: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "RESET_APP_VERSION_STATE" });
    },
};