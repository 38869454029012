import useResizeObserver from "@react-hook/resize-observer";
import React from "react";

interface DualScrollBarContainerProps {
    children: React.ReactNode;
}

export const scrollBreakPointXL : string = getComputedStyle(document.body).getPropertyValue("--breakpoint-xl");

export const DualScrollBarContainer = (props : DualScrollBarContainerProps) => {
    const [scrollVisible, setScrollVisible] = React.useState<"visible" | "hidden">(
        "hidden"
    );
    const scrollTop = React.useRef<HTMLDivElement | null>(null);
    const scrollBot = React.useRef<HTMLDivElement | null>(null);

    const handleScroll = (
        event: React.UIEvent<HTMLDivElement>,
        element: HTMLDivElement | null
    ) => {
        if (element) {
            // synchronize scrollbars
            element.scrollLeft = event.currentTarget.scrollLeft;
        }
    };

    useResizeObserver(scrollTop, (entries) => {
        const { width } = entries.target.getBoundingClientRect();
        const pixel = scrollBreakPointXL;
        const breakPoint = parseInt(pixel.substring(0, pixel.indexOf("px")));

        setScrollVisible(width <= breakPoint ? "visible" : "hidden");
    });

    const scrollDiv: React.CSSProperties = {
        maxHeight: "15px",
        overflowX: "auto",
        overflowY: "hidden",
        whiteSpace: "nowrap",
        visibility: scrollVisible,
    };

    return (
        <>
            <div
                style={scrollDiv}
                ref={scrollTop}
                onScroll={(event) => handleScroll(event, scrollBot?.current)}
            >
                <p style={{ minWidth: scrollBreakPointXL }}>&nbsp;</p>
            </div>
            <div
                style={{ overflowX: "auto" }}
                ref={scrollBot}
                onScroll={(event) => handleScroll(event, scrollTop?.current)}
            >{props.children}</div>
        </>
    );
};
