import React, { useState } from "react";
import { MessageBox, MessageBoxType } from "../../Common";
import { RequestState } from "../../../store/sharedTypes";
import { ThermostatsSearchRequest, ThermostatsSearchResponse } from "../../../store/ThermostatsList/state";
import ThermostatSelection from "../FirmwareWizard/Selectors/ThermostatSelection";
import { FaPlus } from "react-icons/fa";

interface ThermostatDataTableProps {
    firmwareId: string;
    searchRequest: ThermostatsSearchRequest | undefined;
    searchResult: ThermostatsSearchResponse | undefined;
    changeSearch: (request: ThermostatsSearchRequest) => void;
    search: () => void;
    searchRequestState: RequestState;
    addRequestState: RequestState;
    addVisibility: (thermostatIds: string[]) => void;
}

export const ThermostatDataTable = (props: ThermostatDataTableProps) => {
    const [thermostatIds, setThermostatIds] = useState<string[]>([]);

    return (
        <>
            {props.addRequestState === RequestState.Failed && <MessageBox type={MessageBoxType.Error} className="mb-3" title="Failed to add" description="An error occurred when adding visibility." />}
            <button
                disabled={thermostatIds.length === 0}
                className="btn btn-success btn-sm mb-3"
                onClick={() => {
                    props.addVisibility(thermostatIds);
                }}>
                <FaPlus className="mt-n1" /> Add Selected
            </button>
            {props.addRequestState === RequestState.Succeeded && <p className="text-success">Successfully added the thermostats to the visibility list</p>}
            <ThermostatSelection
                searchRequest={props.searchRequest}
                searchResult={props.searchResult}
                searchState={props.searchRequestState}
                changeSearch={props.changeSearch}
                search={props.search}
                selected={thermostatIds}
                setSelected={(selected) => setThermostatIds(selected)} />
        </>
    );
};

export default ThermostatDataTable;