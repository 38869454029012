import * as React from "react";
import { BuildingLookupTree } from "../../store/UserInformationView/state";
import { BsHouse, BsFolder } from "react-icons/bs";
import { TbPointFilled } from "react-icons/tb";
import { NavLink } from "react-router-dom";

export const Tree = (props: { data: BuildingLookupTree[] | undefined }) => {

    if (!props || !props.data) {
        return (<p style={{ marginLeft: "20px" }}>No data</p>);
    }

    return (
        <div style={{ marginLeft: "20px" }}>
            {props.data.map(building => (
                <div key={building.id}>
                    <BsHouse style={{ marginRight: "6px" }} />
                    {building.name}
                    {building.zones.map(zone => (
                        <div key={zone.id} style={{ marginLeft: "30px" }}>
                            <BsFolder style={{ marginRight: "6px" }} />
                            {zone.name}
                            {zone.thermostats.map(thermostat => (
                                <div key={thermostat.id} className="d-flex" style={{ marginLeft: "30px", marginRight: "14px" }}>
                                    <div>
                                        <TbPointFilled style={{ marginRight: "6px" }} />
                                    </div>
                                    <span className="text-truncate flex-shrink-1">{(thermostat.name === "" ? "[No name]" : thermostat.name)}</span>
                                    <div className="ml-1">
                                        {"["}
                                        <NavLink to={{ pathname: `/thermostat/${thermostat.id}` }} >
                                            {thermostat.serialNumber}
                                        </NavLink>
                                        {"]"}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div >
            ))}
        </div>
    );
};