import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { FirmwareSearchResponse, FirmwareSearchState } from "./state";

const unloadedState: FirmwareSearchState = {
    searchRequestState: RequestState.NotStarted,
    deleteRequestState: RequestState.NotStarted,
    searchResult: undefined
};

export const reducer: Reducer<FirmwareSearchState> = (state: FirmwareSearchState | undefined, incomingAction: Action): FirmwareSearchState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        // Search
        case "REQUEST_SEARCH_FIRMWARE":
            return { ...state, searchRequestState: RequestState.InProgress, deleteRequestState: RequestState.NotStarted };
        case "RECEIVE_SEARCH_FIRMWARE":
            return { ...state, searchRequestState: RequestState.Succeeded, searchResult: action.searchResult };
        case "REJECT_SEARCH_FIRMWARE":
            return { ...state, searchRequestState: RequestState.Failed };

        // Delete firmware
        case "REQUEST_DELETE_FIRMWARE":
            return { ...state, deleteRequestState: RequestState.InProgress };
        case "REJECT_DELETE_FIRMWARE":
            return { ...state, deleteRequestState: RequestState.Failed };
        case "RECEIVE_DELETE_FIRMWARE":
            const newSearchResult: FirmwareSearchResponse = {
                items: state.searchResult!.items.filter(u => u.id !== action.firmwareId),
                total: state.searchResult!.total - 1
            };

            return {
                ...state,
                searchResult: newSearchResult,
                deleteRequestState: RequestState.Succeeded
            };
        default:
            return state;
    }
};
