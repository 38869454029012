const SFUP_HEADER_SIZE = 512;

const SFU1_HEADER_SIZE = 512;
const SFU1_PACKAGE_INFO_SIZE = 128;

const NINA_HEADER_SIZE = 512;
const NINA_VERSION_OFFSET = 400;
const NINA_VERSION_END = 409;

export interface Header {
    softwareVersion: string;
    wifiVersion: string;
    hardwareVersion: string;
}

export const Parse = async (buffer: Buffer) => {
    try {
        const sfu1HeaderBytes = buffer.slice(SFUP_HEADER_SIZE, SFUP_HEADER_SIZE + SFU1_HEADER_SIZE);
        const sfu1PackageInfo = sfu1HeaderBytes.slice(0, SFU1_PACKAGE_INFO_SIZE);

        const hardwareVersion = buffer.readUInt32LE(88);
        const hardwareHex = hardwareVersion.toString(16);
        const hardwareLetter = String.fromCharCode(parseInt(hardwareHex.substr(0, 2), 16));
        const hardwareMajor = parseInt(hardwareHex.substring(2, 4), 16);
        const hardwareMinor = parseInt(hardwareHex.substring(4, 6), 16);
        const hardwarePatch = parseInt(hardwareHex.substring(6, 8), 16);
        const hardwareIdentifier = hardwareLetter + hardwareMajor + hardwareMinor + hardwarePatch;

        const firmwareVersion = sfu1PackageInfo.readUInt32LE(8);
        const firmwareHex = firmwareVersion.toString(16);
        const firmwareMajor = parseInt(firmwareHex.substring(0, 1), 16);
        const firmwareMinor = parseInt(firmwareHex.substring(1, 3), 16);
        const firmwareBeta = parseInt(firmwareHex.substring(3, 5), 16);
        const hasBeta = firmwareBeta !== 0;
        let softwareVersion = "1047B" + firmwareMajor.toString().slice(-1) + ("0" + firmwareMinor).slice(-2);
        if (hasBeta) {
            softwareVersion += "b" + ("0" + firmwareBeta).slice(-2);
        }

        const firmwareSize = sfu1PackageInfo.readUInt32LE(12);
        const ninaStartPoint = SFUP_HEADER_SIZE + SFU1_HEADER_SIZE + firmwareSize;
        const ninaHeaderBytes = buffer.slice(ninaStartPoint, ninaStartPoint + NINA_HEADER_SIZE);
        const ninaVersionBytes = ninaHeaderBytes.slice(NINA_VERSION_OFFSET, NINA_VERSION_END);
        const ninaVersion = ninaVersionBytes.toString();

        const ninaSplit = ninaVersion.split("-");
        const ninaLeft = ninaSplit[0].split(".");
        const ninaMajor = ninaLeft[0].slice(-1);
        const ninaMinor = ("0" + ninaLeft[1]).slice(-2);
        const ninaPatch = ("0" + ninaLeft[2]).slice(-2);
        let wifiVersion = `${ninaMajor}.${ninaMinor}.${ninaPatch}`;
        if (ninaSplit[1]){
            wifiVersion += `-${ninaSplit[1]}`;
        }

        const header: Header = {
            softwareVersion: softwareVersion,
            wifiVersion: wifiVersion,
            hardwareVersion: hardwareIdentifier
        };

        return header;
    } catch (ex) {
        return {
            softwareVersion: "",
            wifiVersion: "",
            hardwareVersion: ""
        };
    }
};