import * as React from "react";
import { User } from "oidc-client";
import { CallbackComponent } from "redux-oidc";
import { useHistory } from "react-router-dom";
import { Spinner } from "./Common";
import { getUserManager } from "../utils";

export const CustomCallback = () => {
    const history = useHistory();

    const handleSuccess = (user?: User) => {
        let redirectUrl = "/";

        if (!!user && !!user.state) {
            redirectUrl = user.state.uri || "/";
        }

        history.push(redirectUrl);
    };

    const handleError = (error: Error) => {
        // If anything goes wrong during login, f.ex. if user cancels, then force back to mainpage.
        history.push("/");
    };

    return (
        <CallbackComponent userManager={getUserManager()} successCallback={handleSuccess} errorCallback={handleError} >
            <Spinner description="Loading ..." />
        </CallbackComponent>
    );
};
