import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { RetryThermostatsWizardState } from "./state";

const unloadedState: RetryThermostatsWizardState = {
    retryRequestState: RequestState.NotStarted,
    ids: []
};

export const reducer: Reducer<RetryThermostatsWizardState> = (state: RetryThermostatsWizardState | undefined, incomingAction: Action): RetryThermostatsWizardState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_THERMOSTATS_RETRY":
            return {
                ...state,
                retryRequestState: RequestState.InProgress
            };
        case "THERMOSTATS_RETRY_RESPONSE":
            return {
                ...state,
                retryRequestState: RequestState.Succeeded
            };
        case "THERMOSTATS_RETRY_REJECTED":
            return {
                ...state,
                retryRequestState: RequestState.Failed
            };
        case "RETRY_THERMOSTATS_SELECTED":
            return {
                ...state,
                ids: action.ids
            };
        case "RESET_RETRY_THERMOSTATS_WIZARD_STATE":
            return {
                ...state,
                retryRequestState: RequestState.NotStarted,
                ids: []
            };
        default:
            return state;
    }
};
