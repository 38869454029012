import { callApiGateway } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import { KnownAction } from "./actions";
import { UsersSearchRequest, UsersSearchResponse } from "./state";

export const actionCreators = {
    /**
     * Get gdprdata by id.
     */
    getGdprByUserId: (userId: string, userName: string, privateLabel: string): AppThunkAction<KnownAction> => (dispatch) => {
        callApiGateway<{}, string>(`users/gdpr/${userId}`)
            .then(apiResponse => {

                const fileUrl = "data:application/zip;base64," + apiResponse;
                fetch(fileUrl)
                    .then(response => response.blob())
                    .then(blob => {
                        const link = window.document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = userName.concat(" - ", privateLabel, " - ", "GDPR Data.zip");
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    });
                dispatch({ type: "RECEIVE_GDPRDATA_BY_USERID", gdprData: apiResponse });
            })
            .catch(() => dispatch({ type: "REJECT_GDPRDATA_BY_USERID" }));
        dispatch({ type: "REQUEST_GDPRDATA_BY_USERID" });
    },

    /**
     * Reset detail request states to NotStarted.
     */
    resetRequestStates: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "RESET_GDPRDATA_STATE" });
    },

    /**
     * Search users.
     */
    searchUsers: (request: UsersSearchRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (!appState.oidc || !appState.oidc.user || appState.oidc.isLoadingUser) {
            return;
        }

        dispatch({ type: "REQUEST_SEARCH_USERS" });
        callApiGateway<UsersSearchRequest, UsersSearchResponse>("users/search", "POST", request)
            .then(response => dispatch({ type: "RECEIVE_SEARCH_USERS", searchResult: response }))
            .catch(error => dispatch({ type: "REJECT_SEARCH_USERS" }));
    },

    /**
     * Delete user.
     */
    deleteUser: (userId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_DELETE_USER", userId });
        callApiGateway<{}, {}>(`users/${userId}`, "DELETE")
            .then(response => dispatch({ type: "RECEIVE_DELETE_USER", userId }))
            .catch(() => dispatch({ type: "REJECT_DELETE_USER" }));

    },

    /**
     * Delete users.
     */
    deleteUsers: (userIds: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        for (const userId of userIds) {
            dispatch({ type: "REQUEST_DELETE_USER", userId });
            callApiGateway<{}, {}>(`users/${userId}`, "DELETE")
                .then(response => dispatch({ type: "RECEIVE_DELETE_USER", userId }))
                .catch(() => dispatch({ type: "REJECT_DELETE_USER" }));
        }
    }
};
