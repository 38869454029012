export enum ThermostatState {
    Unknown = 0,
    Idle = 1,
    Downloading = 2,
    Downloaded = 3,
    Validating = 4,
    Validated = 5,
    Updating = 6,
    Complete = 7,
    Error = 8,
    Stopped = 9
}

export default ThermostatState;