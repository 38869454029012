import { ApiError, ApiStatusCode, callApiGateway, match } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import { ChangePasswordRequest } from "../../UserManagement/EditUser/state";
import { KnownAction } from "./actions";
import { UserProfileData, UserProfileEditableData } from "./state";

export const actionCreators = {
    /**
     * Get user by id.
     */
    getUserById: (userId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        callApiGateway<{}, UserProfileData>(`users/${userId}`)
            .then(response => dispatch({ type: "RECEIVE_USER_BY_ID", user: response }))
            .catch(() => dispatch({ type: "REJECT_USER_BY_ID" }));
        dispatch({ type: "REQUEST_USER_BY_ID" });
    },

    /**
     * Update user.
     */
    updateUser: (userId: string, user: UserProfileEditableData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        callApiGateway<UserProfileEditableData, {}>(`users/${userId}`, "PUT", user)
            .then(() => dispatch({ type: "RECEIVE_UPDATE_USER", user }))
            .catch(() => dispatch({ type: "REJECT_UPDATE_USER" }));
        dispatch({ type: "REQUEST_UPDATE_USER" });
    },

    /**
     * Initialize new email verification.
     */
    initializeEmailVerification: (userId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        callApiGateway<{}, {}>(`emailverification/initialize/${userId}`, "POST")
            .then(() => dispatch({ type: "RECEIVE_INITIALIZE_EMAIL_VERIFICATION" }))
            .catch(() => dispatch({ type: "REJECT_INITIALIZE_EMAIL_VERIFICATION" }));
        dispatch({ type: "REQUEST_INITIALIZE_EMAIL_VERIFICATION" });
    },

    /**
     * Change role of a user.
     */
    changeRole: (userId: string, newRole: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        callApiGateway<string, {}>(`users/${userId}/role`, "PUT", newRole)
            .then(() => dispatch({ type: "RECEIVE_CHANGE_ROLE", role: newRole }))
            .catch(() => dispatch({ type: "REJECT_CHANGE_ROLE" }));

        dispatch({ type: "REQUEST_CHANGE_ROLE" });
    },

    /**
     * Change Username of a user
     */
    changeUsername: (userId: string, username: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        callApiGateway<{}, {}>(`users/${userId}/username`, "PUT", username)
            .then(() => dispatch({ type: "RECEIVE_CHANGE_USERNAME", username }))
            .catch(() => dispatch({ type: "REJECT_CHANGE_USERNAME" }));

        dispatch({ type: "REQUEST_CHANGE_USERNAME" });
    },

    /**
     * Update Email of a user
     */
    changeEmail: (userId: string, email: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        callApiGateway<string, {}>(`users/${userId}/email`, "PUT", email)
            .then(() => dispatch({ type: "RECEIVE_CHANGE_EMAIL", email }))
            .catch(() => dispatch({ type: "REJECT_CHANGE_EMAIL" }));

        dispatch({ type: "REQUEST_CHANGE_EMAIL" });
    },

    /**
     * Update Password of a user
     */
    changePassword: (userId: string, password: ChangePasswordRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_CHANGE_PASSWORD" });
        callApiGateway<ChangePasswordRequest, {}>(`users/${userId}/password`, "PUT", password)
            .then(() => dispatch({ type: "RECEIVE_CHANGE_PASSWORD" }))
            .catch(error => {
                if (error instanceof ApiError) {
                    const apiError = error as ApiError;

                    const response = apiError.response;
                    let changePasswordErrorReason = `HTTP error: ${response.status}`;
                    if (response.statusText) {
                        changePasswordErrorReason += ` - ${response.statusText}`;
                    }

                    const status = apiError.status;
                    if (status) {
                        // TODO: here we're kind of relying on 401, consider having friendly message formed on backend for this case
                        if (match(status, ApiStatusCode.Unauthorized)) {
                            changePasswordErrorReason = "Old password is invalid";
                        } else {
                            changePasswordErrorReason = `${status.message}`;
                        }
                    }

                    const changePasswordError = `Failed to change password:\n${changePasswordErrorReason}`;
                    dispatch({ type: "REJECT_CHANGE_PASSWORD", error: changePasswordError });
                } else {
                    dispatch({ type: "REJECT_CHANGE_PASSWORD", error: "Unknown error" });
                }
            });
    },

    /**
     * Create a temporary password for a user.
     */
    createTemporaryPassword: (userId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        callApiGateway<{}, string>(`users/${userId}/temporarypassword`, "POST")
            .then((temporaryPassword) => dispatch({ type: "RECEIVE_TEMPORARY_PASSWORD", temporaryPassword }))
            .catch(() => dispatch({ type: "REJECT_TEMPORARY_PASSWORD" }));

        dispatch({ type: "REQUEST_TEMPORARY_PASSWORD" });
    },

    /**
     * Reset temporary password.
     */
    resetTemporaryPassword: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RESET_TEMPORARY_PASSWORD" });
    },

    /**
     * Reset edit request states to NotStarted.
     */
    resetEditRequestStates: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RESET_EDIT_REQUEST_STATES" });
    },
};