/* eslint-disable complexity */
import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { EmailTemplateEditor, EmailTemplateSelector, EmailTemplateTestBox, EmailTemplateRestoreBox } from ".";
import { ApplicationState, IAuthProps, RequestState } from "../../store";
import { CommonState } from "../../store/Common/state";
import { EmailTemplate, EmailTemplateLanguage, EmailTemplateType, EmailTemplatesState, actionCreators as emailTemplatesActionCreators, knownTemplateVariables } from "../../store/EmailTemplates";
import { actionCreators as privateLabelActionCreators } from "../../store/PrivateLabels/PrivateLabelsList/actionCreators";
import { validationSchemaCreators } from "../../utils";
import { MessageBox, MessageBoxType, Page, Spinner, withAuthProps, withCommonProps } from "./../Common";
import { FaSave, FaHourglassHalf, FaExclamationCircle, FaCheckCircle, FaWindowRestore } from "react-icons/fa";
import { UnloadPrompt, unloadWarningMessage } from "../Common/UnloadPrompt";

type EmailTemplatesPageProps =
    CommonState &
    EmailTemplatesState &
    typeof privateLabelActionCreators &
    typeof emailTemplatesActionCreators &
    IAuthProps;

const EmailTemplatesPage = (props: EmailTemplatesPageProps) => {
    const pageTitle = "Email Templates";
    const [privateLabelId, setPrivateLabelId] = React.useState(props.userPrivateLabelId);
    const [testEmail, setTestEmail] = React.useState(props.user!.profile.email || "");
    const [templateType, setTemplateType] = React.useState(EmailTemplateType.ConfirmEmail);
    const [templateLanguage, setTemplateLanguage] = React.useState(EmailTemplateLanguage.English);
    const [template, setTemplate] = React.useState<EmailTemplate>();
    const [htmlBody, setHtmlBody] = React.useState("");
    const [subject, setSubject] = React.useState("");
    const history = useHistory();
    const variables = knownTemplateVariables.get(templateType) || [];

    React.useEffect(() => {
        props.getPrivateLabelTemplates(privateLabelId);
    }, [privateLabelId]);

    React.useEffect(() => {
        if (props.defaultTemplate) {
            setTemplate(props.defaultTemplate);
            setHtmlBody(props.defaultTemplate.htmlBody);
            setSubject(props.defaultTemplate.subject);
        }
    }, [props.defaultTemplate]);

    React.useEffect(() => {
        const match = props.templates.find(t => t.language === templateLanguage && t.type === templateType);
        setTemplate(match);
        setHtmlBody(!match ? "" : match.htmlBody);
        setSubject(!match ? "" : match.subject);
    }, [props.templates, templateType, templateLanguage]);

    // Subject validation
    let subjectValidationError: undefined | string = undefined;
    if (subject.length < 1 || subject.length > 200) {
        subjectValidationError = "The email subject must be 1-200 characters long";
    }

    // Html body validation
    let htmlBodyValidationError: undefined | string = undefined;
    const missingVariable = variables.find(v => v.isRequired && !htmlBody.includes(v.name));
    if (missingVariable) {
        htmlBodyValidationError = `You must use the ${missingVariable.name} placeholder`;
    }

    // Test email validation
    let emailValidationError: undefined | string = undefined;
    if (props.user!.profile.email && !validationSchemaCreators.emailSchema().isValidSync(testEmail)) {
        emailValidationError = "Invalid email format";
    } else if (!props.isAdmin && !props.user!.profile.email_verified) {
        emailValidationError = "Your email address is not verified";
    }

    const didHtmlBodyChange =
        !!template &&
        template.htmlBody.trim() !== htmlBody.trim();
    const didSubjectChange =
        !!template &&
        template.subject.trim() !== subject.trim();
    const canEditTestEmail =
        props.isAdmin &&
        props.sendTestEmailRequestState !== RequestState.InProgress;
    const canSendTestEmail =
        !!template &&
        !subjectValidationError &&
        !htmlBodyValidationError &&
        !emailValidationError &&
        props.sendTestEmailRequestState !== RequestState.InProgress;
    const hasUnsavedChanges = didHtmlBodyChange || didSubjectChange;
    const canSaveTemplate =
        !subjectValidationError &&
        !htmlBodyValidationError &&
        props.saveEmailTemplateRequestState !== RequestState.InProgress &&
        hasUnsavedChanges;

    const sendTestEmail = () => {
        let html = htmlBody;

        for (const v of variables) {
            while (html.indexOf(v.name) >= 0) {
                html = html.replace(v.name, v.testValue);
            }
        }

        props.sendTestEmail({
            privateLabelId,
            subject,
            htmlBody: html,
            emailAddress: testEmail,
            parameters: new Map<string, string>()
        });
    };

    const warnAboutUnsavedChanges = (fn: () => void) => {
        const callFn = !hasUnsavedChanges || window.confirm(unloadWarningMessage);
        if (callFn) {
            fn();
        }
    };

    return (
        <Page title={pageTitle}>

            <UnloadPrompt hasUnsavedChanges={hasUnsavedChanges} />

            {props.loadPrivateLabelsRequestState === RequestState.InProgress && <Spinner />}

            {props.loadPrivateLabelsRequestState === RequestState.Failed && <MessageBox
                type={MessageBoxType.Error}
                title="Failed to load"
                description="An error occurred while loading page">
                <button className="btn btn-primary mt-3" onClick={() => history.go(0)}>Reload</button>
            </MessageBox>}

            {props.loadPrivateLabelsRequestState === RequestState.Succeeded && <div>
                <EmailTemplateSelector
                    {...props}
                    privateLabelId={privateLabelId}
                    setPrivateLabelId={id => warnAboutUnsavedChanges(() => setPrivateLabelId(id))}
                    templateType={templateType}
                    setEmailTemplateType={type => warnAboutUnsavedChanges(() => setTemplateType(type))}
                    templateLanguage={templateLanguage}
                    setEmailTemplateLanguage={language => warnAboutUnsavedChanges(() => setTemplateLanguage(language))}
                >
                    {props.getPrivateLabelTemplatesRequestState === RequestState.Succeeded && <EmailTemplateEditor
                        template={template}
                        templateType={templateType}
                        templateLanguage={templateLanguage}
                        setEmailTemplateLanguage={setTemplateLanguage}
                        subject={subject}
                        setSubject={setSubject}
                        subjectValidationError={subjectValidationError}
                        htmlBody={htmlBody}
                        setHtmlBody={setHtmlBody}
                        htmlBodyValidationError={htmlBodyValidationError} />}

                    {!!template && <>
                        <div className="input-group mb-3">
                            <button
                                disabled={!canSaveTemplate}
                                onClick={() => props.saveEmailTemplate(template.id, { subject, htmlBody })}
                                className="btn btn-primary">
                                <FaSave className="mt-n1" /> Save email template
                            </button>
                        </div>
                        {props.saveEmailTemplateRequestState === RequestState.InProgress &&
                            <span className="ml-3 text-primary"><FaHourglassHalf className="mt-n1" /> Saving email template...</span>}

                        {props.saveEmailTemplateRequestState === RequestState.Failed &&
                            <span className="ml-3 text-danger"><FaExclamationCircle className="mt-n1" /> Failed to save email template</span>}

                        {props.saveEmailTemplateRequestState === RequestState.Succeeded &&
                            <span className="ml-3 text-success"><FaCheckCircle className="mt-n1" /> Email template successfully saved</span>}

                        <EmailTemplateRestoreBox
                            {...props}
                            templateId={template.id}
                            privateLabelId={privateLabelId}
                            getPrivateLabelTemplates={props.getPrivateLabelTemplates}
                            restoreEmailTemplate={props.restoreEmailTemplate}/>

                        <EmailTemplateTestBox
                            {...props}
                            emailValidationError={emailValidationError}
                            canEditTestEmail={canEditTestEmail}
                            testEmail={testEmail}
                            setTestEmail={setTestEmail}
                            canSendTestEmail={canSendTestEmail}
                            sendTestEmail={sendTestEmail} />
                    </>}
                </EmailTemplateSelector>
            </div>}
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => ({ ...state.privateLabelsList, ...state.emailTemplates }),
    { ...privateLabelActionCreators, ...emailTemplatesActionCreators }
)(EmailTemplatesPage as any)));
