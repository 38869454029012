export interface PrivateLabelConfig {
    id: string,
    name: string
}

let privateLabelConfig: PrivateLabelConfig;

export function setPrivateLabelConfig(config: PrivateLabelConfig): void {
    privateLabelConfig = { ...config };
}

export function getPrivateLabelConfig(): PrivateLabelConfig {
    if (!!privateLabelConfig) {
        return privateLabelConfig;
    }

    throw new Error("Private label config is not initialized");
}
