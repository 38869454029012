export enum ThermostatError {
    NoError = 0,
    NoConnection = 1,
    TransferRejected = 2,
    AcceptanceRejected = 3,
    RebootRejected = 4,
    ValidationFailed = 5,
    ApplicationFailed = 6,
    BadExpectedSoftwareVersion = 7,
    UnexpectedSoftwareVersion = 8,
    UnexpectedWifiVersion = 9,
    TimeoutReached = 10,
    BadExpectedWifiVersion = 11
}

export default ThermostatError;