import { callApiGateway } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import { KnownAction } from "./actions";
import { RetryThermostats } from "./state";

export const actionCreators = {
    requestRetryThermostats: (retryThermostats: RetryThermostats): AppThunkAction<KnownAction> => (dispatch) => {
        callApiGateway<RetryThermostats, {}>("firmware/thermostats/retry", "POST", retryThermostats)
            .then(_ => dispatch({ type: "THERMOSTATS_RETRY_RESPONSE" }))
            .catch(() => dispatch({ type: "THERMOSTATS_RETRY_REJECTED" }));
        dispatch({ type: "REQUEST_THERMOSTATS_RETRY" });
    },

    selectRetryThermostats: (ids: string[]): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "RETRY_THERMOSTATS_SELECTED", ids: ids });
    },

    resetRetryWizard: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "RESET_RETRY_THERMOSTATS_WIZARD_STATE" });
    },
};