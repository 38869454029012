import { Formik } from "formik";
import * as React from "react";
import { Spinner } from "reactstrap";
import * as Yup from "yup";
import { ChangeUsernameRequest } from "../../store/UserManagement/EditUser/state";
import { getInputClassNames, validationSchemaCreators } from "../../utils";

interface ChangeUsernameFormProps {
    isSuccessful: boolean;
    isSubmitting: boolean;
    changeUsernameRequest: ChangeUsernameRequest | null;
    changeUsername: (request: string) => void;
}

interface ChangeUsernameFormValues extends ChangeUsernameRequest {

}

export const ChangeUsernameForm: React.FC<ChangeUsernameFormProps> = (props) => {

    const initialValues: ChangeUsernameFormValues =
        !!props.changeUsernameRequest ? {
            username: props.changeUsernameRequest.username
        } : {
            username: ""
        };

    const validationSchema = Yup.object().shape<ChangeUsernameFormValues>({
        username: validationSchemaCreators.usernameSchema()
    });

    return (
        <Formik enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                props.changeUsername(values.username);
            }}>
            {({ values, errors, dirty, touched, isValid, handleChange, handleBlur, handleSubmit, setFieldTouched }) =>
                <div className="form-group">
                    <div>
                        <label htmlFor="username">Username</label>
                    </div>
                    <div className="input-group">
                        <input name="username" value={values.username} onChange={(e) => {
                            setFieldTouched(e.target.name);
                            handleChange(e);
                        }} onBlur={handleBlur}
                        className={getInputClassNames({ isValid: (touched.username && !(!!errors.username)) })} />
                        <div className="input-group-append">
                            <button
                                type="submit"
                                onClick={() => handleSubmit()}
                                className="btn btn-primary rounded"
                                disabled={!dirty || !isValid || props.isSubmitting}>
                                {props.isSubmitting ?
                                    <React.Fragment>
                                        <Spinner color="info" className="spinnder-border spinner-border-sm" />
                                        {"Saving..."}
                                    </React.Fragment>
                                    :
                                    isValid ? "Save username" : "Fix Errors!"
                                }
                            </button>
                        </div>
                        <div className="invalid-feedback">{errors.username}</div>
                        {props.isSuccessful && <div className="valid-feedback d-block">Successfully saved username</div>}
                    </div>
                </div>
            }
        </Formik>
    );
};