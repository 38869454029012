import { getUserManager, signOut } from "./utils";

export enum ApiStatusCode {
    OK = 200,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    InternalServerError = 500
}

export interface FieldViolation {
    field: string;
    message: string;
}

export interface ApiStatus {
    code: number | string;
    traceId: string;
    message: string;
    fieldViolations: Array<FieldViolation>;
}

export interface ApiResponse<TData> {
    status: ApiStatus;
    data: TData;
}

export class ApiError extends Error {
    readonly response: Response;
    readonly status: ApiStatus | undefined;
    constructor(message: string, response: Response, status?: ApiStatus | undefined) {
        super(message);
        this.name = "ApiError";
        this.response = response;
        this.status = status;
    }
}

export interface ApiConfig {
    uri: string
}

let apiConfig: ApiConfig;

export function setApiConfig(config: ApiConfig): void {
    apiConfig = { ...config };
}

// TODO: implement as extension or make ApiStatus a class and implement IApiStatus
export function isError(status: ApiStatus): boolean {
    return !match(status, ApiStatusCode.OK);
}

export function match(status: ApiStatus, code: ApiStatusCode): boolean {
    return status.code === code || status.code === ApiStatusCode[code];
}

export function extractErrorMessage(error: any): string {
    if (error instanceof ApiError) {
        const apiError = error as ApiError;
        if (apiError.status) {
            return apiError.status.message;
        }

        if (apiError.response) {
            let reason = `HTTP error: ${apiError.response.status}`;
            if (apiError.response.statusText) {
                reason += ` - ${apiError.response.statusText}`;
            }

            return reason;
        }
    }

    return "Unknown error";
}

export function callApiGateway<TPayload, TData>(relativeUri: string, method?: string, payload?: TPayload): Promise<TData> {
    let baseUrl = apiConfig.uri;
    if (!baseUrl) {
        throw Error("API base URI is not valid!");
    }

    if (!relativeUri) {
        throw Error("Relative URI for API call must be specified!");
    }

    function trimSlashes(value: string) {
        return value.replace(/^\/|\/$/g, "");
    }

    baseUrl = trimSlashes(baseUrl);
    const trimmedRelativeUri = trimSlashes(relativeUri);

    const url = `${baseUrl}/${trimmedRelativeUri}`;

    return getUserManager().getUser().then(user => {
        if (!!user && !!user.access_token) {
            return user.access_token;
        } else {
            throw Error("Failed to get access token before calling API Gateway");
        }
    }).then(token => {
        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set("Authorization", `Bearer ${token}`);

        let body = null;
        if (!!payload) {
            requestHeaders.set("Content-Type", "application/json");
            body = JSON.stringify(payload);
        }

        let requestMethod = method;
        if (!requestMethod) {
            requestMethod = !!payload ? "POST" : "GET";
        }

        const request: RequestInit = {
            headers: requestHeaders,
            method: requestMethod,
            body: body
        };

        return handleApiCall<TData>(url, request);
    });
}

export function handleApiCall<TData>(input: RequestInfo, init?: RequestInit): Promise<TData> {

    function handleErrors<TData>(response: Response): Promise<ApiResponse<TData>> {

        return response.json()
            .catch(jsonParseError => {
                console.log(`HTTP response: ${response.status} - ${response.statusText}\nFailed to parse content as json:\n${jsonParseError}`);
                throw new ApiError(jsonParseError, response);
            })
            .then(json => {
                const apiResponse = json as ApiResponse<TData>;
                const status = apiResponse.status;
                if (!status) {
                    throw new ApiError("API invocation failed: status is unknown", response);
                }

                if (response.status === ApiStatusCode.Unauthorized) {
                    signOut();
                }

                // TODO: should we differentiate between HTTP and API statuses?
                if (response.ok && !isError(status)) {
                    return apiResponse;
                }

                throw new ApiError(`API invocation failed: ${status.message}`, response, status);
            });
    }

    return fetch(input, init)
        .then(response => handleErrors<TData>(response))
        .then(response => response.data);
}

export interface FilterSettings {
    clause?: "and" | "or";
    terms?: {
        field: string;
        value: string;
        type: "contains" | "notContains" | "equals" | "notEquals";
    }[];
}

export interface OrderSettings {
    terms?: {
        field: string;
        descending: boolean;
    }[];
}

export interface PagingSettings {
    size?: number;
    number?: number;
}

export interface PagingInfo {
    total: number;
    totalPages: number;
}

export interface PagedRequest {
    page?: PagingSettings;
}

export interface PagedResponse {
    page: PagingInfo;
}

export interface PagedItemsResponse<TItem> extends PagedResponse {
    items: TItem[];
}

export interface OrderedRequest {
    order?: OrderSettings;
}

export interface FilteredRequest {
    filter: FilterSettings;
}
