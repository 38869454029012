import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { DistributorCreateState } from "./state";

const unloadedState: DistributorCreateState = {
    createRequestState: RequestState.NotStarted,
    newId: undefined
};

export const reducer: Reducer<DistributorCreateState> = (state: DistributorCreateState | undefined, incomingAction: Action): DistributorCreateState => {

    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        // Create distributor
        case "REQUEST_CREATE_DISTRIBUTOR":
            return {
                ...state,
                createRequestState: RequestState.InProgress
            };
        case "RECEIVE_CREATE_DISTRIBUTOR":
            return {
                ...state,
                newId: action.newId,
                createRequestState: RequestState.Succeeded
            };
        case "REJECT_CREATE_DISTRIBUTOR":
            return {
                ...state,
                createRequestState: RequestState.Failed
            };

        // Reset create request states
        case "RESET_CREATE_DISTRIBUTOR_STATE":
            return {
                ...state,
                ...unloadedState
            };

        default:
            return state;
    }
};
