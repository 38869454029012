import React, { useState } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { MessageBox, MessageBoxType, Page, withAuthProps } from "../../Common";
import Wizard from "../../Common/Wizard/Wizard";
import WizardComponent from "../../Common/Wizard/WizardComponent";
import { actionCreators as retrySessionWizardActionCreators } from "../../../store/FirmwareUpdates/RetrySessionWizard/actionCreators";
import { CommonState } from "../../../store/Common/state";
import { IAuthProps } from "../../../store/authTypes";
import { RouteComponentProps } from "react-router";
import { RequestState } from "../../../store/sharedTypes";
import { RetrySession, RetrySessionWizardState } from "../../../store/FirmwareUpdates/RetrySessionWizard/state";
import SchedulerSelection, { SchedulerDefinitions } from "./Selectors/SchedulerSelection";
import ConfirmationSelector from "./Selectors/ConfirmationSelector";

type FirmwareWizardProps =
    RetrySessionWizardState &
    CommonState &
    typeof retrySessionWizardActionCreators &
    IAuthProps &
    RouteComponentProps<{ sessionId: string }>;

const FirmwareWizard = (props: FirmwareWizardProps) => {
    const pageTitle = "Retry Session";
    const sessionId = props.match.params.sessionId;
    const [scheduler, setScheduler] = useState<SchedulerDefinitions>({});

    const { resetRetryWizard } = props;

    React.useEffect(() => {

        return () => {
            // Anything in here is fired on component unmount.
            resetRetryWizard();
        };
    }, [resetRetryWizard]);

    if (!props.isAdmin) {
        return <Page title={pageTitle}>
            <MessageBox
                type={MessageBoxType.Error}
                title="Access Denied!"
                description="You do not have permissions to access this page.">
                <button
                    className="btn btn-primary mt-3"
                    onClick={props.history.goBack}>
                    Go Back
                </button>
            </MessageBox>
        </Page>;
    }

    const confirm = () => {
        const retrySession: RetrySession = {
            sessionId: sessionId,
            startAt: scheduler.startAt ?? null,
            startOfWindow: scheduler.startOfWindow ?? null,
            endOfWindow: scheduler.endOfWindow ?? null,
            errorThreshold: scheduler.errorThreshold ?? null
        };
        props.requestRetrySession(retrySession);
    };

    return (
        <div className="p-2">
            <Wizard>
                <WizardComponent
                    displayHeader="Scheduler"
                    displaySelection={""}
                    hasSelection={scheduler !== null}>
                    <SchedulerSelection
                        selected={scheduler}
                        setSelected={(selected) => setScheduler(selected)} />
                </WizardComponent>
                <WizardComponent
                    displayHeader="Confirmation"
                    displaySelection=""
                    hasSelection={props.retryRequestState === RequestState.Succeeded}>
                    <ConfirmationSelector
                        preconditions={!!sessionId}
                        details={[{ header: "Session Id", value: sessionId }]}
                        scheduler={scheduler}
                        confirm={confirm} />
                    {props.retryRequestState === RequestState.Succeeded &&
                        <h6 className="text-success font-weight-light px-2 mt-3">Successfully sent the retry request.</h6>}
                </WizardComponent>
            </Wizard>
        </div>
    );
};

export default withAuthProps(connect(
    (state: ApplicationState) => state.retrySessionWizard,
    { ...retrySessionWizardActionCreators }
)(FirmwareWizard as any));