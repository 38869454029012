import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { RetrySessionWizardState } from "./state";

const unloadedState: RetrySessionWizardState = {
    retryRequestState: RequestState.NotStarted
};

export const reducer: Reducer<RetrySessionWizardState> = (state: RetrySessionWizardState | undefined, incomingAction: Action): RetrySessionWizardState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_SESSION_RETRY":
            return {
                ...state,
                retryRequestState: RequestState.InProgress
            };
        case "SESSION_RETRY_RESPONSE":
            return {
                ...state,
                retryRequestState: RequestState.Succeeded
            };
        case "SESSION_RETRY_REJECTED":
            return {
                ...state,
                retryRequestState: RequestState.Failed
            };
        case "RESET_RETRY_WIZARD_STATE":
            return {
                ...state,
                retryRequestState: RequestState.NotStarted
            };
        default:
            return state;
    }
};
