import { Formik } from "formik";
import * as React from "react";
import { FaSearch } from "react-icons/fa";
import { UncontrolledTooltip } from "reactstrap";
import { DistributorSearchFormValues, DistributorSearchRequest } from "../../store/Distributors/DistributorsList/state";
import { PrivateLabelLookupItem } from "../../store/Common/state";

interface DistributorsSearchFormProps {
    privateLabels: PrivateLabelLookupItem[] | undefined,
    searchFormValues: DistributorSearchFormValues;
    changeRequest(request?: DistributorSearchRequest): void;
    search(): void;
    isSearching: boolean;
}

export default (props: DistributorsSearchFormProps) => {
    const submit = (values: DistributorSearchFormValues) => {
        const searchModel: DistributorSearchRequest = {
            query: values.query,
            filters: []
        };

        if (!!values.privateLabelId) {
            searchModel.filters!.push({ field: "privateLabelId", value: values.privateLabelId });
        }

        props.changeRequest(searchModel);
        props.search();
    };

    return (
        <Formik
            onSubmit={submit}
            validateOnChange
            enableReinitialize
            initialValues={{ ...props.searchFormValues }}>
            {({ values, handleChange, handleSubmit }) =>
                <form onSubmit={handleSubmit}>
                    <div className="input-group mb-3" style={{ maxWidth: "600px" }}>

                        {
                            /* Private label filter if any */
                            props.privateLabels && props.privateLabels.length > 0 &&
                            <>
                                {/* Private label filter */}
                                <div className="input-group-prepend">
                                    <label className="input-group-text" htmlFor="privateLabelId">Private Label</label>
                                </div>
                                <select
                                    style={{ maxWidth: 150 }}
                                    value={values.privateLabelId}
                                    name="privateLabelId"
                                    onChange={handleChange}
                                    className="form-control">
                                    <option value="" label="All" />
                                    {!props.privateLabels ? null : props.privateLabels
                                        .map((privateLabel, i) => (<option
                                            key={privateLabel.id}
                                            value={privateLabel.id}
                                            label={privateLabel.name}
                                        />))
                                    }
                                </select>
                            </>
                        }

                        {/* Search field */}
                        <input
                            type="text"
                            name="query"
                            value={values.query}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Search distributors" />

                        <div className="input-group-append">
                            {/* Search button */}
                            <button
                                id="search-btn"
                                type="submit"
                                disabled={props.isSearching}
                                className="btn btn-primary">
                                <FaSearch className="mt-n1" />
                            </button>
                            <UncontrolledTooltip placement="top" target="search-btn">Search</UncontrolledTooltip>
                        </div>
                    </div>
                </form>
            }
        </Formik>
    );
};
