import * as React from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

interface PasswordFieldProps {
    placeholder?: string;
    className?: string;
    name?: string;
    value?: string;
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
    onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
}

export const PasswordField = (props: PasswordFieldProps) => {
    const [isPlainText, setIsPlainText] = React.useState(false);

    const icon = isPlainText ? <FaEye className="mt-n1" /> : <FaEyeSlash className="mt-n1" />;

    return (
        <>
            <div className={`input-group ${(props.className && props.className.includes("is-invalid")) ? "is-invalid" : ""}`}>
                <input
                    name={props.name}
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    className={props.className}
                    placeholder={props.placeholder}
                    type={isPlainText ? "text" : "password"} />
                <div className="input-group-append">
                    <button
                        type="button"
                        tabIndex={-1}
                        className="btn btn-outline-primary"
                        onClick={() => setIsPlainText(!isPlainText)}>{icon}</button>
                </div>
            </div>
        </>
    );
};