import { callApiGateway } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import SearchRequest from "../../search";
import { KnownAction } from "./actions";
import { SessionSearchResponse } from "./state";

export const actionCreators = {
    /**
     * Search sessions.
     */
    searchSessions: (request: SearchRequest): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "REQUEST_SEARCH_SESSION" });
        callApiGateway<SearchRequest, SessionSearchResponse>("firmware/sessions/search", "POST", request)
            .then(response => dispatch({ type: "RECEIVE_SEARCH_SESSION", searchResult: response }))
            .catch(() => dispatch({ type: "REJECT_SEARCH_SESSION" }));
    },

    cancelSession: (sessionId: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "REQUEST_CANCEL_SESSION", sessionId });
        callApiGateway<{}, {}>(`firmware/sessions/${sessionId}/cancel`, "PUT")
            .then(() => dispatch({ type: "RECEIVE_CANCEL_SESSION", sessionId }))
            .catch(() => dispatch({ type: "REJECT_CANCEL_SESSION" }));

    },

    cancelSessionRange: (sessionIds: Array<string>): AppThunkAction<KnownAction> => (dispatch) => {
        for (const sessionId of sessionIds) {
            dispatch({ type: "REQUEST_CANCEL_SESSION", sessionId });
            callApiGateway<{}, {}>(`firmware/sessions/${sessionId}/cancel`, "PUT")
                .then(() => dispatch({ type: "RECEIVE_CANCEL_SESSION", sessionId }))
                .catch(() => dispatch({ type: "REJECT_CANCEL_SESSION" }));
        }
    },

    /**
     * Delete session.
     */
    deleteSession: (sessionId: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "REQUEST_DELETE_SESSION", sessionId });
        callApiGateway<{}, {}>(`firmware/sessions/${sessionId}`, "DELETE")
            .then(() => dispatch({ type: "RECEIVE_DELETE_SESSION", sessionId }))
            .catch(() => dispatch({ type: "REJECT_DELETE_SESSION" }));

    },

    /**
     * Delete session.
     */
    deleteSessionRange: (sessionIds: Array<string>): AppThunkAction<KnownAction> => (dispatch) => {
        for (const sessionId of sessionIds) {
            dispatch({ type: "REQUEST_DELETE_SESSION", sessionId });
            callApiGateway<{}, {}>(`firmware/sessions/${sessionId}`, "DELETE")
                .then(() => dispatch({ type: "RECEIVE_DELETE_SESSION", sessionId }))
                .catch(() => dispatch({ type: "REJECT_DELETE_SESSION" }));
        }
    }
};
