import * as React from "react";
import { FaCheckCircle, FaExclamationCircle, FaHourglassHalf, FaPaperPlane, FaVial } from "react-icons/fa";
import { RequestState } from "../../store";

export interface EmailTemplateTestBoxProps {
    canEditTestEmail: boolean;
    canSendTestEmail: boolean;
    emailValidationError?: string;
    testEmail: string;
    setTestEmail: (email: string) => void;
    sendTestEmail: () => void;
    sendTestEmailRequestState: RequestState;
}

export const EmailTemplateTestBox = (props: EmailTemplateTestBoxProps) => <>
    <div className="mt-3 mb-0 alert alert-warning p-3">
        <h5><FaVial className="mt-n1" /> Try out email template</h5>
        <p>
            Please verify that the email template looks correct on multiple email clients before saving any changes.
            You can do this by sending a <b><i>test email</i></b> to your own email address to verify that the email looks as expected.
        </p>
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">Send test email to:</div>
            </div>
            <input
                disabled={!props.canEditTestEmail}
                className={`form-control ${props.emailValidationError ? "is-invalid" : ""}`}
                onChange={e => props.setTestEmail(e.target.value)}
                value={props.testEmail} />
        </div>
        <button
            onClick={() => props.sendTestEmail()}
            disabled={!props.canSendTestEmail}
            className="btn btn-warning">
            <FaPaperPlane className="mt-n1" />
            &nbsp;Send test email
        </button>

        {props.emailValidationError &&
            <span className="ml-3 text-danger"><FaExclamationCircle className="mt-n1" /> {props.emailValidationError}</span>}

        {!props.emailValidationError && props.sendTestEmailRequestState === RequestState.InProgress &&
            <span className="ml-3 text-primary"><FaHourglassHalf className="mt-n1" /> Sending test email...</span>}

        {!props.emailValidationError && props.sendTestEmailRequestState === RequestState.Failed &&
            <span className="ml-3 text-danger"><FaExclamationCircle className="mt-n1" /> Failed to send test email</span>}

        {!props.emailValidationError && props.sendTestEmailRequestState === RequestState.Succeeded &&
            <span className="ml-3 text-success"><FaCheckCircle className="mt-n1" /> Test email successfully sent</span>}
    </div>
</>;
