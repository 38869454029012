import * as React from "react";
import { MessageBox, MessageBoxType } from "./MessageBox";
import { Page } from "./Page";
import * as H from "history";

type PermissionDeniedPageProps = { pageTitle: string, history: H.History };

export const PermissionDeniedPage = (props: PermissionDeniedPageProps) => {
    return (
        <Page title={props.pageTitle}>
            <MessageBox
                type={MessageBoxType.Error}
                title="Access Denied!"
                description="You do not have permissions to access this page.">
                <button
                    className="btn btn-primary mt-3"
                    onClick={props.history.goBack}>
                    Go Back
                </button>
            </MessageBox>
        </Page>
    );
};
