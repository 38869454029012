import * as React from "react";
import { isValidGuid } from "../../utils";
import { CopyToClipboardText } from "../Common/CopyToClipboardText";
import { ThermostatDetailsProps } from "./ThermostatDetailsPage";

// eslint-disable-next-line complexity
export const GUIDTab = (props: ThermostatDetailsProps) => {

    return (
        <div className="card">
            <div className="card-header p-1 pl-3">
                <b>GUIDs</b>
            </div>
            <div className="card-body py-2">
                <table className="table table-sm text-break">
                    <tbody>
                        <tr>
                            <th scope="row">Thermostat ID:</th>
                            <td>
                                {props.thermostat?.id !== undefined && isValidGuid(props.thermostat?.id) ?
                                    <CopyToClipboardText btnText={props.thermostat?.id} /> : "No data"
                                }
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">User ID:</th>
                            <td>
                                {props.thermostat?.userId !== undefined && isValidGuid(props.thermostat?.userId) ?
                                    <CopyToClipboardText btnText={props.thermostat?.userId} /> : "No data"
                                }
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Zone ID:</th>
                            <td>
                                {props.thermostat?.zoneId !== undefined && isValidGuid(props.thermostat?.zoneId) ?
                                    <CopyToClipboardText btnText={props.thermostat?.zoneId} /> : "No data"
                                }
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Schedule ID:</th>
                            <td>
                                {props.thermostat?.scheduleData?.scheduleId !== undefined && isValidGuid(props.thermostat?.scheduleData?.scheduleId) ?
                                    <CopyToClipboardText btnText={props.thermostat?.scheduleData?.scheduleId} /> : "No data"
                                }
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Building ID:</th>
                            <td>
                                {props.thermostat?.buildingId !== undefined && isValidGuid(props.thermostat?.buildingId) ?
                                    <CopyToClipboardText btnText={props.thermostat?.buildingId} /> : "No data"
                                }
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Internal distributor ID:</th>
                            <td>
                                {props.thermostat?.internalDistributorId !== undefined && isValidGuid(props.thermostat?.internalDistributorId) ?
                                    <CopyToClipboardText btnText={props.thermostat?.internalDistributorId} /> : "No data"
                                }
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Private Label ID:</th>
                            <td>
                                {props.thermostat?.privateLabelId !== undefined && isValidGuid(props.thermostat?.privateLabelId) ?
                                    <CopyToClipboardText btnText={props.thermostat?.privateLabelId} /> : "No data"
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};
