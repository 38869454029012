import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { DistributorEditState } from "./state";

const unloadedState: DistributorEditState = {
    getRequestState: RequestState.NotStarted,
    updateRequestState: RequestState.NotStarted,
    existingDistributor: undefined
};

export const reducer: Reducer<DistributorEditState> = (state: DistributorEditState | undefined, incomingAction: Action): DistributorEditState => {

    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        // Get distributor
        case "REQUEST_DISTRIBUTOR_BY_ID":
            return {
                ...state,
                getRequestState: RequestState.InProgress,
                updateRequestState: RequestState.NotStarted,
                existingDistributor: undefined
            };
        case "RECEIVE_DISTRIBUTOR_BY_ID":
            return {
                ...state,
                getRequestState: RequestState.Succeeded,
                existingDistributor: action.distributor
            };
        case "REJECT_DISTRIBUTOR_BY_ID":
            return {
                ...state,
                getRequestState: RequestState.Failed,
                existingDistributor: undefined
            };

        // Update distributor
        case "REQUEST_UPDATE_DISTRIBUTOR":
            return {
                ...state,
                updateRequestState: RequestState.InProgress
            };
        case "RECEIVE_UPDATE_DISTRIBUTOR":
            if (!state.existingDistributor) {
                return state;
            }

            return {
                ...state,
                existingDistributor: { ...state.existingDistributor, ...action.distributor },
                updateRequestState: RequestState.Succeeded
            };
        case "REJECT_UPDATE_DISTRIBUTOR":
            return {
                ...state,
                updateRequestState: RequestState.Failed
            };

        // Reset edit request states
        case "RESET_EDIT_DISTRIBUTOR_STATE":
            return {
                ...state,
                ...unloadedState
            };

        default:
            return state;
    }
};
