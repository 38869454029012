import { callApiGateway } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import SearchRequest from "../../search";
import { KnownAction } from "./actions";
import { FirmwareSearchResponse } from "./state";

export const actionCreators = {
    /**
     * Search firmware.
     */
    searchFirmware: (request: SearchRequest): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "REQUEST_SEARCH_FIRMWARE" });
        callApiGateway<SearchRequest, FirmwareSearchResponse>("firmware/search", "POST", request)
            .then(response => dispatch({ type: "RECEIVE_SEARCH_FIRMWARE", searchResult: response }))
            .catch(() => dispatch({ type: "REJECT_SEARCH_FIRMWARE" }));
    },

    /**
     * Delete firmware.
     */
    deleteFirmware: (firmwareId: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "REQUEST_DELETE_FIRMWARE", firmwareId });
        callApiGateway<{}, {}>(`firmware/${firmwareId}`, "DELETE")
            .then(() => dispatch({ type: "RECEIVE_DELETE_FIRMWARE", firmwareId }))
            .catch(() => dispatch({ type: "REJECT_DELETE_FIRMWARE" }));

    },

    /**
     * Delete multiple firmware.
     */
    deleteFirmwareRange: (firmwareIds: Array<string>): AppThunkAction<KnownAction> => (dispatch) => {
        for (const firmwareId of firmwareIds) {
            dispatch({ type: "REQUEST_DELETE_FIRMWARE", firmwareId });
            callApiGateway<{}, {}>(`firmware/${firmwareId}`, "DELETE")
                .then(() => dispatch({ type: "RECEIVE_DELETE_FIRMWARE", firmwareId }))
                .catch(() => dispatch({ type: "REJECT_DELETE_FIRMWARE" }));
        }
    }
};
