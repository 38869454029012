import { createTheme } from "react-data-table-component";
import { getPrivateLabelConfig } from "./PrivateLabel";

export function createCustomDataTableThemes(): void {
    // TODO: create custom themes for DataTable component:
    // https://react-data-table-component.netlify.app/?path=/story/theming-custom--custom
    createTheme(
        "OJ",
        {
        });

    createTheme(
        "OJ-NN",
        {
            background: {
                default: "#C0C0C0",
            }
        });
}

export function getDataTableTheme(): string {
    // theme matches PL name
    return getPrivateLabelConfig().name;
}
