import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { FirmwareWizardState, ScheduledSessionResponse } from "./state";

const unloadedState: FirmwareWizardState = {
    scheduledUpdateState: RequestState.NotStarted,
    scheduledUpdateResponse: {} as ScheduledSessionResponse
};

export const reducer: Reducer<FirmwareWizardState> = (state: FirmwareWizardState | undefined, incomingAction: Action): FirmwareWizardState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_SCHEDULED_UPDATE":
            return {
                ...state,
                scheduledUpdateState: RequestState.InProgress
            };
        case "SCHEDULED_UPDATE_RESPONSE":
            return {
                ...state,
                scheduledUpdateState: RequestState.Succeeded,
                scheduledUpdateResponse: action.response
            };
        case "SCHEDULED_UPDATE_REJECTED":
            return {
                ...state,
                scheduledUpdateState: RequestState.Failed
            };
        case "RESET_FIRMWARE_WIZARD_STATE":
            return {
                ...state,
                scheduledUpdateState: RequestState.NotStarted
            };
        default:
            return state;
    }
};
