import { callApiGateway, extractErrorMessage } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { DistributorSearchRequest, DistributorSearchSearchResponse } from "./state";

export const actionCreators = {
    changeDistributorSearchRequest: (request: DistributorSearchRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "CHANGE_DISTRIBUTORS_SEARCH_REQUEST", request });
    },

    /**
     * Search distributors.
     */
    searchDistributors: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (!appState.distributorsList){
            return;
        }

        const state = appState.distributorsList;
        if (state.searchRequestState === RequestState.InProgress){
            return;
        }

        if (!state.searchRequest){
            return;
        }

        dispatch({ type: "REQUEST_SEARCH_DISTRIBUTORS", request: state.searchRequest });
        callApiGateway<DistributorSearchRequest, DistributorSearchSearchResponse>(
            "distributors/search", "POST", state.searchRequest)
            .then(response => dispatch({ type: "RECEIVE_SEARCH_DISTRIBUTORS", searchResult: response }))
            .catch(error => dispatch({ type: "REJECT_SEARCH_DISTRIBUTOR" }));
    },

    /**
     * Delete distributor.
     */
    deleteDistributor: (distributorId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_DELETE_DISTRIBUTOR", id: distributorId });
        callApiGateway<{}, {}>(`distributors/${distributorId}`, "DELETE")
            .then(() => dispatch({ type: "RECEIVE_DELETE_DISTRIBUTOR", id: distributorId }))
            .catch(error => dispatch({ type: "REJECT_DELETE_DISTRIBUTOR", error: extractErrorMessage(error) }));
    },

    /**
     * Delete distributors range.
     */
    deleteDistributors: (distributorIds: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        for (const distributorId of distributorIds) {
            actionCreators.deleteDistributor(distributorId)(dispatch, getState);
        }
    }
};
