/* eslint-disable max-depth */
import { AppThunkAction } from "../index";
import { KnownAction } from "./actions";
import { UserInformationViewDetails } from "./state";
import { callApiGateway } from "../../ApiGateway";
import { toDate } from "../../utils";

export const actionCreators = {

    loadInformationViewData: (userId: string): AppThunkAction<KnownAction> => (dispatch) => {

        dispatch({ type: "REQUEST_USER_INFO_BY_ID" });

        callApiGateway<{}, UserInformationViewDetails>(`userinfo/${userId}`)
            .then(getUserInfoResponse => {
                // Convert timestamps to Date objects & generate unique daySchedule key
                const dateBirthDate = toDate(getUserInfoResponse.profileData?.birthDate);
                if (dateBirthDate) {
                    getUserInfoResponse.profileData!.birthDate = dateBirthDate;
                }
                if (getUserInfoResponse.schedulesData) {
                    for (const schedule of getUserInfoResponse.schedulesData) {
                        const tempDate = toDate(schedule.creationDate);
                        if (tempDate) {
                            schedule.creationDate = tempDate;
                        }
                        if (schedule.daySchedules) {
                            for (const daySchedule of schedule.daySchedules) {
                                // Generate unique key, that enables us to identify and handle daySchedules
                                daySchedule.key = schedule.id + daySchedule.days.join(".");

                                if (daySchedule.schedulesEvents) {
                                    for (const scheduleEvent of daySchedule.schedulesEvents) {
                                        const startDate = toDate(scheduleEvent.start);
                                        if (startDate) {
                                            scheduleEvent.start = startDate;
                                        }
                                        const endDate = toDate(scheduleEvent.end);
                                        if (endDate) {
                                            scheduleEvent.end = endDate;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // Convert start- and end-Time to Date objects
                getUserInfoResponse.buildingsTreeData?.forEach((building) => {
                    if (building.awayData) {
                        const startDate = toDate(building.awayData.startTime);
                        if (startDate) {
                            building.awayData.startTime = startDate;
                        }
                        const endDate = toDate(building.awayData.endTime);
                        if (endDate) {
                            building.awayData.endTime = endDate;
                        }
                    }
                });

                dispatch({
                    type: "RECEIVE_USER_INFO_BY_ID",
                    data: getUserInfoResponse
                });
            })
            .catch(error => {
                console.log("Failed to get user info", error);
                dispatch({ type: "REJECT_USER_INFO_BY_ID" });
            });
    }
};
