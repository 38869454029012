import React from "react";
import { FaSearch } from "react-icons/fa";
import { UncontrolledTooltip } from "reactstrap";

export interface DataTableSearchFieldProps {
    query: string;
    displayText: string;
    disableButton: boolean;
    setQuery: (query: string) => void;
    search: () => void;
}

export const DataTableSearchField = (props: DataTableSearchFieldProps) => {
    return (
        <>
            {/* Search field */}
            <input
                type="text"
                name="query"
                value={props.query}
                onChange={(e) => props.setQuery(e.target.value)}
                className="form-control"
                placeholder={`Search ${props.displayText}`} />

            <div
                onClick={() => props.search()}>
                {/* Search button */}
                <button
                    id="search-btn"
                    type="submit"
                    disabled={props.disableButton}
                    className="btn btn-primary">
                    <FaSearch className="mt-n1" />
                </button>
                <UncontrolledTooltip placement="top" target="search-btn">Search</UncontrolledTooltip>
            </div>
        </>
    );
};

export default DataTableSearchField;