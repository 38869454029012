import { callApiGateway } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import SearchRequest from "../../search";
import { KnownAction } from "./actions";
import { ThermostatSearchResponse } from "./state";

export const actionCreators = {
    /**
     * Search thermostats.
     */
    searchThermostats: (request: SearchRequest): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "REQUEST_SEARCH_FIRMWARE_THERMOSTATS" });
        callApiGateway<SearchRequest, ThermostatSearchResponse>("firmware/thermostats/search", "POST", request)
            .then(response => dispatch({ type: "RECEIVE_SEARCH_FIRMWARE_THERMOSTATS", searchResult: response }))
            .catch(() => dispatch({ type: "REJECT_SEARCH_FIRMWARE_THERMOSTATS" }));
    },

    cancelThermostat: (thermostatId: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "REQUEST_CANCEL_FIRMWARE_THERMOSTAT", thermostatId });
        callApiGateway<{}, {}>(`firmware/thermostats/${thermostatId}/cancel`, "PUT")
            .then(() => dispatch({ type: "RECEIVE_CANCEL_FIRMWARE_THERMOSTAT", thermostatId }))
            .catch(() => dispatch({ type: "REJECT_CANCEL_FIRMWARE_THERMOSTAT" }));

    },

    cancelThermostatRange: (thermostatIds: Array<string>): AppThunkAction<KnownAction> => (dispatch) => {
        for (const thermostatId of thermostatIds) {
            dispatch({ type: "REQUEST_CANCEL_FIRMWARE_THERMOSTAT", thermostatId });
            callApiGateway<{}, {}>(`firmware/thermostats/${thermostatId}/cancel`, "PUT")
                .then(() => dispatch({ type: "RECEIVE_CANCEL_FIRMWARE_THERMOSTAT", thermostatId }))
                .catch(() => dispatch({ type: "REJECT_CANCEL_FIRMWARE_THERMOSTAT" }));
        }
    }
};
