import * as React from "react";
import { MessageBox, MessageBoxType } from "./MessageBox";
import { Page } from "./Page";
import { signOut } from "../../utils";

type PermissionDeniedPageProps = {
    pageTitle?: string,
    title?: string,
    description?: string
};

export const UnauthorizedPage = (props: PermissionDeniedPageProps) => {

    const pageTitle = props.pageTitle || "Unauthorized";
    const title = props.title || "Unauthorized";
    const description = props.description || "You are unauthorized to access this site.";

    return (
        <Page title={pageTitle}>
            <MessageBox
                className="my-5"
                type={MessageBoxType.Information}
                title={title}
                description={description}>
                <button
                    onClick={() => signOut()}
                    className="btn btn-primary mt-3">Click here to sign out</button>
            </MessageBox>
        </Page>
    );
};
