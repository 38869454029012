import { Formik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Redirect, RouteComponentProps } from "react-router";
import { Page, PasswordField, withAuthProps, withCommonProps } from "../Common";
import { ApplicationState } from "../../store";
import { CommonState } from "../../store/Common/state";
import { IAuthProps } from "../../store/authTypes";
import { actionCreators } from "../../store/UserManagement/CreateUser/actionCreators";
import { CreateUserState, NewUserProfile, RejectReason } from "../../store/UserManagement/CreateUser/state";
import { RequestState } from "../../store/sharedTypes";
import { getInputClassNames, validationSchemaCreators } from "../../utils";
import { getPrivateLabelConfig } from "../../PrivateLabel";

type InputFieldProps = {
    value: string | undefined;
    name: string;
    label: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
    isValid: boolean;
    invalidFeedback?: string;
    optional?: boolean;
}

const TextInputField = (props: InputFieldProps) => {
    return (
        <div className="form-group">
            <div>
                <label htmlFor={props.name}>{props.label}</label>
                {props.optional ? <small className="float-right">*Optional</small> : null}
            </div>
            <input name={props.name} value={props.value} onChange={props.onChange} onBlur={props.onBlur}
                className={getInputClassNames({ isValid: props.isValid })} />
            <div className="invalid-feedback">{props.invalidFeedback}</div>
        </div>
    );
};

type CreateUserProps =
    CreateUserState &
    CommonState &
    typeof actionCreators &
    IAuthProps &
    RouteComponentProps;

const CreateUser = (props: CreateUserProps) => {
    const pageTitle = "Create New User";
    const [redirectToEdit, setRedirectToEdit] = React.useState(false);

    // Redirect to EditUser
    if (!!props.newUserId && redirectToEdit) {
        props.resetNewUserState();
        return <Redirect to={`/users/edit/${props.newUserId}`} />;
    }

    // Show success
    if (!!props.newUserId && !redirectToEdit) {
        setTimeout(() => setRedirectToEdit(true), 3000);
        return <Page title={pageTitle}><p>The new user has been created. You will be redirected to the edit page now.</p></Page>;
    }

    type FormValues = NewUserProfile & {
        repeatedPassword: string;
    };

    const initialValues: FormValues = {
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        countryIsoCode: "",
        phoneNumber: "",
        postalCode: "",
        email: "",
        username: "",
        password: "",
        repeatedPassword: "",
        role: props.isAdmin ? "" : "user",
        privateLabelId: getPrivateLabelConfig().id // default to current site PL, admin can change that
    };

    const validationSchema = Yup.object().shape<FormValues>({
        firstName: validationSchemaCreators.firstNameSchema(),
        lastName: validationSchemaCreators.lastNameSchema(),
        address: validationSchemaCreators.addressSchema(),
        city: validationSchemaCreators.citySchema(),
        postalCode: validationSchemaCreators.postalCodeSchema(),
        countryIsoCode: validationSchemaCreators.countryIsoCodeSchema(),
        phoneNumber: validationSchemaCreators.phoneNumberSchema(),
        email: validationSchemaCreators.emailRoleBasedSchema(),
        password: validationSchemaCreators.passwordSchema(),
        repeatedPassword: Yup.string()
            .oneOf([Yup.ref("password")], "*Passwords must match!")
            .required("*Repeated password is required!"),
        role: validationSchemaCreators.roleSchema(),
        username: validationSchemaCreators.usernameSchema(),
        privateLabelId: validationSchemaCreators.privateLabelIdSchema()
    });

    const submit = (values: FormValues) => {
        props.createUser(values);
    };

    const rejectReasonToString = () => {
        let result = "Failed to create user due to an unknown error.";

        if (props.rejectReason !== undefined) {
            switch (props.rejectReason) {
                case RejectReason.EmailAlreadyUsed:
                    result = "The email address is already being used by another user.";
                    break;
                case RejectReason.UsernameAlreadyUsed:
                    result = "The username is already being used by another user.";
                    break;
                case RejectReason.InternalError:
                default:
                    result = "Failed to create user due to an internal error. Try again later.";
                    break;
            }
        }

        return result;
    };

    return (
        <Page title={pageTitle} id="create-user-page">
            <Formik
                onSubmit={submit}
                validateOnChange
                validationSchema={validationSchema}
                initialValues={initialValues}>
                { /* eslint-disable-next-line complexity */}
                {({ values, dirty, errors, touched, isValid, handleChange, handleBlur, resetForm, handleSubmit, handleReset, setFieldTouched }) =>
                    <form onSubmit={e => e.preventDefault()}>

                        {/* Private Label */}
                        { props.isAdmin ?
                            <div className="form-group">
                                <div>
                                    <label htmlFor="privateLabelId">Private Label</label>
                                </div>
                                <select
                                    name="privateLabelId"
                                    className={getInputClassNames({ isValid: !(touched.privateLabelId && errors.privateLabelId) })}
                                    onChange={(e) => {
                                        setFieldTouched(e.target.name);
                                        handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.privateLabelId}>
                                    {!props.privateLabels ? null : props.privateLabels
                                        .map((privateLabel, i) => (<option
                                            key={privateLabel.id}
                                            value={privateLabel.id}
                                            label={privateLabel.name}
                                        />))
                                    }
                                </select>
                                <div className="invalid-feedback">{errors.privateLabelId}</div>
                            </div> :
                            <></>
                        }

                        {/* Role */}
                        <div className="form-group">
                            <label htmlFor="role">Role</label>
                            <select
                                name="role"
                                className={getInputClassNames({ isValid: !(touched.role && errors.role) })}
                                onChange={(e) => {
                                    setFieldTouched(e.target.name);
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values.role}>
                                <option value="" label="Choose role..." />
                                <option value="user" label="User" />
                                <option value="supporter" label="Supporter" />
                                {props.isAdmin && <option value="administrator" label="Administrator" />}
                            </select>
                            <div className="invalid-feedback">{errors.role}</div>
                        </div>

                        {/* Email */}
                        <TextInputField
                            name="email" label="Email"
                            isValid={!(touched.email && errors.email)} invalidFeedback={errors.email}
                            value={values.email} onChange={(e) => {
                                setFieldTouched(e.target.name);
                                handleChange(e);
                            }} onBlur={handleBlur} />

                        {/* Password */}
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <PasswordField
                                className={getInputClassNames({ isValid: !(touched.password && errors.password) })}
                                name="password"
                                value={values.password}
                                onChange={(e) => {
                                    setFieldTouched(e.target.name);
                                    handleChange(e);
                                }}
                                onBlur={handleBlur} />
                            <div className="invalid-feedback">
                                {errors.password && errors.password.split("\n").map(error => <>{error}<br /></>)}
                            </div>
                        </div>

                        {/* Repeated password */}
                        <div className="form-group">
                            <label htmlFor="repeatedPassword">Repeat password</label>
                            <PasswordField
                                className={getInputClassNames({ isValid: !(touched.repeatedPassword && errors.repeatedPassword) })}
                                name="repeatedPassword"
                                value={values.repeatedPassword}
                                onChange={(e) => {
                                    setFieldTouched(e.target.name);
                                    handleChange(e);
                                }}
                                onBlur={handleBlur} />
                            <div className="invalid-feedback">{errors.repeatedPassword}</div>
                        </div>

                        {/* Username */}
                        <TextInputField
                            name="username" label="Username"
                            isValid={!(touched.username && errors.username)} invalidFeedback={errors.username}
                            value={values.username} onChange={(e) => {
                                setFieldTouched(e.target.name);
                                handleChange(e);
                            }} onBlur={handleBlur} />

                        {/* First name */}
                        <TextInputField
                            name="firstName" label="First name" optional
                            isValid={!(touched.firstName && errors.firstName)} invalidFeedback={errors.firstName}
                            value={values.firstName} onChange={(e) => {
                                setFieldTouched(e.target.name);
                                handleChange(e);
                            }} onBlur={handleBlur} />

                        {/* Last name */}
                        <TextInputField
                            name="lastName" label="Last name" optional
                            isValid={!(touched.lastName && errors.lastName)} invalidFeedback={errors.lastName}
                            value={values.lastName} onChange={(e) => {
                                setFieldTouched(e.target.name);
                                handleChange(e);
                            }} onBlur={handleBlur} />

                        {/* Phone number */}
                        <TextInputField
                            name="phoneNumber" label="Phone number" optional
                            isValid={!(touched.phoneNumber && errors.phoneNumber)} invalidFeedback={errors.phoneNumber}
                            value={values.phoneNumber} onChange={(e) => {
                                setFieldTouched(e.target.name);
                                handleChange(e);
                            }} onBlur={handleBlur} />

                        {/* Country */}
                        <div className="form-group">
                            <div>
                                <label htmlFor="country">Country</label>
                                <small className="float-right">*Optional</small>
                            </div>
                            <select
                                name="countryIsoCode"
                                className={getInputClassNames({ isValid: !(touched.countryIsoCode && errors.countryIsoCode) })}
                                onChange={(e) => {
                                    setFieldTouched(e.target.name);
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values.countryIsoCode}>
                                <option value="" label="Choose country..." />
                                {!props.countries ? null : props.countries
                                    .map((country, i) => (<option
                                        key={country.iso}
                                        value={country.iso}
                                        label={country.name}
                                    />))
                                }
                            </select>
                            <div className="invalid-feedback">{errors.countryIsoCode}</div>
                        </div>

                        {/* City */}
                        <TextInputField
                            name="city" label="City" optional
                            isValid={!(touched.city && errors.city)} invalidFeedback={errors.city}
                            value={values.city} onChange={(e) => {
                                setFieldTouched(e.target.name);
                                handleChange(e);
                            }} onBlur={handleBlur} />

                        {/* Postal Code */}
                        <TextInputField
                            name="postalCode" label="Postal Code" optional
                            isValid={!(touched.postalCode && errors.postalCode)} invalidFeedback={errors.postalCode}
                            value={values.postalCode} onChange={(e) => {
                                setFieldTouched(e.target.name);
                                handleChange(e);
                            }} onBlur={handleBlur} />

                        {/* Address */}
                        <TextInputField
                            name="address" label="Address" optional
                            isValid={!(touched.address && errors.address)} invalidFeedback={errors.address}
                            value={values.address} onChange={(e) => {
                                setFieldTouched(e.target.name);
                                handleChange(e);
                            }} onBlur={handleBlur} />

                        {/* Save */}
                        <div className="form-group mb-0">
                            <button
                                onClick={() => handleSubmit()}
                                className="btn btn-primary"
                                disabled={!dirty || !isValid || props.createUserRequestState === RequestState.InProgress}
                            >{props.createUserRequestState === RequestState.InProgress ? "Creating new user" : "Create new user"}</button>
                            <button onClick={() => props.history.goBack()} className="btn btn-primary ml-3">Cancel</button>
                            {props.createUserRequestState === RequestState.Failed ? <p className="text-danger mt-3">{rejectReasonToString()}</p> : null}
                        </div>
                    </form>
                }
            </Formik>
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => ({ ...state.createUser }),
    actionCreators
)(CreateUser as any)));
