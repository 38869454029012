import React from "react";
import { ConfirmAlert, MessageBox, MessageBoxType } from "../../Common";
import { VisibilitySearchResultItem } from "../../../store/FirmwareUpdates/EditVisibility/state";
import { confirmAlert } from "react-confirm-alert";
import { FaArrowAltCircleRight, FaTrash } from "react-icons/fa";
import SearchRequest, { defaultSearchRequest, FilterClauseType, FilterTermMatchType, SearchResponse } from "../../../store/search";
import { IDataTableColumn } from "react-data-table-component";
import { NavLink } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { RequestState } from "../../../store/sharedTypes";
import SearchableDataTable from "../../Common/SearchableDataTable/SearchableDataTable";
import { formatDateTime } from "../../../utils";

interface VisibilityDataTableProps {
    firmwareId: string;
    searchResult: SearchResponse<VisibilitySearchResultItem> | undefined;
    searchRequestState: RequestState;
    deleteRequestState: RequestState;
    deleteVisibility: (thermostatIds: string[]) => void;
    searchVisibility: (searchRequest: SearchRequest) => void;
}

export const VisibilityDataTable = (props: VisibilityDataTableProps) => {

    const contextComponent = (selected: VisibilitySearchResultItem[], clearRows: () => void) => (
        <>
            {selected.length} thermostat visibility selected
            <button
                className="btn btn-danger btn-sm ml-2"
                disabled={selected.length === 0}
                onClick={e => {
                    e.preventDefault();
                    confirmAlert({
                        customUI: ({ onClose }) => <ConfirmAlert
                            title="Are you sure?"
                            description={`You are about to permanently delete ${selected.length} thermostat visibility!`}
                            onCancel={onClose}
                            onConfirm={() => {
                                props.deleteVisibility(selected.map(u => u.thermostatId));
                                clearRows();
                                onClose();
                            }}
                            confirmText="Yes, I am sure"
                            cancelText="Cancel" />
                    });
                }}><FaTrash className="mt-n1" /> Delete</button>
        </>);

    const defaultSearch: SearchRequest = {
        ...defaultSearchRequest,
        filter: {
            terms: [{
                field: "FirmwareId",
                value: props.firmwareId,
                type: FilterTermMatchType.Equals
            }],
            clause: FilterClauseType.Or
        }
    };

    const columns: IDataTableColumn[] = [
        { name: "Created", selector: "createdDate", cell: s => formatDateTime(s.createdDate), sortable: true, minWidth: "150px" },
        { name: "Thermostat ID", selector: "thermostatId", sortable: true, minWidth: "250px" },
        { name: "Firmware ID", selector: "firmwareId", sortable: true, minWidth: "250px" },
        {
            name: "Actions",
            cell: (visibility: VisibilitySearchResultItem) => (
                <div className="w-100">
                    <NavLink
                        id={`firmware-${visibility.firmwareId}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        to={`/firmware/edit/${visibility.firmwareId}`}>
                        <FaArrowAltCircleRight size={15} />
                    </NavLink>
                    <UncontrolledTooltip placement="top" target={`firmware-${visibility.firmwareId}`}>See firmware</UncontrolledTooltip>

                    <NavLink
                        id={`thermostat-${visibility.thermostatId}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        to={`/thermostat/${visibility.thermostatId}`}>
                        <FaArrowAltCircleRight size={15} />
                    </NavLink>
                    <UncontrolledTooltip placement="top" target={`thermostat-${visibility.thermostatId}`}>See thermostat</UncontrolledTooltip>

                    <button
                        id={`delete-${visibility.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        type="button"
                        disabled={props.deleteRequestState === RequestState.InProgress}
                        onClick={e => {
                            e.preventDefault();
                            confirmAlert({
                                customUI: ({ onClose }) => <ConfirmAlert
                                    title="Are you sure?"
                                    description={`Are you sure you want to permanently delete ${visibility.id}?`}
                                    onCancel={onClose}
                                    onConfirm={() => {
                                        props.deleteVisibility([visibility.thermostatId]);
                                        onClose();
                                    }}
                                    confirmText="Yes, I am sure"
                                    cancelText="Cancel" />
                            });
                        }}>
                        <FaTrash size={15} />
                    </button>
                    <UncontrolledTooltip placement="top" target={`delete-${visibility.id}`}>Delete {visibility.id}</UncontrolledTooltip>

                </div>
            ), ignoreRowClick: true, allowOverflow: true, button: true
        }
    ];

    return (
        <>
            {props.deleteRequestState === RequestState.Failed && <MessageBox type={MessageBoxType.Error} className="mb-3" title="Failed to delete" description="An error occurred when deleting visibility." />}
            <SearchableDataTable
                state={props.searchRequestState}
                columns={columns}
                response={props.searchResult}
                search={props.searchVisibility}
                defaultSearchRequest={defaultSearch}
                displayText={"thermostat visibility"}
                pluralSuffix={""}
                contextComponent={contextComponent} />
        </>
    );
};

export default VisibilityDataTable;