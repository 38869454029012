import * as React from "react";
import { RouteComponentProps } from "react-router";
import { confirmAlert } from "react-confirm-alert";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { FaArrowAltCircleRight, FaEdit, FaInfoCircle, FaPlus, FaTrash } from "react-icons/fa";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { UsersSearchRequest, UsersSearchResultItem, UsersSearchState } from "../../store/UserManagement/UsersSearch/state";
import { UncontrolledTooltip } from "reactstrap";
import { ApplicationState, IAuthProps, RequestState } from "../../store";
import { actionCreators } from "../../store/UserManagement/UsersSearch/actionCreators";
import { formatDateTime, maybePluralize, toTitleCase } from "../../utils";
import { ConfirmAlert, MessageBox, MessageBoxType, Page, Spinner, withAuthProps, withCommonProps } from "../Common";
import UsersSearchForm from "./UsersSearchForm";
import { getDataTableTheme } from "../../DataTableThemes";
import { CommonState } from "../../store/Common/state";

type UsersPageProps =
    UsersSearchState &
    typeof actionCreators &
    IAuthProps &
    CommonState &
    RouteComponentProps<{ userId: string }>;

const UsersPage = (props: UsersPageProps) => {
    // Constants
    const paginationRowsPerPageOptions = [10, 20, 50, 100, 200, 500];
    const defaultLimit = paginationRowsPerPageOptions[2];
    const defaultOffset = 0;
    const defaultOrderDescending = true;
    const defaultOrderBy = "createdAt";

    // Component state
    const [selectedUsers, setSelectedUsers] = React.useState([] as UsersSearchResultItem[]);
    const [lastSearchRequest, setLastSearchRequest] = React.useState({
        // Initialize with defaults in order to avoid missmatch with defaultLimit being one value,
        // and the rows-per-page option being the default value of the first element in paginationRowsPerPageOptions
        limit: defaultLimit,
        offset: defaultOffset,
        orderBy: defaultOrderBy,
        orderDescending: defaultOrderDescending,
        query: "",
        filters: []
    } as UsersSearchRequest);
    const [clearRows, setClearRows] = React.useState(false);

    const search = (request?: UsersSearchRequest) => {
        const usersSearchRequestOrEmptyObject: UsersSearchRequest = request || {};

        const getValue = (newValue: any, lastValue: any, defaultValue: any) => {
            return newValue !== undefined ? newValue : lastValue !== undefined ? lastValue : defaultValue;
        };

        const preparedRequest: UsersSearchRequest = {
            limit: getValue(usersSearchRequestOrEmptyObject.limit, lastSearchRequest.limit, defaultLimit),
            offset: getValue(usersSearchRequestOrEmptyObject.offset, lastSearchRequest.offset, defaultOffset),
            orderBy: getValue(usersSearchRequestOrEmptyObject.orderBy, lastSearchRequest.orderBy, defaultOrderBy),
            orderDescending: getValue(usersSearchRequestOrEmptyObject.orderDescending, lastSearchRequest.orderDescending, defaultOrderDescending),
            query: getValue(usersSearchRequestOrEmptyObject.query, lastSearchRequest.query, ""),
            filters: getValue(usersSearchRequestOrEmptyObject.filters, lastSearchRequest.filters, [])
        };

        setLastSearchRequest(preparedRequest);
        props.searchUsers(preparedRequest);
    };

    const defaultSearch = () => {
        search({
            limit: defaultLimit,
            offset: defaultOffset,
            query: "",
            filters: [],
            orderBy: defaultOrderBy,
            orderDescending: defaultOrderDescending
        });
    };

    const changePagingOptions = (settings: { size?: number, number?: number }): void => {
        const limit = settings.size || lastSearchRequest.limit || defaultLimit;
        const offset = limit * (settings.number !== undefined ? settings.number : defaultOffset);
        search({ ...lastSearchRequest, limit: limit, offset: offset });
    };

    // as this is functional component, there is no componentDidMount or componentDidUpdate,
    // so we use useEffect to fetch data on first render because we have no dependencies
    React.useEffect(defaultSearch, []);

    const lockedFormat = (user: UsersSearchResultItem): string => (user.isLocked ? "Yes" : "No");
    const roleFormat = (user: UsersSearchResultItem): string => toTitleCase(user.role);
    const createdAtFormat = (user: UsersSearchResultItem) => formatDateTime(user.createdAt);
    const privateLabelFormat = (user: UsersSearchResultItem): string => {
        // TODO: should we add name and ability to search/sort by name to user account service?
        return props.privateLabels?.find(x => x.id === user.privateLabelId)?.name || "N/A";
    };

    const createUserButton =
        <NavLink
            className="btn btn-primary btn-sm mb-3"
            to="/users/new/">
            <FaPlus className="mt-n1" /> Create new
        </NavLink>;

    const contextComponent = (
        <>
            {maybePluralize(selectedUsers.length, "user")} selected
            <button
                className="btn btn-danger btn-sm ml-2"
                disabled={selectedUsers.length === 0}
                onClick={e => {
                    e.preventDefault();
                    confirmAlert({
                        customUI: ({ onClose }) => <ConfirmAlert
                            title="Are you sure?"
                            description={`You are about to permanently delete ${maybePluralize(selectedUsers.length, "user")}!`}
                            onCancel={onClose}
                            onConfirm={() => {
                                props.deleteUsers(selectedUsers.map(u => u.id));
                                onClose();
                                setClearRows(true);
                            }}
                            confirmText="Yes, I am sure"
                            cancelText="Cancel" />
                    });
                }}><FaTrash className="mt-n1" /> Delete</button>
        </>);

    // changed selector to be string as it's hard to extract field name from function stacks
    // and also sorting state is not persisted in RDT and column is not highlighted
    // and for fields with custom format we will use format
    const columns: IDataTableColumn[] = [
        { name: "Username", selector: "username", sortable: true, minWidth: "100px" },
        { name: "Email", selector: "email", sortable: true, minWidth: "170px" },
        { name: "First name", selector: "firstName", sortable: true, maxWidth: "120px" },
        { name: "Last name", selector: "lastName", sortable: true, maxWidth: "120px" },
        { name: "Locked", selector: "isLocked", format: lockedFormat, sortable: true, width: "70px" },
        { name: "Role", selector: "role", format: roleFormat, sortable: true, width: "120px" },
        { name: "Created At", selector: "createdAt", cell: createdAtFormat, sortable: true, maxWidth: "220px" },
        {
            name: "Actions",
            width: "170px",
            cell: (user: UsersSearchResultItem) => (
                <div className="w-100">
                    <NavLink
                        id={`thermostats-${user.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        to={`/thermostat/query/${user.id}`}>
                        <FaArrowAltCircleRight size={15} />
                    </NavLink>
                    <UncontrolledTooltip placement="top" target={`thermostats-${user.id}`}>See Associated Thermostats</UncontrolledTooltip>

                    <button
                        id={`gdpr-${user.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        type="button"
                        onClick={() => props.getGdprByUserId(user.id, user.username, props.privateLabels?.find(x => x.id === user.privateLabelId)?.name || "N/A")}
                        disabled={props.deleteUserRequestState === RequestState.InProgress || !props.userInteractionIsValid(user.role)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 232V334.1l31-31c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-72 72c-9.4 9.4-24.6 9.4-33.9 0l-72-72c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l31 31V232c0-13.3 10.7-24 24-24s24 10.7 24 24z" /></svg>
                    </button>
                    {props.userInteractionIsValid(user.role) &&
                            <UncontrolledTooltip placement="top" target={`gdpr-${user.id}`}>GDPR Get Data</UncontrolledTooltip> }

                    <NavLink
                        id={`user-info-${user.id}`}
                        className={`btn shadow-none text-dark btn-sm mt-n1 ${props.userInteractionIsValid(user.role) ? "" : "disabled"}`}
                        to={`/user-info/${user.id}`}>
                        <FaInfoCircle size={15} />
                    </NavLink>
                    <UncontrolledTooltip placement="top" target={`user-info-${user.id}`}>See Info for {user.firstName}</UncontrolledTooltip>

                    <NavLink
                        id={`edit-${user.id}`}
                        className={`btn shadow-none text-dark btn-sm mt-n1 ${props.userInteractionIsValid(user.role) ? "" : "disabled"}`}
                        to={`/users/edit/${user.id}`}>
                        <FaEdit size={15} />
                    </NavLink>
                    <UncontrolledTooltip placement="top" target={`edit-${user.id}`}>Edit {user.firstName}</UncontrolledTooltip>

                    <button
                        id={`delete-${user.id}`}
                        className="btn shadow-none text-dark btn-sm mt-n1"
                        type="button"
                        disabled={props.deleteUserRequestState === RequestState.InProgress || !props.userInteractionIsValid(user.role)}
                        onClick={e => {
                            e.preventDefault();
                            confirmAlert({
                                customUI: ({ onClose }) => <ConfirmAlert
                                    title="Are you sure?"
                                    description={`Are you sure you want to permanently delete ${user.firstName} ${user.lastName}
                                    ${user.firstName.length > 0 || user.lastName.length > 0 ? "" : user.username}?`}
                                    onCancel={onClose}
                                    onConfirm={() => {
                                        props.deleteUser(user.id);
                                        onClose();
                                    }}
                                    confirmText="Yes, I am sure"
                                    cancelText="Cancel" />
                            });
                        }}>
                        <FaTrash size={15} />
                    </button>
                    {props.userInteractionIsValid(user.role) ?
                        <>
                            <UncontrolledTooltip placement="top" target={`delete-${user.id}`}>Delete {user.firstName}</UncontrolledTooltip>
                        </> : null}
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];

    if (props.isAdmin) {
        columns.unshift({ name: "Private Label", selector: "privateLabelId", format: privateLabelFormat, sortable: false, width: "120px" });
    }

    return (
        <Page title="User Management">
            {createUserButton}

            <UsersSearchForm search={(request) => search({ ...request, offset: 0 })} isSearching={props.searchRequestState === RequestState.InProgress} />
            {props.deleteUserRequestState === RequestState.Failed ? <MessageBox type={MessageBoxType.Error} className="mb-3" title="Failed to delete" description="An error occurred when deleting user(s)." /> : null}
            {props.searchRequestState === RequestState.Failed ? (
                <MessageBox type={MessageBoxType.Error} title="Failed to search" description="An error occurred when searching users.">
                    <button
                        className="btn btn-primary mt-3"
                        onClick={() => search()}>Try again</button>
                    <button
                        className="btn btn-primary mt-3 ml-3"
                        onClick={defaultSearch}>Reset search</button>
                </MessageBox>
            ) :
                (<DataTable
                    // Options
                    keyField={"id"}
                    noHeader={!props.searchResult}
                    title={!props.searchResult ? null : `Found ${maybePluralize(props.searchResult.total, "user")}`}
                    columns={columns}
                    data={!props.searchResult ? [] : props.searchResult.items}
                    noDataComponent={<p> </p>}

                    // Pagination
                    pagination
                    paginationServer
                    paginationPerPage={lastSearchRequest.limit}
                    paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                    paginationTotalRows={!props.searchResult ? 0 : props.searchResult.total}
                    onChangeRowsPerPage={size => changePagingOptions({ size })}
                    // page index in API is zero based but in react-data-table-component it starts from 1
                    onChangePage={page => changePagingOptions({ number: page - 1 })}

                    // Sorting
                    defaultSortAsc={!defaultOrderDescending}
                    defaultSortField={defaultOrderBy} // without this first render doesn't show default sort column and also sort order is stuck in default
                    onSort={(column, sortDirection) => search({ ...lastSearchRequest, orderBy: column.selector as string, orderDescending: sortDirection === "desc" })}
                    sortServer

                    // Selectable rows
                    clearSelectedRows={clearRows}
                    onSelectedRowsChange={({ selectedRows }) => {
                        setSelectedUsers(selectedRows);
                        setClearRows(false);
                    }}
                    selectableRows
                    selectableRowsHighlight
                    selectableRowDisabled={row => !props.userInteractionIsValid(row.role)}
                    // Loading/progress
                    progressPending={props.searchRequestState === RequestState.InProgress || props.deleteUserRequestState === RequestState.InProgress}
                    progressComponent={<Spinner />}

                    // Actions
                    contextComponent={contextComponent}

                    // Theme
                    theme={getDataTableTheme()}
                />)
            }
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => ({ ...state.usersSearch }),
    actionCreators
)(UsersPage as any)));
