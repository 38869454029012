import { AppThunkAction } from "../index";
import { KnownAction } from "./actions";
import { callApiGateway } from "../../ApiGateway";
import { ThermostatsSearchRequest, ThermostatsSearchResponse } from "./state";
import { RequestState } from "../sharedTypes";

export const actionCreators = {
    changeThermostatsSearchRequest: (request: ThermostatsSearchRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "CHANGE_THERMOSTATS_SEARCH_REQUEST", request });
    },

    loadThermostats: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();
        if (!appState.thermostatsList) {
            return;
        }

        if (!appState.oidc || appState.oidc.isLoadingUser) {
            console.log("Not authorized!");
            return;
        }

        const state = appState.thermostatsList;
        if (state.searchRequestState === RequestState.InProgress) {
            console.log("Search in progress already!");
            return;
        }

        if (!state.thermostatsSearchRequest) {
            console.log("Search request is null or undefined");
            return;
        }

        callApiGateway<ThermostatsSearchRequest, ThermostatsSearchResponse>("thermostats/search", "POST", state.thermostatsSearchRequest)
            .then(response =>
                dispatch({
                    type: "RECEIVE_THERMOSTATS",
                    result: response
                })
            )
            .catch(error => {
                dispatch({ type: "REJECT_THERMOSTATS" });
            });

        dispatch({
            type: "REQUEST_THERMOSTATS",
            request: state.thermostatsSearchRequest
        });
    },

    unlinkThermostat: (thermostatId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_UNLINK_THERMOSTAT", thermostatId });
        callApiGateway<{}, {}>(`thermostats/${thermostatId}/unlink`, "PUT")
            .then(response => dispatch({ type: "RECEIVE_UNLINK_THERMOSTAT", thermostatId }))
            .catch(() => dispatch({ type: "REJECT_UNLINK_THERMOSTAT" }));
    },

    deleteThermostat: (thermostatId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "REQUEST_DELETE_THERMOSTAT", thermostatId });
        callApiGateway<{}, {}>(`thermostats/${thermostatId}`, "DELETE")
            .then(response => dispatch({ type: "RECEIVE_DELETE_THERMOSTAT", thermostatId }))
            .catch(() => dispatch({ type: "REJECT_DELETE_THERMOSTAT" }));
    }
};
