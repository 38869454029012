import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { CreateUserState } from "./state";

const unloadedState: CreateUserState = {
    createUserRequestState: RequestState.NotStarted,
    newUserId: undefined,
    rejectReason: undefined
};

export const reducer: Reducer<CreateUserState> = (state: CreateUserState | undefined, incomingAction: Action): CreateUserState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_CREATE_USER":
            return {
                ...state,
                createUserRequestState: RequestState.InProgress,
                newUserId: undefined,
                rejectReason: undefined
            };
        case "RECEIVE_CREATE_USER":
            return {
                ...state,
                createUserRequestState: RequestState.Succeeded,
                newUserId: action.userId,
                rejectReason: undefined
            };
        case "REJECT_CREATE_USER":
            return {
                ...state,
                createUserRequestState: RequestState.Failed,
                rejectReason: action.reason
            };
        case "RESET_NEW_USER_STATE":
            return {
                ...state,
                newUserId: undefined,
                rejectReason: undefined
            };
        default:
            return state;
    }
};
