import { callApiGateway } from "../../../ApiGateway";
import { AppThunkAction } from "../../index";
import SearchRequest from "../../search";
import { KnownAction } from "./actions";
import { FirmwareVisibilityData, VisibilitySearchResponse } from "./state";

export const actionCreators = {
    searchVisibility: (request: SearchRequest): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "SEARCH_FIRMWARE_VISIBILITY" });
        callApiGateway<SearchRequest, VisibilitySearchResponse>("firmware/visibility/search", "POST", request)
            .then(response => dispatch({ type: "RECEIVE_FIRMWARE_VISIBILITY", response: response }))
            .catch(() => dispatch({ type: "REJECT_FIRMWARE_VISIBILITY" }));
    },

    createVisibility: (firmwareId: string, visibility: FirmwareVisibilityData): AppThunkAction<KnownAction> => (dispatch) => {
        callApiGateway<FirmwareVisibilityData, FirmwareVisibilityData>(`firmware/${firmwareId}/visibility`, "POST", visibility)
            .then(() => dispatch({ type: "RECEIVE_CREATE_FIRMWARE_VISIBILITY" }))
            .catch(() => dispatch({ type: "REJECT_CREATE_FIRMWARE_VISIBILITY" }));
        dispatch({ type: "CREATE_FIRMWARE_VISIBILITY" });
    },

    deleteVisibility: (firmwareId: string, visibility: FirmwareVisibilityData): AppThunkAction<KnownAction> => (dispatch) => {
        callApiGateway<FirmwareVisibilityData, FirmwareVisibilityData>(`firmware/${firmwareId}/visibility`, "DELETE", visibility)
            .then(() => dispatch({ type: "RECEIVE_DELETE_FIRMWARE_VISIBILITY", visibility: visibility }))
            .catch(() => dispatch({ type: "REJECT_DELETE_FIRMWARE_VISIBILITY" }));
        dispatch({ type: "DELETE_FIRMWARE_VISIBILITY" });
    },

    /**
     * Reset states.
     */
    resetRequestStates: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "RESET_FIRMWARE_VISIBILITY_STATE" });
    },
};