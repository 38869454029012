import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { PrivateLabelSearchSearchResponse, PrivateLabelSearchState } from "./state";

class Defaults {
    constructor() {
        this.paginationRowsPerPageOptions = [10, 20, 50, 100, 200, 500];
        this.defaultPageSize = this.paginationRowsPerPageOptions[2];
        this.defaultPageNumber = 0;
        this.maxPageSize = 1000;
    }

    readonly paginationRowsPerPageOptions: number[];
    readonly defaultPageNumber: number;
    readonly defaultPageSize: number;
    readonly maxPageSize: number;
}

export const DefaultSettings = new Defaults();

const unloadedState: PrivateLabelSearchState = {
    searchRequestState: RequestState.NotStarted,
    deleteRequestState: RequestState.NotStarted,
    deleteError: undefined,
    searchResult: undefined,
    searchRequest: {
        limit: DefaultSettings.defaultPageSize,
        offset: 0
    }
};

export const reducer: Reducer<PrivateLabelSearchState> = (state: PrivateLabelSearchState | undefined, incomingAction: Action): PrivateLabelSearchState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        // Search
        case "CHANGE_PRIVATE_LABELS_SEARCH_REQUEST":
            return {
                ...state,
                searchRequest: Object.assign(state.searchRequest!, action.request),
            };
        case "REQUEST_SEARCH_PRIVATE_LABELS":
            return {
                ...state,
                searchRequest: action.request,
                searchRequestState: RequestState.InProgress,
                deleteRequestState: RequestState.NotStarted
            };
        case "RECEIVE_SEARCH_PRIVATE_LABELS":
            return {
                ...state,
                searchRequestState: RequestState.Succeeded,
                searchResult: action.searchResult
            };
        case "REJECT_SEARCH_PRIVATE_LABELS":
            return {
                ...state,
                searchRequestState: RequestState.Failed
            };

        // Delete private label
        case "REQUEST_DELETE_PRIVATE_LABEL":
            return { ...state, deleteRequestState: RequestState.InProgress };
        case "REJECT_DELETE_PRIVATE_LABEL":
            return {
                ...state,
                deleteRequestState: RequestState.Failed,
                deleteError: action.error
            };
        case "RECEIVE_DELETE_PRIVATE_LABEL":
            const newSearchResult: PrivateLabelSearchSearchResponse = {
                items: state.searchResult!.items.filter(u => u.id !== action.id),
                total: state.searchResult!.total - 1
            };

            return {
                ...state,
                searchResult: newSearchResult,
                deleteRequestState: RequestState.Succeeded
            };
        case "RESET_SEARCH_PRIVATE_LABELS":
            return {
                ...state,
                ...unloadedState
            };
        default:
            return state;
    }
};
