import { Action, Reducer } from "redux";
import { RequestState } from "../../sharedTypes";
import { KnownAction } from "./actions";
import { SessionSearchResponse, SessionSearchState } from "./state";

const unloadedState: SessionSearchState = {
    searchRequestState: RequestState.NotStarted,
    cancelRequestState: RequestState.NotStarted,
    deleteRequestState: RequestState.NotStarted,
    searchResult: undefined
};

export const reducer: Reducer<SessionSearchState> = (state: SessionSearchState | undefined, incomingAction: Action): SessionSearchState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        // Search
        case "REQUEST_SEARCH_SESSION":
            return {
                ...state,
                searchRequestState: RequestState.InProgress,
                cancelRequestState: RequestState.NotStarted,
                deleteRequestState: RequestState.NotStarted
            };
        case "RECEIVE_SEARCH_SESSION":
            return {
                ...state,
                searchRequestState: RequestState.Succeeded,
                searchResult: action.searchResult
            };
        case "REJECT_SEARCH_SESSION":
            return {
                ...state,
                searchRequestState: RequestState.Failed
            };

        // Cancel session
        case "REQUEST_CANCEL_SESSION":
            return { ...state, cancelRequestState: RequestState.InProgress };
        case "REJECT_CANCEL_SESSION":
            return { ...state, cancelRequestState: RequestState.Failed };
        case "RECEIVE_CANCEL_SESSION":
            return {
                ...state,
                cancelRequestState: RequestState.Succeeded
            };

        // Delete session
        case "REQUEST_DELETE_SESSION":
            return { ...state, deleteRequestState: RequestState.InProgress };
        case "REJECT_DELETE_SESSION":
            return { ...state, deleteRequestState: RequestState.Failed };
        case "RECEIVE_DELETE_SESSION":
            const newSearchResult: SessionSearchResponse = {
                items: state.searchResult!.items.filter(u => u.id !== action.sessionId),
                total: state.searchResult!.total - 1
            };

            return {
                ...state,
                searchResult: newSearchResult,
                deleteRequestState: RequestState.Succeeded
            };
        default:
            return state;
    }
};
