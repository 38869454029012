// libs
import { Formik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Redirect, RouteComponentProps } from "react-router";

// components
import { Page, PermissionDeniedPage, withAuthProps, withCommonProps } from "../Common";

// state
import { ApplicationState } from "../../store";
import { CommonState } from "../../store/Common/state";
import { IAuthProps } from "../../store/authTypes";
import { RequestState } from "../../store/sharedTypes";
import { validationSchemaCreators } from "../../utils";
import { actionCreators } from "../../store/Distributors/DistributorCreate/actionCreators";
import { DistributorCreateState, DistributorData } from "../../store/Distributors/DistributorCreate/state";

type DistributorCreateProps =
    DistributorCreateState &
    CommonState &
    typeof actionCreators &
    IAuthProps &
    RouteComponentProps;

const DistributorCreatePage = (props: DistributorCreateProps) => {
    const pageTitle = "Create Distributor";
    const { resetCreateRequestStates } = props;
    const [redirectToEdit, setRedirectToEdit] = React.useState(false);

    React.useEffect(() => {
        return () => {
            // Anything in here is fired on component unmount.
            resetCreateRequestStates();
        };
    }, [resetCreateRequestStates]);

    // Redirect to Distributor Editor
    if (!!props.newId && redirectToEdit) {
        props.resetCreateRequestStates();
        return <Redirect to={`/distributors/edit/${props.newId}`} />;
    }

    // Show success
    if (!!props.newId && !redirectToEdit) {
        setTimeout(() => setRedirectToEdit(true), 3000);
        return <Page title={pageTitle}><p>The new distributor has been created. You will be redirected to the edit page now.</p></Page>;
    }

    if (!props.isAdmin) {
        return <PermissionDeniedPage pageTitle={pageTitle} history={props.history} />;
    }

    type FormValues = DistributorData;

    const emptyValues: FormValues = {
        distributorId: 0,
        customerCode: "",
        customerName: "",
        privateLabelId: "",
        description: ""
    };

    const initialValues: FormValues = {
        ...emptyValues
    };

    const validationSchema = Yup.object().shape<FormValues>({
        privateLabelId: validationSchemaCreators.privateLabelIdSchema(),
        distributorId: validationSchemaCreators.distributorIdSchema(),
        customerCode: validationSchemaCreators.distributorCustomerCodeSchema(),
        customerName: validationSchemaCreators.distributorCustomerNameSchema(),
        description: validationSchemaCreators.distributorDescriptionSchema()
    });

    const getInputClassNames = (isInvalid: boolean) => {
        const classNames = "form-control";
        return isInvalid ? classNames + " is-invalid text-break" : classNames;
    };

    const submit = (values: typeof emptyValues) => {
        props.createDistributor(values);
    };

    return (
        <Page title={pageTitle} id="create-distributor-page">
            <div className="row">
                <div className="col">
                    <Formik
                        enableReinitialize={true}
                        onSubmit={submit}
                        validateOnChange
                        validationSchema={validationSchema}
                        initialValues={initialValues}>
                        {({ values, dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, setFieldTouched }) =>
                            <form onSubmit={e => e.preventDefault()}>

                                {/* Private Label */}
                                <div className="form-group">
                                    <div>
                                        <label htmlFor="privateLabelId">Private Label</label>
                                    </div>
                                    <select
                                        name="privateLabelId"
                                        className={getInputClassNames(!!(touched.privateLabelId && errors.privateLabelId))}
                                        onChange={(e) => {
                                            setFieldTouched(e.target.name);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.privateLabelId}>
                                        <option value="" label="Choose private label..." />
                                        {!props.privateLabels ? null : props.privateLabels
                                            .map((privateLabel, i) => (<option
                                                key={privateLabel.id}
                                                value={privateLabel.id}
                                                label={privateLabel.name}
                                            />))
                                        }
                                    </select>
                                    <div className="invalid-feedback">{errors.privateLabelId}</div>
                                </div>

                                {/* Distributor ID */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="distributorId">Distributor ID</label>
                                    </div>
                                    <input
                                        name="distributorId"
                                        value={values.distributorId}
                                        onChange={(e) => {
                                            setFieldTouched(e.target.name);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        className={getInputClassNames(!!(touched.distributorId && errors.distributorId))} />
                                    <div className="invalid-feedback">{errors.distributorId}</div>
                                </div>

                                {/* Customer Name */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="customerName">Customer Name</label>
                                    </div>
                                    <input
                                        name="customerName"
                                        value={values.customerName}
                                        onChange={(e) => {
                                            setFieldTouched(e.target.name);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        className={getInputClassNames(!!(touched.customerName && errors.customerName))} />
                                    <div className="invalid-feedback">{errors.customerName}</div>
                                </div>

                                {/* Customer Code */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="customerCode">Customer Code</label>
                                        <small className="float-right">*Optional</small>
                                    </div>
                                    <input
                                        name="customerCode"
                                        value={values.customerCode}
                                        onChange={(e) => {
                                            setFieldTouched(e.target.name);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        className={getInputClassNames(!!(touched.customerCode && errors.customerCode))} />
                                    <div className="invalid-feedback">{errors.customerName}</div>
                                </div>

                                {/* Description */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="description">Description</label>
                                        <small className="float-right">*Optional</small>
                                    </div>
                                    <input
                                        name="description"
                                        value={values.description}
                                        onChange={(e) => {
                                            setFieldTouched(e.target.name);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        className={getInputClassNames(!!(touched.description && errors.description))} />
                                    <div className="invalid-feedback">
                                        {errors.description && errors.description.split("\n").map(error => <>{error}<br /></>)}
                                    </div>
                                </div>

                                {/* Save */}
                                <div className="form-group mb-0">
                                    <button
                                        type="submit"
                                        onClick={() => handleSubmit()}
                                        className="btn btn-primary"
                                        disabled={!dirty || !isValid || props.createRequestState === RequestState.InProgress}>
                                        {props.createRequestState === RequestState.InProgress ? "Creating new distributor" : "Create new distributor"}
                                    </button>
                                    <button
                                        onClick={() => props.history.goBack()}
                                        className="btn btn-primary ml-3">Cancel</button>
                                    {props.createRequestState === RequestState.Failed && <p className="text-danger mt-3">Failed to create distributor</p>}
                                    {props.createRequestState === RequestState.Succeeded && <div className="valid-feedback d-block">Distributor successfully created</div>}
                                </div>
                            </form>
                        }
                    </Formik>
                </div>
            </div>
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => ({ ...state.distributorCreate }),
    actionCreators
)(DistributorCreatePage as any)));
