// libs
import { Formik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { RouteComponentProps } from "react-router";

// components
import {
    Page,
    Spinner,
    withAuthProps,
    withCommonProps,
    PermissionDeniedPage,
    FailureRetryPage,
    ConfirmAlert
} from "../Common";

// state
import { ApplicationState } from "../../store";
import { CommonState } from "../../store/Common/state";
import { IAuthProps } from "../../store/authTypes";
import { RequestState } from "../../store/sharedTypes";
import { validationSchemaCreators } from "../../utils";
import { actionCreators } from "../../store/Distributors/DistributorEdit/actionCreators";
import { DistributorEditState, DistributorEditableData } from "../../store/Distributors/DistributorEdit/state";
import { confirmAlert } from "react-confirm-alert";

type DistributorEditProps =
    DistributorEditState &
    CommonState &
    typeof actionCreators &
    IAuthProps &
    RouteComponentProps<{ distributorId: string }>;

const DistributorEditPage = (props: DistributorEditProps) => {
    const pageTitle = "Edit Distributor";
    const distributorId = props.match.params.distributorId;
    const { resetEditRequestStates } = props;

    React.useEffect(() => {
        return () => {
            // Anything in here is fired on component unmount.
            resetEditRequestStates();
        };
    }, [resetEditRequestStates]);

    if (props.getRequestState === RequestState.NotStarted) {
        props.getDistributorById(distributorId);
    }

    if (props.getRequestState === RequestState.InProgress || props.getRequestState === RequestState.NotStarted) {
        return (
            <Page title={pageTitle}>
                <Spinner />
            </Page>
        );
    }

    if (props.getRequestState === RequestState.Failed) {
        return <FailureRetryPage
            pageTitle={pageTitle}
            description="An error occurred when loading the distributor."
            onRetry={() => props.getDistributorById(distributorId)} />;
    }

    if (!props.isAdmin) {
        return <PermissionDeniedPage pageTitle={pageTitle} history={props.history} />;
    }

    type FormValues = DistributorEditableData;

    const emptyValues: FormValues = {
        distributorId: 0,
        customerCode: "",
        customerName: "",
        privateLabelId: "",
        description: ""
    };

    const initialValues: FormValues = {
        ...emptyValues,
        ...{
            distributorId: props.existingDistributor!.distributorId,
            privateLabelId: props.existingDistributor!.privateLabelId,
            customerName: props.existingDistributor!.customerName,
            customerCode: props.existingDistributor!.customerCode,
            description: props.existingDistributor!.description
        }
    };

    const validationSchema = Yup.object().shape<FormValues>({
        privateLabelId: validationSchemaCreators.privateLabelIdSchema(),
        distributorId: validationSchemaCreators.distributorIdSchema(),
        customerCode: validationSchemaCreators.distributorCustomerCodeSchema(),
        customerName: validationSchemaCreators.distributorCustomerNameSchema(),
        description: validationSchemaCreators.distributorDescriptionSchema()
    });

    const getInputClassNames = (isInvalid: boolean) => {
        const classNames = "form-control";
        return isInvalid ? classNames + " is-invalid text-break" : classNames;
    };

    const submit = (values: typeof emptyValues) => {
        props.updateDistributor(distributorId, values);
    };

    const getPrivateLabelName = (privateLabelId: string) => {
        return  props.privateLabels?.find(x => x.id === privateLabelId)?.name || "N/A";
    };

    return (
        <Page title={pageTitle} id="edit-distributor-page">
            <div className="row">
                <div className="col">
                    <Formik
                        enableReinitialize={true}
                        onSubmit={submit}
                        validateOnChange
                        validationSchema={validationSchema}
                        initialValues={initialValues}>
                        {({ values, dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, setFieldTouched }) =>
                            <form onSubmit={e => e.preventDefault()}>
                                {/* Private Label */}
                                <div className="form-group">
                                    <div>
                                        <label htmlFor="privateLabelId">Private Label</label>
                                    </div>
                                    <select
                                        name="privateLabelId"
                                        className={getInputClassNames(!!(touched.privateLabelId && errors.privateLabelId))}
                                        onChange={(e) => {
                                            setFieldTouched(e.target.name);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.privateLabelId}>
                                        <option value="" label="Choose private label..." />
                                        {!props.privateLabels ? null : props.privateLabels
                                            .map((privateLabel, i) => (<option
                                                key={privateLabel.id}
                                                value={privateLabel.id}
                                                label={privateLabel.name}
                                            />))
                                        }
                                    </select>
                                    <div className="invalid-feedback">{errors.privateLabelId}</div>
                                </div>

                                {/* Distributor ID */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="distributorId">Distributor ID</label>
                                    </div>
                                    <input
                                        name="distributorId"
                                        value={values.distributorId}
                                        onChange={(e) => {
                                            setFieldTouched(e.target.name);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        className={getInputClassNames(!!(touched.distributorId && errors.distributorId))} />
                                    <div className="invalid-feedback">{errors.distributorId}</div>
                                </div>

                                {/* Customer Name */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="customerName">Customer Name</label>
                                    </div>
                                    <input
                                        name="customerName"
                                        value={values.customerName}
                                        onChange={(e) => {
                                            setFieldTouched(e.target.name);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        className={getInputClassNames(!!(touched.customerName && errors.customerName))} />
                                    <div className="invalid-feedback">{errors.customerName}</div>
                                </div>

                                {/* Customer Code */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="customerCode">Customer Code</label>
                                        <small className="float-right">*Optional</small>
                                    </div>
                                    <input
                                        name="customerCode"
                                        value={values.customerCode} onChange={(e) => {
                                            setFieldTouched(e.target.name);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        className={getInputClassNames(!!(touched.customerCode && errors.customerCode))} />
                                    <div className="invalid-feedback">{errors.customerName}</div>
                                </div>

                                {/* Description */}
                                <div className="form-group">
                                    <div>
                                        <label className="" htmlFor="description">Description</label>
                                        <small className="float-right">*Optional</small>
                                    </div>
                                    <input
                                        name="description"
                                        value={values.description}
                                        onChange={(e) => {
                                            setFieldTouched(e.target.name);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        className={getInputClassNames(!!(touched.description && errors.description))} />
                                    <div className="invalid-feedback">
                                        {errors.description && errors.description.split("\n").map(error => <>{error}<br /></>)}
                                    </div>
                                </div>

                                {/* Save */}
                                <div className="form-group mb-0">
                                    <button
                                        type="submit"
                                        onClick={e => {
                                            e.preventDefault();

                                            if (initialValues.distributorId !== values.distributorId ||
                                                initialValues.privateLabelId !== values.privateLabelId) {
                                                let changeSummary = "";

                                                if (initialValues.distributorId !== values.distributorId) {
                                                    changeSummary += `public distributor ID from ${initialValues.distributorId} to ${values.distributorId}`;
                                                }

                                                if (initialValues.privateLabelId !== values.privateLabelId) {
                                                    if (changeSummary) {
                                                        changeSummary += " and ";
                                                    }

                                                    const prevPrivateLabel = getPrivateLabelName(initialValues.privateLabelId);
                                                    const newPrivateLabel = getPrivateLabelName(values.privateLabelId);

                                                    changeSummary += `parent private label from ${prevPrivateLabel} to ${newPrivateLabel}`;
                                                }

                                                const alertPubDidChange =
                                                    `You are about to change ${changeSummary}! ` +
                                                    "This will affect all saved thermostats' data and existing linked devices won't be able to connect " +
                                                    "without settings adjustment or relinking. Are you sure you want to proceed?";

                                                confirmAlert({
                                                    customUI: ({ onClose }) => <ConfirmAlert
                                                        title="Are you sure?"
                                                        description={alertPubDidChange}
                                                        onCancel={onClose}
                                                        onConfirm={() => {
                                                            handleSubmit();
                                                            onClose();
                                                        }}
                                                        confirmText="Yes, I am sure"
                                                        cancelText="Cancel" />
                                                });
                                            } else {
                                                handleSubmit();
                                            }
                                        }}
                                        className="btn btn-primary"
                                        disabled={!dirty || !isValid || props.updateRequestState === RequestState.InProgress}>
                                        {props.updateRequestState === RequestState.InProgress ? "Saving changes" : "Save changes"}
                                    </button>
                                    <button
                                        onClick={() => props.history.goBack()}
                                        className="btn btn-primary ml-3">Cancel</button>
                                    {props.updateRequestState === RequestState.Failed && <p className="text-danger mt-3">Failed to save changes</p>}
                                    {props.updateRequestState === RequestState.Succeeded && <div className="valid-feedback d-block">Distributor data successfully updated</div>}
                                </div>
                            </form>
                        }
                    </Formik>
                </div>
            </div>
        </Page>
    );
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => ({ ...state.distributorEdit }),
    actionCreators
)(DistributorEditPage as any)));
