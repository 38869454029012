import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store";
import { CommonState } from "../../store/Common/state";

export const withCommonProps = <TComponentProps extends CommonState & RouteComponentProps>(CommonPropsContainer: React.ComponentType<TComponentProps>) => {
    const mapStateToProps = (state: ApplicationState) => ({ ...state.common });

    const createWrapper = () => {
        return ((props: ReturnType<typeof mapStateToProps>) => {
            return <CommonPropsContainer {...props as TComponentProps} />;
        });
    };

    return connect(mapStateToProps)(createWrapper());
};
